import { Box, Button, Heading, Layer, Text } from 'grommet';
import { FormClose } from 'grommet-icons';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import useActionWhenLocationAspectChanges, {
    locationAspectFunctions,
} from '../../../hooks/useActionWhenLocationAspectChanges';
import theme from '../../../style/theme';
import { Button as MDWButton } from '../../MDW/MDW';
import googleAnalyticsConsentManager from './GoogleAnalyticsConsentManager';

const StyledHeading = styled(Heading)`
    font-weight: ${theme.fontWeights.bold};
`;

const StyledAcceptButton = styled(MDWButton)`
    font-size: 15px;
    font-weight: ${theme.fontWeights.bold};
    &:focus {
        border-radius: ${theme.radii.large};
        box-shadow: 0 0 0 ${theme.a11yBoxShadow.thickerThickness}
            ${theme.global.colors[window.localStorage.getItem('branding') || 'default'].focusBorderColor};
    }
`;

const StyledRejectButton = styled(MDWButton)`
    font-size: 15px;
    font-weight: ${theme.fontWeights.bold};
    background-color: ${theme?.global?.colors[window.localStorage.getItem('branding') || 'default']?.lighterGrey};
    color: ${theme?.global?.colors[window.localStorage.getItem('branding') || 'default']?.richAlternativeBlue};
    border: solid 2px
        ${theme?.global?.colors[window.localStorage.getItem('branding') || 'default']?.richAlternativeBlue};

    &: hover {
        background-color: ${theme?.global?.colors[window.localStorage.getItem('branding') || 'default']?.lighterGrey};
    }
    &:focus {
        border-radius: ${theme.radii.large};
        box-shadow: 0 0 0 ${theme.a11yBoxShadow.thickerThickness}
            ${theme.global.colors[window.localStorage.getItem('branding') || 'default'].focusBorderColor};
        background-color: ${theme?.global?.colors[window.localStorage.getItem('branding') || 'default']?.lighterGrey};
    }
`;

const StyledCloseButton = styled(Button)`
    &:focus {
        border-radius: ${theme.radii.large};
        box-shadow: 0 0 0 ${theme.a11yBoxShadow.thickness}
            ${theme.global.colors[window.localStorage.getItem('branding') || 'default'].focusBorderColor};
    }
`;

const showCookieConsentModalCondition: () => boolean = () => {
    return googleAnalyticsConsentManager.googleAnalyticsCookieAbsent();
};

/**
 * This modal dialogue could potentially be used to get the user's consent for multiple features,
 * hence the generic name CookieConsentModal.
 * For now it just deals with consent for Google Analytics, which is encapsulated in the googleAnalyticsConsentManager object.
 * In future there could be similar objects for other features.
 * @constructor
 */
const CookieConsentModal = () => {
    const [showModal, setShowModal] = React.useState<boolean>(showCookieConsentModalCondition);

    const showModalOnLocationAspectChange = useCallback(() => {
        if (showCookieConsentModalCondition()) {
            setShowModal(true);
        }
    }, [setShowModal]);

    useActionWhenLocationAspectChanges(showModalOnLocationAspectChange, locationAspectFunctions.locationPathname);

    return showModal ? (
        <Layer onEsc={() => setShowModal(false)} margin="large" data-testid="cookie-consent-modal">
            <Box background="lighterGrey" elevation={'small'} round={theme.radii.large}>
                <Box direction="row" justify="between">
                    <StyledHeading
                        margin={'medium'}
                        level="4"
                        color="richAlternativeBlue"
                        data-testid="cookie-consent-modal-heading"
                    >
                        We use Cookies
                    </StyledHeading>
                    <Box pad="xsmall">
                        <StyledCloseButton
                            alignSelf="start"
                            plain
                            tabIndex={1}
                            icon={<FormClose size="40em" />}
                            onClick={() => {
                                setShowModal(false);
                            }}
                            data-testid="cookie-consent-modal-close"
                        />
                    </Box>
                </Box>
                <Box
                    pad={{
                        horizontal: 'medium',
                        top: 'none',
                        bottom: 'medium',
                    }}
                >
                    <Box pad={{ bottom: 'medium' }}>
                        <Text color="richAlternativeBlue" data-testid="cookie-consent-modal-message">
                            We use tools, such as cookies, to enable essential services and functionality on our site
                            and to collect data on how visitors interact with our site, products and services. By opting
                            in, you agree to our use of these tools for analytics.
                        </Text>
                    </Box>

                    <Box direction="row">
                        <StyledAcceptButton
                            margin={{ right: 'medium' }}
                            tabIndex={2}
                            onClick={() => {
                                googleAnalyticsConsentManager.setUserAcceptedGoogleAnalytics();
                                setShowModal(false);
                            }}
                            data-testid="cookie-consent-modal-accept"
                        >
                            ACCEPT
                        </StyledAcceptButton>
                        <StyledRejectButton
                            alignSelf="end"
                            tabIndex={3}
                            onClick={() => {
                                googleAnalyticsConsentManager.setUserRejectedGoogleAnalytics();
                                setShowModal(false);
                            }}
                            data-testid="cookie-consent-modal-reject"
                        >
                            NO, THANKS
                        </StyledRejectButton>
                    </Box>
                </Box>
            </Box>
        </Layer>
    ) : null;
};

export default CookieConsentModal;
