import {
    Box,
    // Button,
    // Image,
    // Text
} from 'grommet';
// import React from 'react';
import { StyledSubTitleText } from './DescriptiveMetadataListUtils';
// import { youTubeRegExp1, youTubeRegExp2 } from '../../../../../utils/javascriptUtilities';
// import theme from '../../../../../style/theme';
// import styled from 'styled-components';
// import { VideoOverlay } from '../../../../MDW/MDW';
// import { whitePlayMedia } from '../../../../../assets/icons/MDW-icons';
import DescriptiveGenericSection from './DescriptiveGenericSection';
import {
    NestedMetadataSection,
    SummaryMetadataSection,
} from '../../IndividualDatasetPageContents/IndividualDatasetPageContents';

// const StyledSummaryTitleText = styled(Text)`
//     white-space: break-spaces;
// `;

// const StyledPublisherText = styled(Text)`
//     text-transform: capitalize;
// `;

// const StyledButton = styled(Button)`
//     & > img {
//         padding: 5px;
//     }
//     &:focus > img {
//         border-radius: ${theme.radii.large};
//         box-shadow: 0 0 0 ${theme.a11yBoxShadow.thickness} ${theme.a11yBoxShadow.colour};
//     }
// `;
// const StyledWrapperForHeader = styled.div`
//     display: flex;
//     box-sizing: border-box;
//     max-width: 100%;
//     background-color: #1b2f64;
//     color: #f8f8f8;
//     min-width: 0;
//     min-height: 0;
//     display: -webkit-box;
//     display: -webkit-flex;
//     display: -ms-flexbox;
//     width: 100%;
//     padding: 18px;
//     border-radius: 6px;
//     justify-content: space-between;
// `;
// const StyledRetiredButton = styled.div`
//     display: flex;
//     border: 2px solid yellow;
//     border-radius: 6px;
//     width: 150px;
//     justify-content: center;
//     align-items: center;
//     height: 40px;
// `;
// const StyledTypo = styled.p`
//     font-size: 14px;
//     line-height: 20px;
//     font-weight: bold;
// `;

interface DescriptiveSummaryPropTypes {
    isFinalized: any;
    summary: SummaryMetadataSection;
    associatedMediaArray: string[];
    lastSection: boolean;
}

// const filterYouTubeLinks = (linkArray: string[]) => {
//     return linkArray.filter((link) => link.match(youTubeRegExp1) && link.match(youTubeRegExp2));
// };

export const combineMemberOfAndPublisherName = (memberOf?: string, publisherName?: string): string => {
    if (memberOf && publisherName) {
        return `${memberOf} • ${publisherName}`.toLocaleLowerCase();
    } else if (memberOf) {
        return memberOf.toLocaleLowerCase();
    } else if (publisherName) {
        return publisherName.toLocaleLowerCase();
    } else {
        return '[No Publisher Information Found!]';
    }
};

const DescriptiveSummary = (props: DescriptiveSummaryPropTypes) => {
    const { summary } = props;

    // const [showPlayMedia, setShowPlayMedia] = React.useState(false);

    // const openMediaPlayButtonClick = () => {
    //     setShowPlayMedia(true);
    // };

    // const videoArray = associatedMediaArray ? filterYouTubeLinks(associatedMediaArray) : [];
    let publisherCondition = window.config?.branding;
    if (!publisherCondition?.includes('dsc2', 'adr', 'ons')) {
        let {
            title,
            abstract,
            keywords,
            contactPoint,
            publisher: { name: publisherName, memberOf, ...otherPublisherDetails } = {},
            associatedMedia,
            ...remainingSummaryObjects
        } = summary;
        console.log(summary);

        let citation = remainingSummaryObjects?.citation;
        // @ts-ignore
        delete remainingSummaryObjects?.citation;

        let doiName = remainingSummaryObjects?.doiName;
        delete remainingSummaryObjects?.doiName;

        // @ts-ignore
        remainingSummaryObjects = {
            // @ts-ignore
            ...remainingSummaryObjects,
            citation,
            doiName,
            publisher: otherPublisherDetails as NestedMetadataSection,
            associatedMedia,
        };

        return (
            <Box margin={{ top: 'small' }}>
                <StyledSubTitleText
                    data-testid={`summary-list-title`}
                    size="small"
                    weight="bold"
                    margin={{ bottom: 'small' }}
                >
                    Summary
                </StyledSubTitleText>
                <DescriptiveGenericSection
                    metadataSection={remainingSummaryObjects}
                    metadataSectionName="summary"
                    lastSection={props.lastSection}
                />
            </Box>
        );
    } else {
        let { title, abstract, keywords, contactPoint, ...remainingSummaryObjects } = summary;

        let citation = remainingSummaryObjects?.citation;
        // @ts-ignore
        delete remainingSummaryObjects?.citation;

        let doiName = remainingSummaryObjects?.doiName;
        delete remainingSummaryObjects?.doiName;

        // @ts-ignore
        remainingSummaryObjects = {
            // @ts-ignore
            ...remainingSummaryObjects,
            citation,
            doiName,
        };

        return (
            <Box margin={{ top: 'small' }}>
                <StyledSubTitleText
                    data-testid={`summary-list-title`}
                    size="small"
                    weight="bold"
                    margin={{ bottom: 'small' }}
                >
                    Summary
                </StyledSubTitleText>
                <DescriptiveGenericSection
                    metadataSection={remainingSummaryObjects}
                    metadataSectionName="summary"
                    lastSection={props.lastSection}
                />
            </Box>
        );
    }
};

export default DescriptiveSummary;
