import { Box } from 'grommet';
import { seperateCamelCase } from '../../../../../utils/javascriptUtilities';
import {
    MetadataSection,
    NestedMetadataSection,
} from '../../IndividualDatasetPageContents/IndividualDatasetPageContents';
import { DescriptiveGenericSectionKeyValue, StyledSubTitleText } from './DescriptiveMetadataListUtils';
import theme from '../../../../../style/theme';
import styled from 'styled-components';
import { BoxTypes } from 'grommet/components/Box';

interface GenericSectionProps {
    metadataSection: MetadataSection;
    metadataSectionName: string;
    lastSection: boolean;
}

const DescriptiveGenericSection = ({ metadataSection, metadataSectionName }: GenericSectionProps) => {
    const filteredMetadataSection = Object.assign({}, metadataSection);
    let branding = window?.config?.branding;
    if (['ons', 'dsc2', 'adr'].includes(branding)) {
        delete filteredMetadataSection?.publisher;
    }
    return (
        <Box
            data-testid={`descriptive-${metadataSectionName}-block`}
            background={theme.global.colors.default.lightGrey}
            pad={{
                horizontal: 'xsmall',
                top: 'xsmall',
                bottom: 'none',
            }}
            round="10px"
        >
            {
                // @ts-ignore
                Object.entries(filteredMetadataSection).map(([key, value], index) => {
                    console.log([key, value]);

                    return Array.isArray(value) && value && value.every((e) => typeof e === 'object') ? (
                        <DisplayNestedListMetadataSection
                            key={key}
                            nestedMetadataSectionName={key}
                            // @ts-ignore
                            nestedMetadataSection={value}
                            isFirstItem={index === 0}
                        />
                    ) : Array.isArray(value) || typeof value !== 'object' ? (
                        // This is a directly contained Metadata Entry of the Metadata Section, so we display it directly
                        <DescriptiveGenericSectionKeyValue
                            key={key}
                            title={key}
                            convertTitleFromCamelCase
                            value={value}
                            direction={
                                (Array.isArray(value) && value.length > 1) || isLongString(value) ? 'column' : 'row'
                            }
                        />
                    ) : (
                        // typeof value === 'object' && !Array.isArray(value)
                        // Therefore it must be a NestedMetadataSection, so we display it accordingly
                        Object.keys(value).length > 0 && (
                            <DisplayNestedMetadataSection
                                key={key}
                                nestedMetadataSectionName={key}
                                nestedMetadataSection={value}
                                isFirstItem={index === 0}
                            />
                        )
                    );
                })
            }
        </Box>
    );
};

type StyledBoxProps = BoxTypes & { isFirstItem: boolean };

const StyledBox = styled(Box)<StyledBoxProps>`
    ${(props) =>
        !props.isFirstItem &&
        `
            padding-top: 10px;
            border-top: solid 1px ${
                theme?.global?.colors[window.localStorage.getItem('branding') || 'default']?.dividerLine
            };
        `}
`;

interface DisplayNestedMetadataSectionProps {
    nestedMetadataSectionName?: string;
    nestedMetadataSection: NestedMetadataSection;
    isFirstItem: boolean;
}

const DisplayNestedMetadataSection = ({
    nestedMetadataSectionName,
    nestedMetadataSection,
    isFirstItem,
}: DisplayNestedMetadataSectionProps) => {
    return (
        <StyledBox
            margin={{
                bottom: 'medium',
            }}
            isFirstItem={isFirstItem}
        >
            {nestedMetadataSectionName && (
                <DisplayNestedMetadataSectionName nestedMetadataSectionName={nestedMetadataSectionName} />
            )}
            {Object.entries(nestedMetadataSection).map(([subKey, subVal]) => {
                return (
                    <DescriptiveGenericSectionKeyValue
                        key={subKey}
                        title={subKey}
                        convertTitleFromCamelCase
                        value={
                            Array.isArray(subVal) &&
                            subKey !== 'accessService' &&
                            isSmallArrayAndFirstItemIsNotALink(subVal)
                                ? subVal.join(', ')
                                : subVal
                        }
                        direction={
                            (Array.isArray(subVal) && isLargeArrayOrFirstItemIsALink(subVal)) ||
                            subKey === 'accessService' ||
                            isLongString(subVal)
                                ? 'column'
                                : 'row'
                        }
                    />
                );
            })}
        </StyledBox>
    );
};

interface DisplayNestedListMetadataSectionProps {
    nestedMetadataSectionName?: string;
    nestedMetadataSection: NestedMetadataSection[];
    isFirstItem: boolean;
}

const DisplayNestedListMetadataSection = ({
    nestedMetadataSectionName,
    nestedMetadataSection,
    isFirstItem,
}: DisplayNestedListMetadataSectionProps) => {
    return (
        <StyledBox
            margin={{
                bottom: 'medium',
            }}
            isFirstItem={isFirstItem}
        >
            {nestedMetadataSectionName && (
                <DisplayNestedMetadataSectionName nestedMetadataSectionName={nestedMetadataSectionName} />
            )}
            {nestedMetadataSection.map((item) => {
                return (
                    <DisplayNestedMetadataSection
                        key={nestedMetadataSectionName}
                        nestedMetadataSection={item}
                        isFirstItem={true}
                    />
                );
            })}
        </StyledBox>
    );
};

const DisplayNestedMetadataSectionName = ({ nestedMetadataSectionName }: { nestedMetadataSectionName: string }) => {
    return (
        <Box
            margin={{
                bottom: 'small',
            }}
        >
            <StyledSubTitleText size="small" weight="bold">
                {seperateCamelCase(nestedMetadataSectionName)}
            </StyledSubTitleText>
        </Box>
    );
};

const isSmallArrayAndFirstItemIsNotALink = (array: string[]) => {
    return array.length <= 3 && array[0].search('http') === -1 && array[0].search('www') === -1;
};

const isLargeArrayOrFirstItemIsALink = (array: string[]) => {
    return !isSmallArrayAndFirstItemIsNotALink(array);
};

const isLongString = (value: any) => {
    return typeof value === 'string' && value.length > 600;
};
export default DescriptiveGenericSection;
