import React, { useState, useEffect, useLayoutEffect, useMemo } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { TreeItem, TreeView } from '@material-ui/lab';
import store from '../../../redux/store';
import { showTreeviewDataInDataSet } from '../../../redux/actions/actions';
import FolderIcon from '@material-ui/icons/Folder';
import { fetchChildNodes } from '../../../api/fetchChildNodes';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactTooltip from 'react-tooltip';
// import { ToolTip } from '../../MDW/MDW';
import { Tooltip } from '@material-ui/core';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
// const showtooltip = () => {
//     ReactTooltip.show();
// };
import './Treelist.css';
import { Box, Text } from 'grommet';
import { Cubes } from 'grommet-icons';

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.dark,
        color: 'white',
        boxShadow: theme.shadows[1],
        fontSize: 15,
    },
}))(Tooltip);

const StyledText = styled(Text)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const StyledBox = styled(Box)`
    overflow: hidden;
`;

function TreeList(props) {
    let adrConfig = window.config.branding === 'adr';
    const location = useLocation();
    const history = useHistory();
    // console.log(props);
    let structuralMetadata = useMemo(() => store.getState().dataSet.structuralMetadata);
    const sorterFunc = (param) => {
        let sorted = param.sort(function (a, b) {
            const nameA = a.name.toUpperCase(); // ignore upper and lowercase
            const nameB = b.name.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
        return sorted;
    };
    const [childNodes, setChildNodes] = useState(
        structuralMetadata.map((node) => <TreeList key={String(node.id)} {...node} />),
    );
    const [initial, setinitial] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    const [nameOftreeListTitile, setNameOftreeListTitile] = useState('');
    const [mainUrl, setMainURl] = useState(location.state && location.state?.from.split('||')[1]);
    const [tree, setTree] = useState([]);

    const handleSend = (nodes) => {
        if (nodes) {
            store.dispatch(showTreeviewDataInDataSet({ nodes, showTreeView: true }));
            // console.log(nodes);
        }
    };
    const selectedItem = (selected) => {
        // console.log(selected);
    };
    useLayoutEffect(() => {
        if (props.openFirstClass && props.firstCallOptionIndex === 0) {
            const pathVariable = currentLocation.split('&');
            // console.log(`${pathVariable[0]}`, '\n', `&${pathVariable[1]}`, '\n', `&dataclass=${props.id}`);
            const originParam = pathVariable[1]?.includes('origin') ? `&${pathVariable[1]}` : '';
            history.push(`${pathVariable[0]}${originParam}&dataclass=${props.id}`);
        }
    }, [window.location]);

    let currentLocation = location.pathname + location.search;
    // console.log(location);

    const onClicks = (e, props) => {
        e.persist();
        const pathVariable = currentLocation.split('&');
        const originParam = pathVariable[1]?.includes('origin') ? `&${pathVariable[1]}` : '';
        history.push(`${pathVariable[0]}${originParam}&dataclass=${props.id}`);
        switch (e.detail) {
            case 1:
                handleSend(props);
                break;
            case 2:
                selectedItem(props);
                break;
            default:
                break;
        }
        // console.log(currentLocation.split("&")[0],"-----");
    };
    const handleMousOver = (e) => {
        setNameOftreeListTitile(e.target.innerHTML);
    };
    const handleChange = (event, nodes) => {
        const expandingNodes = nodes[0] != 1 ? nodes.filter((x) => !expanded.includes(x)) : null;
        setExpanded(nodes);
        if (expandingNodes?.[0]) {
            setisLoading(true);
            const childId = expandingNodes[0];
            let originId = new URLSearchParams(location.search).get('origin');
            return fetchChildNodes(childId, originId).then((unsortedResult) => {
                let resultDataClasses = unsortedResult?.dataClasses && sorterFunc(unsortedResult?.dataClasses);
                delete unsortedResult.dataClasses;
                let result = unsortedResult;
                result.dataClasses = resultDataClasses && resultDataClasses;
                setisLoading(false);
                Array.isArray(result.dataClasses) && result.dataClasses.length > 0
                    ? setChildNodes(result.dataClasses.map((node) => <TreeList key={String(node.id)} {...node} />))
                    : setChildNodes(null);
                // Array.isArray(result.dataElements) && result.dataElements.length > 0
                //     ? setChildNodes(result.dataElements.map((node) => <TreeList key={String(node.id)} {...node} />))
                //     : setChildNodes(
                //           <TreeView>
                //               <TreeItem onClick={() => handleSend(result)} nodeId={result.id} label={result.name} />
                //           </TreeView>,
                //       );
            });
        }
    };
    return !isLoading ? (
        <TreeView
            defaultCollapseIcon={props.childClasses && <ExpandMoreIcon />}
            defaultExpandIcon={props.childClasses && <ChevronRightIcon />}
            // defaultCollapseIcon={childNodes?.elementType === "DataClass" && <ExpandMoreIcon />}
            // defaultExpandIcon={childNodes?.elementType === "DataClass" && <ChevronRightIcon />}
            // defaultSelected={() => selectedItem(props)}
            expanded={expanded}
            onNodeToggle={handleChange}
            style={{ color: adrConfig ? 'black' : '' }}
        >
            {/*The node below should act as the root node for now */}
            <LightTooltip
                style={{ fontSize: '10px' }}
                title={nameOftreeListTitile == props.name ? props.name : ''}
                placement="right"
            >
                <TreeItem
                    onClick={(e) => onClicks(e, props)}
                    onMouseOverCapture={(e) => handleMousOver(e)}
                    nodeId={String(props.id)}
                    label={
                        <Box
                            background={'transparent'}
                            direction="row"
                            width="calc(100% - 24px);"
                            margin={{ vertical: '2px' }}
                            overflow="hidden"
                            gap="xsmall"
                            align="center"
                        >
                            <Cubes size="19px" />
                            <StyledText size="small">{props.name}</StyledText>
                        </Box>
                    }
                    data-tag={props.id}
                    className="treeItem"
                >
                    {childNodes || [<div key="stub" />]}
                </TreeItem>
            </LightTooltip>
        </TreeView>
    ) : (
        <CircularProgress color="white" disableShrink size={19} />
    );
}
export default TreeList;
// import React, { useState } from 'react';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';
// import { TreeItem, TreeView } from '@material-ui/lab';
// import store from '../../../redux/store';
// import { showTreeviewDataInDataSet } from '../../../redux/actions/actions';
// import { fetchChildNodes } from '../../../api/fetchChildNodes';
// function TreeList(props) {
//     let dataSet = store.getState().dataSet;
//     const [childNodes, setChildNodes] = useState([]);
//     const [expanded, setExpanded] = useState([]);
//     const [isLoading, setisLoading] = useState(true);

//     React.useEffect(() => {
//         // let id = window.location.search.slice(4);
//         // fetchChildNodes(id)
//         //     .then((d) => {
//         //         console.log(d);
//         //         setChildNodes((prev) => [...prev, d]);
//         //         setisLoading(false);
//         //     });
//         setChildNodes((prev) => [...prev, dataSet]);
//                 setisLoading(false);
//     }, []);
//     // const handleChange = (event, nodes) => {
//     //     const expandingNodes = nodes.filter((x) => !expanded.includes(x));
//     //     setExpanded(nodes);
//     //     console.log(nodes,"nodes");
//     //     if (expandingNodes[0]) {
//     //         const childId = expandingNodes[0];
//     //         fetchChildNodes(childId).then((result) =>
//     //             setChildNodes(result.children.map((node) => <TreeList key={node.id} {...node} />)),
//     //         );
//     //     }
//     // };

//     const handleChange = (event, nodes) => {
//         console.log(nodes, event);
//         setExpanded(nodes);
//         // fetchChildNodes(1)
//         //     // .then((result) =>
//         //     //     setChildNodes(result.children.map((node) => <TreeList key={node.id} {...node} />)),
//         //     // );
//         //     .then((result) =>
//         //         //    console.log(result,"result"),
//         //         setChildNodes(result.map((node, index) => {
//         //             console.log(node,index,'asd');
//         //             if (node.dataElements?.length>=0){
//         //                 return (
//         //                     <TreeList key={node.id == 1 ? node.id : index} {...node.dataClasses} />
//         //                 )
//         //             }else{
//         //                 <TreeItem key={node.id == 1 ? node.id : index} {...node.dataElements} />
//         //             }
//         //         })),
//         //     );
//     };

//     // const handleSelect = (e, nodeId) => {
//     //     e.persist();

//     //     console.log(e, nodeId);
//     // };

//     // const justClick = () => {
//     //     console.log('justClick')
//     // };

//     // return (
//     //     <TreeView
//     //         defaultCollapseIcon={<ExpandMoreIcon />}
//     //         defaultExpandIcon={<ChevronRightIcon onClick={justClick} />}
//     //         expanded={expanded}
//     //         onNodeToggle={handleChange}
//     //         onNodeSelect={handleSelect}
//     //     >
//     //         {/*The node below should act as the root node for now */}
//     //         <CustomTreeItem nodeId={props.id} label={props.name}>
//     //             {childNodes || [<div key="stub" />]}
//     //         </CustomTreeItem>
//     //     </TreeView>
//     // );

//     const handleSend = (nodes) => {
//         console.log('handleSend', nodes);
//         if (nodes.dataElements?.length || nodes.dataClasses?.length > 0) {
//             store.dispatch(showTreeviewDataInDataSet({ nodes, showTreeView: true }));
//         } else {
//             return;
//         }
//     };
//     console.log(childNodes);
//     const renderTreeDataClasses = (nodes) => {
//         console.log('renderTreeDataClasses', nodes);
//         return (
//             <div>
//                 {isLoading ? (
//                     <div>Loading...</div>
//                 ) : (
//                     <div>
//                         <TreeItem
//                             onClick={() => handleSend(nodes)}
//                             key={String(nodes.id)}
//                             nodeId={nodes.id}
//                             label={nodes.name}
//                         >
//                             {' '}
//                             {nodes?.children?.length > 0
//                                 ? nodes.children.map((node) => {
//                                     console.log('mapofTreedataClassses', node);
//                                     return node && renderTreeDataClasses(node);
//                                 })
//                                 : null}
//                         </TreeItem>
//                     </div>
//                 )}
//             </div>
//         );
//     };

//     const renderTreeDataElements = (nodes) => {
//         console.log('renderTreeDataElements', nodes);
//         return (
//             <div>
//                 {isLoading ? (
//                     <div>Loading...</div>
//                 ) : (
//                     <div>
//                         <TreeItem
//                             onClick={() => handleSend(nodes)}
//                             key={String(nodes.id)}
//                             nodeId={String(nodes.id)}
//                             label={nodes.name}
//                         >
//                             {Array.isArray(nodes.dataElements)
//                                 ? nodes.dataElements.map((node) => {
//                                     console.log('mapofTreeDataElemenst', node);
//                                     return renderTreeDataElements(node);
//                                 })
//                                 : null}
//                         </TreeItem>
//                     </div>
//                 )}
//             </div>
//         );
//     };
//     return (
//         <>
//             <TreeView
//                 aria-label="rich object"
//                 defaultCollapseIcon={<ExpandMoreIcon />}
//                 // defaultExpanded={['root']}
//                 defaultExpandIcon={<ChevronRightIcon />}
//                 sx={{ height: '90vh', flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
//             >
//                 {childNodes?.dataClasses && renderTreeDataClasses(childNodes.dataClasse)}
//                 {childNodes?.dataElements && renderTreeDataElements(childNodes.dataElements)}
//             </TreeView>
//         </>
//     );
// }
// export default TreeList;
