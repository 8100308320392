import React from 'react';
import analytics from '../../analytics/analytics';

const useGoogleAnalytics = () => {
    React.useEffect(() => {
        analytics.init();
    }, []);
};

export default useGoogleAnalytics;
