import { Box, Button, Image, Text } from 'grommet';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { getSingleElement } from '../../../../../api/getSingleElement';
import detailsImage from '../../../../../assets/images/details.png';
import theme from '../../../../../style/theme';
import { List } from '../../../../MDW/MDW';
import { StyledMetadataTitleBox } from '../../IndividualDatasetPageContents/IndividualDatasetPageContentsUtils';
import { StyledMetadataBox } from '../DescriptiveMetadata/DescriptiveMetadataListUtils';
import DataElementPopup from './DataElementPopup/DataElementPopup';
import useThrowErrorFromComponent from '../../../../../hooks/useThrowErrorFromComponent';
import DataClassHeader from './DataClassHeader';
import { fetchChildNodes } from '../../../../../api/fetchChildNodes';
import { fetchMetaAndRelations } from '../../../../../api/fetchMetaAndRelations';
import * as DOMPurify from 'dompurify';
import { ElementPopUpContentBlock, StyledGridBox, StyledGridText } from './DataElementPopup/DataElementPopupUtils';
import { Medium } from 'grommet-icons';
import { Accordion, AccordionDetails } from '@material-ui/core';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Scrollbar } from 'react-scrollbars-custom';
import { Link, useLocation, useParams } from 'react-router-dom';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
const ListWrapper = ({ className, ...props }) => {
    return (
        <Box className={className}>
            <List {...props} />
        </Box>
    );
};

const StyledList = styled(ListWrapper)`
    .track-vertical {
        background-color: ${theme.global.colors.lightGrey};
    }
    z-index: 1;
`;

const ElementText = styled(Text)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    margin: 0px;
`;

const ElementTextBox = styled(Box)`
    display: grid;
`;
const AccordionSummary = withStyles({
    root: {
        minHeight: 24,
        '&$expanded': {
            minHeight: 24,
        },
    },
    content: {
        margin: '0',
        '&$expanded': {
            margin: '0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);
const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
        fontSize: '14px',
    },
}));
const StructuralDataElementRow = (props) => {
    const { dataElements, generateOnClickElementRowCallback, index } = props;
    const location = useLocation();
    let originId = new URLSearchParams(location.search).get('origin');

    DOMPurify.addHook('afterSanitizeAttributes', function (node) {
        // set all elements owning target to target=_blank
        if ('target' in node) {
            node.setAttribute('target', '_blank');
            node.setAttribute('rel', 'noopener');
        }
    });
    let desc = DOMPurify.sanitize(dataElements[index].description, { USE_PROFILES: { html: true } });
    desc = desc && desc.length < 70 ? desc : desc.slice(0, 70) + '...';
    return (
        <React.Fragment>
            <StyledMetadataBox style={{ backgroundColor: props.colorIndex, paddingLeft: '5px' }}>
                <Box data-testid={`element-row-id-${props.index}`} style={{ height: '34px', justifyContent: 'center' }}>
                    <Button
                        plain
                        onClick={props.generateOnClickElementRowCallback(
                            props?.dataElements[props.index]?.id,
                            originId,
                        )}
                    >
                        <Box direction="row" align="center">
                            <Box
                                width={'4%'}
                                margin={{
                                    right: 'xsmall',
                                }}
                            >
                                <Image src={detailsImage} aria-hidden width="16px" />
                            </Box>
                            <ElementTextBox width={'25%'}>
                                <ElementText weight="bold" size="xsmall">
                                    {dataElements[index].name}
                                </ElementText>
                            </ElementTextBox>
                            <ElementTextBox
                                width={'60%'}
                                margin={{
                                    left: 'medium',
                                }}
                            >
                                <ElementText size="xsmall" alignSelf="start">
                                    {/* {props.dataElements[props.index].description} */}
                                    {/* {desc} */}
                                    <div
                                        style={{ margin: '0px' }}
                                        dangerouslySetInnerHTML={{
                                            __html: desc.slice(0, 70) + (desc.length > 70 ? '...' : ''),
                                        }}
                                    ></div>
                                </ElementText>
                            </ElementTextBox>
                            <Box
                                direction="row-reverse"
                                flex={'grow'}
                                width="4%"
                                margin={{
                                    right: 'xsmall',
                                }}
                            >
                                <ElementText size="xsmall" alignSelf="start">
                                    {dataElements[index].occurs}
                                </ElementText>
                            </Box>
                        </Box>
                    </Button>
                </Box>
            </StyledMetadataBox>
        </React.Fragment>
    );
};
const StructuralMetaDataRow = (props) => {
    DOMPurify.addHook('afterSanitizeAttributes', function (node) {
        // set all elements owning target to target=_blank
        if ('target' in node) {
            node.setAttribute('target', '_blank');
            node.setAttribute('rel', 'noopener');
        }
    });
    let desc = DOMPurify.sanitize(props.metadata[props.index].value, { USE_PROFILES: { html: true } });
    return (
        <React.Fragment>
            <StyledMetadataBox style={{ backgroundColor: props.colorIndex, paddingLeft: '5px' }}>
                <Box data-testid={`element-row-id-${props.index}`} style={{ height: '34px', justifyContent: 'center' }}>
                    <Button
                        plain
                        // onClick={props.generateOnClickElementRowCallback(props?.dataElements[props.index]?.id)}
                    >
                        <Box direction="row">
                            <Box
                                width={'4%'}
                                margin={{
                                    right: 'xsmall',
                                }}
                            >
                                <Image src={detailsImage} aria-hidden width="16px" />
                            </Box>
                            <ElementTextBox width={'25%'}>
                                <ElementText weight="bold" size="xsmall">
                                    {props.metadata[props.index].key}
                                </ElementText>
                            </ElementTextBox>
                            <ElementTextBox
                                width={'60%'}
                                margin={{
                                    left: 'medium',
                                }}
                            >
                                <ElementText size="xsmall" alignSelf="start">
                                    <div dangerouslySetInnerHTML={{ __html: desc.slice(0, 100) }}></div>
                                </ElementText>
                            </ElementTextBox>
                        </Box>
                    </Button>
                </Box>
            </StyledMetadataBox>
        </React.Fragment>
    );
};

// const StyledImageBox = styled(Box)`
//     ${(props) => props.opacity && `opacity: ${props.opacity};`}
// `;

const StyledImageBox = styled(Box)`
    ${(props) =>
        props.opacity &&
        `opacity: ${props.opacity};
        `}
    align-self: center;
`;

const StructuralRelationsRow = (props) => {
    // let desc = DOMPurify.sanitize(props.relations[props.index].description, { USE_PROFILES: { html: true } });
    const location = useLocation();
    let originId = new URLSearchParams(location.search).get('origin');
    const originParam = originId ? `&origin=${originId}` : '';
    const classes = useStyles();
    return (
        <React.Fragment>
            <StyledMetadataBox style={{ backgroundColor: props.colorIndex, paddingLeft: '5px' }}>
                <Box data-testid={`element-row-id-${props.index}`} style={{ height: '34px', justifyContent: 'center' }}>
                    <Button plain onClick={(e) => {}}>
                        <Box direction="row">
                            <StyledImageBox
                                width={'4%'}
                                margin={{
                                    right: 'xsmall',
                                }}
                                opacity={props?.index === 0 ? '0' : '1'}
                            >
                                <Image src={detailsImage} aria-hidden width="16px" />
                            </StyledImageBox>
                            <ElementTextBox width={'25%'}>
                                <ElementText weight={props?.index === 0 ? 'bold' : 'normal'} size="xsmall">
                                    {props.relations[props.index].relationshipName}
                                </ElementText>
                            </ElementTextBox>
                            <ElementTextBox
                                width={'30%'}
                                margin={{
                                    left: 'medium',
                                }}
                            >
                                <ElementText
                                    size="xsmall"
                                    alignSelf="start"
                                    weight={props?.index === 0 ? 'bold' : 'normal'}
                                >
                                    {props?.index === 0 ? (
                                        props.relations[props.index].targetElementName
                                    ) : (
                                        <Link
                                            to={`/browser/dataset?id=${
                                                props.relations[props.index].targetDataModelId
                                            }${originParam}&dataclass=${Number(
                                                props.relations[props.index].targetElementId,
                                            )}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {props.relations[props.index].targetElementName}
                                        </Link>
                                    )}
                                    {/* {props.relations[props.index].targetElementName} */}
                                </ElementText>
                            </ElementTextBox>
                            <ElementTextBox
                                width={'50%'}
                                margin={{
                                    left: 'medium',
                                }}
                            >
                                <Tooltip
                                    title={props.relations[props.index].targetDataModelName}
                                    classes={{ tooltip: classes.button }}
                                >
                                    <ElementText
                                        size="xsmall"
                                        alignSelf="start"
                                        weight={props?.index === 0 ? 'bold' : 'normal'}
                                    >
                                        {props?.index === 0
                                            ? props.relations[props.index].targetDataModelName
                                            : props.relations[props.index].targetDataModelName}
                                    </ElementText>
                                </Tooltip>
                            </ElementTextBox>
                            <ElementTextBox
                                width={'30%'}
                                margin={{
                                    left: 'xsmall',
                                }}
                            >
                                <ElementText
                                    size="xsmall"
                                    alignSelf="start"
                                    weight={props?.index === 0 ? 'bold' : 'normal'}
                                >
                                    {props.relations[props.index].targetElementType}
                                </ElementText>
                            </ElementTextBox>
                            <StyledImageBox
                                direction="row-reverse"
                                flex={'grow'}
                                width="4%"
                                margin={{
                                    right: 'xsmall',
                                }}
                                opacity={props?.index === 0 ? '0' : '1'}
                            >
                                <ElementText size="xsmall" alignSelf="start">
                                    {props.relations[props.index].occurs}
                                </ElementText>
                            </StyledImageBox>
                        </Box>
                    </Button>
                </Box>
            </StyledMetadataBox>
        </React.Fragment>
    );
};

const relationsTitle = {
    relationshipName: 'Relationship',
    targetElementName: 'Element Name',
    targetElementId: '99999999999999999999999999999999999999999999999999',
    targetDataModelId: 999999999999999999999999999999999999999999999999991,
    targetDataModelName: 'Dataset (Target)',
    targetElementType: 'Element Type',
};

const StructuralDataContent = ({ rebuildToolTip, heightNumber, currentClass, ...props }) => {
    const [isModalShown, setIsModalShown] = React.useState(false);
    const [currentElement, setCurrentElement] = React.useState();
    const [dataElement, setDataElement] = React.useState();
    const [metadata, setMetadata] = React.useState();
    const [relations, setRelations] = React.useState();
    const branding = window.localStorage.getItem('branding');
    const [desc, setHeaderDesc] = React.useState();
    const [name, setHeaderName] = React.useState();
    const [elementsTitleBoxHeight, setElementsTitleBoxHeight] = React.useState(1000);
    const [metadataListHeight, setMetadataListHeight] = React.useState(300);
    const elementDataContainerRef = React.useRef();
    const elementDataTitleBoxRef = React.useRef();
    const classElementListRef = React.useRef();
    const throwErrorFromComponent = useThrowErrorFromComponent();
    const dataId = currentClass?.nodes?.id;
    // const getSingleElement =
    const updateElementListHeight = React.useCallback(() => {
        elementDataContainerRef.current &&
            elementDataTitleBoxRef.current &&
            setElementsTitleBoxHeight(
                elementDataContainerRef.current.getBoundingClientRect().height -
                    elementDataTitleBoxRef.current.getBoundingClientRect().height,
            );
    }, []);

    const setModal = (value) => {
        setCurrentElement({});
        setIsModalShown(value);
    };
    const location = useLocation();

    React.useEffect(() => {
        updateElementListHeight();
    }, [currentClass, elementDataContainerRef, elementDataTitleBoxRef, updateElementListHeight]);

    let isRouteActiveForDataElement = location.search.includes('dataelement');

    React.useEffect(() => {
        let currentId = location.search.split('=').slice(-1)[0];
        let originId = new URLSearchParams(location.search).get('origin');
        if (currentId && isRouteActiveForDataElement) {
            setTimeout(() => {
                currentId &&
                    getSingleElement(currentId, originId)
                        .then((response) => response.json())
                        .then((element) => {
                            element && currentId && setCurrentElement(element);
                            setIsModalShown(true);
                        })
                        .catch((err) => {
                            throwErrorFromComponent(new Error(err));
                        });
            }, 100);
        }
    }, []);

    React.useEffect(() => {
        let dClss = new URLSearchParams(location.search).get('dataclass');
        let originId = new URLSearchParams(location.search).get('origin');
        // let dSet = new URLSearchParams(location.search).get('id');

        // fetchChildNodes(linkId).then((d) => {
        //     setDataElement(d);
        //     console.log(d,"4444444444")
        // });

        if (dClss) {
            fetchChildNodes(dClss, originId).then((d) => {
                setDataElement(d.dataElements);
            });
            fetchMetaAndRelations(dClss, originId).then((d) => {
                setMetadata(d.elementMetadata);
                Array.isArray(d?.relationships) && setRelations([relationsTitle, ...d.relationships]);
                setHeaderDesc(d.description);
                setHeaderName(d.name);
            });
        }
    }, [location.search]);
    React.useEffect(() => {
        window.addEventListener('resize', updateElementListHeight);
        return () => {
            window.removeEventListener('resize', updateElementListHeight);
        };
    }, [updateElementListHeight]);
    const generateOnClickElementRowCallback = React.useCallback(
        (dataElementID, originId) => (e) => {
            dataElementID &&
                getSingleElement(dataElementID, originId)
                    .then((response) => response.json())
                    .then((element) => {
                        element && dataElementID && setCurrentElement(element);
                        setIsModalShown(true);
                    })
                    .catch((err) => {
                        throwErrorFromComponent(new Error(err));
                    });
        },
        [throwErrorFromComponent],
    );

    return (
        <div
            ref={elementDataContainerRef}
            style={{
                display: 'flex',
                flexDirection: 'column',
                boxSizing: 'border-box',
                padding: '22px 12px 12px 12px',
                borderRadius: theme.radii.small,
                color: '#444444',
                backgroundColor: '#FFFFFF',
                minHeight: '-1px',
                width: '100%',
                maxWidth: '100%',
                // borderRadius: '6px',
            }}
        >
            {isModalShown && (
                <DataElementPopup
                    currentElement={currentElement}
                    setIsModalShown={setModal}
                    currentClass={currentClass}
                    rebuildToolTip={rebuildToolTip}
                    // round={props.round}
                    heightNumber={heightNumber}
                ></DataElementPopup>
            )}
            <StyledMetadataTitleBox
                width="100%"
                height={{
                    min: '100px',
                }}
                background={'white'}
                // pad="small"
                classTitle
                ref={elementDataTitleBoxRef}
                // margin={{ top: '10px' }}
            >
                <DataClassHeader name={name} description={desc} />
            </StyledMetadataTitleBox>
            {dataElement && (
                <React.Fragment>
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary
                            style={{ minHeight: '34px' }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Text size="xsmall" weight="bold">
                                Data elements
                            </Text>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '99%',
                                    alignItems: 'center',
                                    maxHeight: branding !== 'dsc2' ? '300px' : '',
                                    overflowY: branding !== 'dsc2' ? 'scroll' : '',
                                }}
                            >
                                {dataElement &&
                                    dataElement?.map((item, index) => {
                                        const dataElements = dataElement;
                                        return (
                                            <StructuralDataElementRow
                                                dataElements={dataElements}
                                                generateOnClickElementRowCallback={generateOnClickElementRowCallback}
                                                index={index}
                                                colorIndex={(index + 1) % 2 === 0 ? '#eef1f7' : ' white'}
                                                key={index}
                                            ></StructuralDataElementRow>
                                        );
                                    })}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </React.Fragment>
            )}
            {relations && (
                <Accordion defaultExpanded={dataElement && dataElement ? false : true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Text size="xsmall" weight="bold">
                            Relationships
                        </Text>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '99%',
                                alignItems: 'center',
                            }}
                            data-testid="relations-list"
                        >
                            {relations &&
                                relations?.map((item, index) => {
                                    return (
                                        <StructuralRelationsRow
                                            relations={relations}
                                            generateOnClickElementRowCallback={generateOnClickElementRowCallback}
                                            index={index}
                                            key={index}
                                            colorIndex={(index + 1) % 2 === 0 ? '#eef1f7' : ' white'}
                                        ></StructuralRelationsRow>
                                    );
                                })}
                        </div>
                        {/* </StyledList> */}
                    </AccordionDetails>
                </Accordion>
            )}
            {metadata && (
                <Accordion defaultExpanded={(dataElement && dataElement) || relations ? false : true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Text size="xsmall" weight="bold">
                            Metadata
                        </Text>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '99%',
                                alignItems: 'center',
                            }}
                        >
                            {metadata &&
                                metadata.map((item, index) => {
                                    return (
                                        <StructuralMetaDataRow
                                            metadata={metadata}
                                            generateOnClickElementRowCallback={generateOnClickElementRowCallback}
                                            key={index}
                                            index={index}
                                            colorIndex={(index + 1) % 2 === 0 ? '#eef1f7' : ' white'}
                                        ></StructuralMetaDataRow>
                                    );
                                })}
                        </div>
                    </AccordionDetails>
                </Accordion>
            )}
        </div>
    );
};

export default StructuralDataContent;

StructuralDataContent.propTypes = {
    rebuildToolTip: PropTypes.func.isRequired,
    heightNumber: PropTypes.number.isRequired,
    round: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
