import { Box, Button, Text } from 'grommet';

import theme from '../../../../style/theme';
import store from '../../../../redux/store';
import styled from 'styled-components';
import lockBlack from '../../../../assets/images/lockBlack.svg';
import lockWhite from '../../../../assets/images/lockWhite.svg';
export type Dataset = DescriptiveMetadata & EverythingElseInDataset;

type EverythingElseInDataset = {
    id: string;
    structuralMetadata: DataClassInfo[];
    data?: DataDownloadInformation;
    dataStatus?: DataStatus;
};

type DataStatus = {
    dataStatus: 'None' | 'Open' | 'Secured' | 'External';
    dataAccess?: string;
};

export const splitDataset: (dataset: Dataset) => [DescriptiveMetadata, EverythingElseInDataset] = (
    dataset: Dataset,
) => {
    const { id, structuralMetadata, data, dataStatus, ...descriptiveMetadata } = dataset;
    return [descriptiveMetadata, { id, structuralMetadata, data, dataStatus }];
};

export type DescriptiveMetadata = {
    summary: SummaryMetadataSection;
} & {
    [key: string]: MetadataSection | Observation[];
};

export interface SummaryMetadataSection {
    title: string;
    publisher: {
        name: string;
        memberOf: string;
    };
    abstract: string;

    [key: string]: NestedMetadataSection | string | undefined;
}

export interface MetadataSection {
    [key: string]: NestedMetadataSection | string | string[] | undefined;

    documentation?: { associatedMedia: string[] };
}

export interface NestedMetadataSection {
    [key: string]: string | string[];

    [index: number]: string;
}

export interface Observation {
    observedNode: string;
    measuredValue: string;
    disambiguatingDescription: string;
    observationDate: string;
    measuredProperty: string;
    [key: string]: string;
}

export type DataClassInfo = {
    id: number;
    name: string;
};

export type DataDownloadInformation = {
    location: string;
    files: FileInfo[];
};

export type FileInfo = {
    name: string;
    size: string | number;
};
const StyledRequestAccessButton = styled(Button)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    &:focus-within div {
        border-radius: ${theme.radii.large};
        box-shadow: 0 0 0 ${theme.a11yBoxShadow.thickness}
            ${theme.global.colors[window.localStorage.getItem('branding') || 'default'].focusBorderColor};
    }
`;

const DataAccessRequestButton = (props: { dataStatus: DataStatus }) => {
    let adrConfig = window.config?.branding === 'adr';
    const isEmail = (link: string): boolean => {
        return link?.startsWith('mailto');
    };

    const isServiceDeskLink = (link: string): boolean => {
        return link?.includes('atlassian.net/servicedesk/customer');
    };

    const dataAccess = props.dataStatus?.dataAccess ?? window?.config?.requestDataAccessUrl;
    const dataSet: Dataset = store.getState().dataSet;
    const subject = `Data Access Request for Dataset: \"${dataSet.summary?.title}\" (ID: ${dataSet.id})`;
    const body = `I would like to request access for the data in this dataset: \"${dataSet.summary?.title}\" (ID: ${dataSet.id}).\n\nThe dataset can be viewed here: ${window.location.href}`;
    const hrefSuffix = isEmail(dataAccess)
        ? `?subject=${subject}&body=${body}`
        : isServiceDeskLink(dataAccess)
        ? `?summary=${subject}&description=${body}`
        : '';
    return (
        <Box>
            <StyledRequestAccessButton href={encodeURI(dataAccess + hrefSuffix)} target="_blank" plain role="link">
                <Box
                    direction="row"
                    align="center"
                    justify="evenly"
                    background={
                        theme.global.colors[window.localStorage.getItem('branding') || 'default']
                            .accessDataButtonBgColor
                    }
                    round={'12px'}
                    width="138px"
                    height={'44px'}
                >
                    <Box>
                        <img src={adrConfig ? lockBlack : lockWhite} alt={adrConfig ? 'letterBlack' : 'lockWhite'} />
                    </Box>
                    <Text
                        size="small"
                        color={theme.global.colors[window.localStorage.getItem('branding') || 'default'].primaryText}
                    >
                        {props.dataStatus?.dataStatus === 'Secured' ? 'Access Data' : 'Data Service'}
                    </Text>
                    {/* <StyledMenuButtonRightArrowImage src={thinArrowRightWhite} /> */}
                </Box>
            </StyledRequestAccessButton>
        </Box>
    );
};

export default DataAccessRequestButton;
