import { Box, ResponsiveContext } from 'grommet';
import _ from 'lodash';
import React, { CSSProperties, LegacyRef, ReactNode, useRef } from 'react';
import { VariableSizeList as ReactWindowList, VariableSizeList } from 'react-window';
// import theme from '../../../style/theme';
import ListScrollBar from './ListScrollBar';
import useListRow, { HighlightRowOnFocusType } from './useListRow';
import { RoundType } from 'grommet/utils';
import memoize from 'memoize-one';
const rowStyleOptions = ['alternate-gaps', 'blocks', 'lines'];

interface ListRefCurrent extends VariableSizeList {
    clientHeight?: number;
}

export interface ListRef {
    current: ListRefCurrent;
}

export interface MDWListProps {
    highlightRowOnFocus?: HighlightRowOnFocusType;
    children: ReactNode;
    listLength: number;
    rowBackground?: string;
    listBackground?: string;
    onScroll?: () => void;
    rowHeight?: number;
    percentageMargin?: number;
    plain?: boolean;
    border?: string;
    descriptive?: boolean;
    rowStyle?: 'alternate-gaps' | 'blocks' | 'lines';
    className?: string;
    heightNumber?: number;
    round?: RoundType;
    rowBorderRadius?: string | Object;
    listRef?: ListRef;
    rowBorder?: object | undefined;
    dataTestId?: string;
    rowPadding?: string | Object;
    StructuralDataContent?: boolean;
}

const List = React.memo(({ children, listLength, listRef, ...props }: MDWListProps) => {
    const highlightRowOnFocus = props.highlightRowOnFocus;
    const size = React.useContext(ResponsiveContext);
    const rowHeights = React.useRef(new Array(listLength).fill(50));
    const setRowHeights = (index: number, ref: ListRef) => {
        rowHeights.current[index] = ref.current.clientHeight || 50;
        listRef?.current?.resetAfterIndex?.(0);
    };
    const { rowStyle } = props;
    React.useEffect(() => {
        if (rowStyle && !rowStyleOptions.includes(rowStyle)) {
            throw Error(`rowStyle prop incorrect. Must be one of: ${rowStyleOptions.join()}`);
        }
    }, [rowStyle]);

    const getItemSize = (index: number) => {
        const value = props.rowHeight || rowHeights.current[index] || 50;
        return value;
    };
    const Row = memoize(
        useListRow({
            renderProp: children,
            size,
            onScroll: props.onScroll && _.debounce(props.onScroll, 150),
            setRowHeightCallback: setRowHeights,
            plain: props.plain,
            rowPadding: props.rowPadding,
            rowBorder: props.rowBorder,
            rowStyle: props.rowStyle,
            rowBorderRadius: props.rowBorderRadius,
            listLength,
            highlightRowOnFocus,
            percentageMargin: props.percentageMargin,
        }),
    );
    const ref = useRef() as LegacyRef<HTMLDivElement>;

    const styleRef = useRef<CSSProperties>({
        backgroundColor: props.listBackground
            ? props.listBackground
            : props.rowStyle === 'lines'
            ? 'white'
            : 'transparent',
        boxSizing: 'border-box',
        ...(props.border && { border: props.border }),
        ...(props.descriptive && { borderTop: 'none' }),
        ...convertRoundToBorderRadius(props.round || '0px 0px 6px 6px'),
    });

    return listLength === 0 ? (
        <div>No List</div>
    ) : (
        <Box
            data-testid={props.dataTestId}
            ref={ref}
            width="100%"
            round={props.round || 'none'}
            // flex={{ shrink: 1 }}
            {...(props.listBackground && { background: props.listBackground })}
        >
            <ReactWindowList
                {...props.onScroll}
                ref={listRef}
                height={props.heightNumber || 900}
                style={styleRef.current}
                itemCount={listLength}
                itemSize={getItemSize}
                width={'100%'}
                outerElementType={ListScrollBar}
                useIsScrolling
            >
                {Row}
            </ReactWindowList>
        </Box>
    );
});

List.whyDidYouRender = true;

export default List;
const convertRoundToBorderRadius = (round: RoundType) => {
    return round && typeof round === 'string' ? { borderRadius: round } : null;
};
