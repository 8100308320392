import { Box, DataTable, Text } from 'grommet';
import React from 'react';
import theme from '../../../../../../style/theme';
import { ToolTip } from '../../../../../MDW/MDW';
import { ElementPopUpContentBlock, StyledGridBox, StyledGridText } from '../DataElementPopup/DataElementPopupUtils';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

const RelationshipLink = styled(Link)`
    color: ${window.localStorage.getItem('branding') !== 'default'
        ? theme.global.colors[window.localStorage.getItem('branding')].secondary
        : ''};
`;
const DataElementPopUpRelationshipTag = ({ popUpItemRefs, relationships }) => {
    const location = useLocation();
    let originId = new URLSearchParams(location.search).get('origin');
    const originParam = originId ? `&origin=${originId}` : '';
    return (
        <>
            <Box
                pad={{
                    bottom: 'none',
                }}
            >
                <ElementPopUpContentBlock title={''} isAtBottom={relationships}>
                    <Box
                        pad={{
                            bottom: 'none',
                        }}
                    >
                        <DataTable
                            columns={[
                                {
                                    property: 'relationshipName',
                                    header: (
                                        <Text size="16px" weight={theme.fontWeights.semibold}>
                                            Relationship
                                        </Text>
                                    ),
                                    render: (datum) => (
                                        <Box pad={{ vertical: 'xsmall' }}>
                                            <Text size="small">{datum.relationshipName}</Text>
                                        </Box>
                                    ),
                                },
                                {
                                    property: 'targetElementName',
                                    header: (
                                        <Text size="16px" weight={theme.fontWeights.semibold}>
                                            Name
                                        </Text>
                                    ),
                                    render: (datum) => (
                                        <Box pad={{ vertical: 'xsmall' }}>
                                            <RelationshipLink
                                                to={`/browser/dataset?id=${datum.targetDataModelId}${originParam}&dataclass=${datum.targetElementId}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {datum.targetElementName}
                                            </RelationshipLink>
                                        </Box>
                                    ),
                                },
                                {
                                    property: `dataModelName`,
                                    header: (
                                        <Text size="16px" weight={theme.fontWeights.semibold}>
                                            Dataset
                                        </Text>
                                    ),
                                    render: (datum) => (
                                        <Box pad={{ vertical: 'xsmall' }}>
                                            <Text size="small">{datum.targetDataModelName}</Text>
                                        </Box>
                                    ),
                                    align: 'center',
                                },
                                {
                                    property: `elementType`,
                                    header: (
                                        <Text size="16px" weight={theme.fontWeights.semibold}>
                                            Type
                                        </Text>
                                    ),
                                    render: (datum) => (
                                        <Box pad={{ vertical: 'xsmall' }}>
                                            <Text size="small">{datum?.targetElementType}</Text>
                                        </Box>
                                    ),
                                    align: 'center',
                                },
                            ]}
                            data={relationships}
                        />
                    </Box>
                    <ToolTip />
                </ElementPopUpContentBlock>
            </Box>
        </>
    );
};

export default DataElementPopUpRelationshipTag;
