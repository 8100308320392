import React from 'react';
import PropTypes from 'prop-types';
import theme from '../../../../style/theme';
import { Tag, ToolTip } from '../../../MDW/MDW';
import { TagBoxContents } from './StyledComponents';
import store from '../../../../redux/store';
import { Box, Button, Text } from 'grommet';
import styled from 'styled-components';
import { isTesting } from '../../../../utils/testingUtils';
import { useLocation } from 'react-router';

const StyledClearAllButton = styled(Button)`
    line-height: 12px;
    font-weight: 500;
    &: hover {
        font-weight: ${theme.fontWeights.bold};
    }
    &:focus {
        border-radius: ${theme.radii.large};
        box-shadow: 0 0 0 ${theme.a11yBoxShadow.thickness}
            ${theme.global.colors[window.localStorage.getItem('branding') || 'default'].focusBorderColor};
    }
`;

const TagBox = React.memo(({ onFilterTagClick, ...props }) => {
    const location = useLocation();

    const [chosenTags, setChosenTags] = React.useState(store.getState().selectedFilters);
    let adrConfig = window.config?.adr;

    const handleChange = React.useCallback(() => {
        window.localStorage.setItem('searchParams', location.search);
        if (chosenTags !== store.getState().selectedFilters) {
            setChosenTags(store.getState().selectedFilters);
        }
    }, [chosenTags]);

    React.useEffect(() => {
        const unsubscribe = store.subscribe(handleChange);
        return () => {
            unsubscribe();
        };
    }, [handleChange]);

    return (
        <TagBoxContents
            data-testid={'tag-box'}
            margin={{ bottom: 'small' }}
            width="100%"
            background={adrConfig ? theme.global.colors.skyBlue : ''}
            height={{
                min: '30px',
            }}
            round={`${theme.radii.large}`}
            direction="row"
            wrap
            gap="xsmall"
            pad={{
                top: ' 15px',
                right: ' 8px',
                left: ' 6px',
                bottom: ' 6px',
            }}
            isHidden={!chosenTags || (chosenTags && chosenTags.length === 0)}
        >
            <Box width="100%" align="end">
                <StyledClearAllButton plain onClick={props.onClearAllClicked}>
                    <Text
                        size="12px"
                        margin="xsmall"
                        // color={adrConfig ? 'dark' : 'lightPastelBlue'}
                        color={'dark'}
                        as="div"
                    >
                        Clear all
                    </Text>
                </StyledClearAllButton>
            </Box>
            <Box>
                {chosenTags &&
                    chosenTags.map((tagObject, index) => (
                        <Tag
                            backgroundColor={`${theme.global.colors.faintWhite}`}
                            ariaLabel="Selected filter tag button"
                            // primary
                            // color={adrConfig ? 'dark' : 'lightPastelBlue'}
                            color={'dark'}
                            dataTip={tagObject.label}
                            dataTestId={'tag-box-tag'}
                            isChosen
                            key={`${index}-${tagObject.label}`}
                            onClick={onFilterTagClick(tagObject)}
                        >
                            {tagObject.label}
                        </Tag>
                    ))}
                {!isTesting && <ToolTip />}
            </Box>
        </TagBoxContents>
    );
});

TagBox.propTypes = {
    onFilterTagClick: PropTypes.func.isRequired,
    onClearAllClicked: PropTypes.func,
};

TagBox.whyDidYouRender = true;

export default TagBox;
