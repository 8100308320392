export const camelCaseConverter = (str, exceptionsMap = {}) => {
    if (!str) {
        throw Error;
    }
    if (exceptionsMap[str]) {
        return exceptionsMap[str];
    }
    let result = str.replace(/([A-Z])/g, ' $1');
    let final = result.charAt(0).toUpperCase() + result.slice(1);
    return final;
};

export const descriptiveMetadataCamelCaseConversionExceptionsMap = window?.config
    ?.descriptiveMetadataCamelCaseConversionExceptionsMap || { doiName: 'DOI Name', accessUrl: 'Access URL' };
