import { Box } from 'grommet';
import { useCallback } from 'react';
import { OuterScroll } from '../../MDW/MDW';
import { DataDownloadInformation } from '../IndividualDatasetPage/IndividualDatasetPageContents/IndividualDatasetPageContents';
import S3Browser from './S3Browser';
const S3BrowserWrapper = ({ data }: { data?: DataDownloadInformation }) => {
    const onScroll = useCallback((e) => {
        const element = e.currentTarget.previousSibling?.firstChild;
        const isNum = !isNaN(element?.style.opacity);
        const isDefinedNonZero = element?.style.opacity && parseInt(element?.style.opacity);
        if (element && isNum && !isDefinedNonZero) {
            element.style.opacity = '1.0';
            setTimeout(() => {
                element.style.opacity = '0.0';
            }, 1000);
        }
    }, []);
    return (
        <Box fill>
            <OuterScroll heightRelativeToParent="100%" allowOuterScroll onScroll={onScroll}>
                <S3Browser data={data} />
            </OuterScroll>
        </Box>
    );
};

export default S3BrowserWrapper;
