import React from 'react';
import { useId } from 'react-id-generator';
import styled from 'styled-components';
import theme from '../../../style/theme';
import PropTypes from 'prop-types';
import { TextArea as GrommetTextArea } from 'grommet';

const StyledTextArea = styled(GrommetTextArea)`
    font-weight: ${theme.fontWeights.body};
    font-size: ${theme.fontSizes[2]};
    &: focus {
        border: 1px solid ${theme.global.colors.primary};
    }
`;

const TextArea = ({ name, ...props }) => {
    const [textAreaId] = useId();
    return <StyledTextArea {...props} name={name} placeholder={props.placeholder} id={props.id || textAreaId} />;
};

TextArea.propTypes = {
    name: PropTypes.string.isRequired,
    id: PropTypes.string,
    placeholder: PropTypes.string,
};

export default TextArea;
