import { useCallback, useEffect, useState } from 'react';
import { xApiVersion } from '../api/getDataSets';

declare var window: { config: { client: string } };
const useClientHTML = (names: string[], force: boolean) => {
    interface ClientHTMLStateObjectType {
        [name: string]: string;
    }

    const [clientHTML, setClientHTML] = useState<ClientHTMLStateObjectType>({});

    const fetchWithXAPIVersion = async (url: string) =>
        await fetch(url, {
            method: 'get',
            headers: {
                'X-API-Version': xApiVersion,
            },
        });

    const fetchData = useCallback(async (urls) => {
        let data;
        try {
            const clientHeader = await fetchWithXAPIVersion(urls[0]);
            if (clientHeader.status === 403) throw Error('403 Forbidden');
            data = clientHeader;
        } catch {
            data = await fetchWithXAPIVersion(urls[1]);
        }
        return data.text();
    }, []);

    const clientHTMLStem = window?.config ? window.config.client || '/defaults' : ``;
    const getClientHTML = useCallback(async () => {
        const htmlList = await Promise.all(
            names.map((name) => fetchData([`${clientHTMLStem}/${name}.html`, `/defaults/${name}.html`])),
        );

        const newClientHTML: ClientHTMLStateObjectType = {};
        names.forEach((name, index) => (newClientHTML[name] = htmlList[index]));

        setClientHTML(newClientHTML);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientHTMLStem, fetchData, ...names]);

    useEffect(() => {
        (process.env.NODE_ENV !== 'test' || force) && getClientHTML();
    }, [getClientHTML, force]);

    return { clientHTML };
};

export default useClientHTML;
