import axios from 'axios';
import React from 'react';
import { addToAllFilters, receiveFilterMenuItems } from '../redux/actions/actions';
import store from '../redux/store';
import { removeArrayDuplicates } from '../utils/javascriptUtilities';
import { xApiVersion } from './getDataSets';

export const featchEndPointInclude = async (searchTerm, includes, setDataSets) => {
    var url = new URL(window.location.href);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const origin = urlParams.get('origin');
    var api = url.origin + '/api';
    var url = `${window?.config?.reactAppDatasetApi || api}/dataset?searchTerm=${searchTerm}&include=${includes}`;
    return fetch(url, {
        method: 'get',
        headers: {
            'X-API-Version': xApiVersion,
        },
    })
        .then((response) => response.json())
        .then((result) => {
            getMenuItemsFromResponse(result);
            setDataSets(result);
        })
        .catch((err) => {
            throw new Error(err);
        });
};
const getMenuItemsFromResponse = (data) => {
    const processedFilters = data.filters.map((item) => {
        return { ...item, options: processMenuItems(removeArrayDuplicates(item.options), item.name) };
    });
    store.dispatch(receiveFilterMenuItems(processedFilters));
};

export const processMenuItems = (options, catagoryName) => {
    const checkboxObjectList = options
        .map((menuItem) => {
            const ref = React.createRef();
            const checkBoxObject = {
                label: menuItem,
                ref,
                type: catagoryName.replace(/ /g, ''),
            };
            return checkBoxObject;
        })
        .filter(({ label }) => label);

    console.time('Dispatch');
    store.dispatch(
        addToAllFilters(
            checkboxObjectList.map((checkBoxObject) => {
                return { ...checkBoxObject, label: checkBoxObject.label.toLowerCase().trim() };
            }),
        ),
    );
    console.timeEnd('Dispatch');

    return checkboxObjectList;
};
