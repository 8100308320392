import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../MDW/MDW';
import { Box, Heading, Text, ResponsiveContext } from 'grommet';

const GenericPageContentHeader = ({ children, onMainButtonClick, buttonLabel, title, subTitle, ...props }) => {
    const size = React.useContext(ResponsiveContext);
    let adrConfig = window.config.branding === 'adr';
    return (
        <Box fill direction="column">
            <Box
                justify="between"
                flex={{ shrink: 0 }}
                margin={{ bottom: size === 'small' ? 'small' : '20px' }}
                direction="row"
                align="start"
                wrap
            >
                {title && subTitle && (
                    <Box direction="column" margin={{ bottom: 'medium' }}>
                        <Heading
                            margin={{
                                bottom: 'xsmall',
                                top: 'none',
                                horizontal: 'none',
                            }}
                            level="3"
                            size="small"
                        >
                            {title}
                        </Heading>
                        <Text color={adrConfig ? 'dark' : 'lightPastelBlue'} size="small" margin={{ left: '2px' }}>
                            {subTitle}
                        </Text>
                    </Box>
                )}
                {onMainButtonClick && !props.customComponent && (
                    <Button medium label={buttonLabel} onClick={onMainButtonClick} />
                )}
                {!onMainButtonClick && props.customComponent && props.customComponent}
            </Box>
            {children}
        </Box>
    );
};

GenericPageContentHeader.whyDidYouRender = true;

GenericPageContentHeader.propTypes = {
    children: PropTypes.node.isRequired,
    search: PropTypes.bool,
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
    onMainButtonClick: PropTypes.func,
    buttonLabel: PropTypes.string,
};

export default GenericPageContentHeader;
