import PropTypes from 'prop-types';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import theme from '../../../style/theme';
// import crossWhite from '../../../assets/images/crossWhite.png';
import crossPrimary from '../../../assets/images/crossPrimary.png';
import crossWhite from '../../../assets/images/crossWhite.png';

import { Button, Image, Text, Box, ButtonExtendedProps } from 'grommet';
import {
    highlightTag,
    setHighlightSelectedTags,
} from '../../pages/BrokerSearchPage/BrokerSearchPageContents/BrokerSearchPageContentsUtils';
import store from '../../../redux/store';
import { addToAllFilters } from '../../../redux/actions/actions';
import { TagObjectType } from '../../pages/BrokerSearchPage/BrokerSearchPageList';
import { BackgroundType, ColorType } from 'grommet/utils';

const StyledTag = styled(Box)`
    display: inline-flex;
`;

interface TagButtonProps extends ButtonExtendedProps {
    /** TagButton component children */
    children?: React.ReactNode;
    /** whether to show boxshadow around tag on focus */
    hasBoxShadow?: boolean;
}

const TagButton = ({ children, hasBoxShadow, ...props }: TagButtonProps) => <Button {...props}>{children}</Button>;

const StyledTagButton = styled(TagButton)`
    ${(props: any) => !props.isChosen && `margin-left: 6px;`}
    display: inline-block;
    ${(props) => !props.onClick && `cursor: default;`}
    ${(props) =>
        props.hasBoxShadow &&
        `&:focus > div {
        box-shadow: 0 0 0 ${theme.a11yBoxShadow.thickness}
            ${theme.global.colors[window.localStorage.getItem('branding') || 'default'].focusBorderColor};
    } ;`}
`;

const StyledTagText = styled(Text)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

interface TagType {
    children: ReactNode;
    iconImgSource?: string;
    ariaHidden?: boolean;
    iconImgAltText?: string;
    isChosen?: boolean;
    tagObject?: TagObjectType;
    dataTestId?: string;
    ariaLabel?: string;
    id?: string;
    theRef?: ((instance: HTMLButtonElement | null) => void) | React.RefObject<HTMLButtonElement> | null;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    dataTip?: string;
    disabled?: boolean;
    backgroundColor?: BackgroundType;
    secondary?: boolean;
    color?: ColorType;
    borderColor?: string;
    borderSize?: string;
    isHeader?: boolean;
    hasBoxShadow?: boolean;
}

const Tag = ({
    children,
    iconImgSource,
    ariaHidden,
    iconImgAltText,
    isChosen,
    hasBoxShadow = true,
    ...props
}: TagType) => {
    const { tagObject } = props;
    React.useEffect(() => {
        setHighlightSelectedTags(tagObject, children, highlightTag);
    }, [tagObject]);

    React.useEffect(() => {
        !isChosen && tagObject && store.dispatch(addToAllFilters(tagObject));
    }, [isChosen, tagObject]);

    return (
        // @ts-ignore
        <StyledTagButton
            plain
            data-testid={props.dataTestId}
            aria-label={props.ariaLabel}
            id={props.id}
            {...(ariaHidden ? { tabIndex: -1, 'aria-hidden': true } : {})}
            ref={props.theRef}
            onClick={props.onClick}
            data-tip={props.dataTip}
            disabled={!!props.disabled}
            hasBoxShadow={hasBoxShadow}
            isChosen={isChosen}
        >
            <StyledTag
                background={props.backgroundColor ? props.backgroundColor : props.secondary ? 'midGrey' : 'primary'}
                pad={{ horizontal: '8px', vertical: '4px' }}
                round={theme.radii.large}
                direction={'row'}
                gap="7px"
                align="center"
                margin={{ bottom: '4px' }}
                width={{ max: '145px' }}
                // @ts-ignore
                border={{
                    color: props.isHeader ? props.borderColor : 'none',
                    size: props.isHeader ? props.borderSize : 'none',
                }}
            >
                {iconImgSource && (
                    <Image
                        {...(!ariaHidden && iconImgAltText ? { alt: iconImgAltText } : {})}
                        src={iconImgSource}
                        height="13px"
                    />
                )}
                <StyledTagText size="xsmall" color={props.secondary ? 'primary' : props.color ? props.color : 'black'}>
                    {children}
                </StyledTagText>
                {isChosen && (
                    <Image
                        src={
                            window.localStorage.getItem('branding') === 'adr' ||
                            window.localStorage.getItem('branding') === 'dsc2'
                                ? crossPrimary
                                : crossWhite
                        }
                        alt="Close"
                        height="10px"
                    />
                )}
            </StyledTag>
        </StyledTagButton>
    );
};

export default Tag;

Tag.propTypes = {
    iconImgSource: PropTypes.string,
    tagObject: PropTypes.object,
    isChosen: PropTypes.bool,
    backgroundColor: PropTypes.string,
    color: PropTypes.string,
    secondary: PropTypes.bool,
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    dataTip: PropTypes.string,
    id: PropTypes.string,
    dataTestId: PropTypes.string,
    ariaLabel: PropTypes.string,
    borderColor: PropTypes.string,
    borderSize: PropTypes.string,
};
