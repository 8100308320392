import React, { Suspense } from 'react';
import PropTypes from 'prop-types';

const MessageHub = React.lazy(() => import('./MessageHub'));

const MessageHubContext = React.createContext();

const MessageHubProvider = ({ containerRef, notificationTimeout, ...props }) => {
    const createNotificationMessage = React.useCallback(
        (message) => {
            containerRef && typeof containerRef.current === 'function' && containerRef.current(message);
        },
        [containerRef],
    );

    return (
        <MessageHubContext.Provider value={{ createNotificationMessage }}>
            {props.children}
            <Suspense fallback={<div></div>}>
                <MessageHub
                    children={(add) => {
                        if (containerRef) {
                            containerRef.current = add;
                        }
                    }}
                    timeout={notificationTimeout}
                />
            </Suspense>
        </MessageHubContext.Provider>
    );
};

export default MessageHubProvider;

export const useMessageHub = () => {
    const context = React.useContext(MessageHubContext);

    if (context === undefined) {
        throw new Error('useMessageHub must be used within a MessageHubProvider');
    }

    return context;
};

MessageHubProvider.propTypes = {
    containerRef: PropTypes.object.isRequired,
    notificationTimeout: PropTypes.number.isRequired,
};
