import React from 'react';
import SomethingWentWrongPage from '../components/pages/SomethingWentWrongPage/SomethingWentWrongPage';
import { ErrorBoundary as ErrBoundary } from 'react-error-boundary';
import { logError } from '../api/logError';

const ErrorBoundary = ({ ...props }) => {
    if (process.env.NODE_ENV === 'production' || props.isBeingTested) {
        return (
            <ErrBoundary FallbackComponent={SomethingWentWrongPage} onError={myErrorHandler}>
                {props.children}
            </ErrBoundary>
        );
    } else {
        return props.children;
    }
};

const myErrorHandler = (error: Error, info: string) => {
    var msg;
    if (error && error?.stack) {
        msg = error.stack;
    } else {
        msg = 'something went wrong';
    }
    logError(msg, 'ERROR');
};

export default ErrorBoundary;
