import Cookies, { CookieAttributes } from 'js-cookie';
import analytics from '../../../analytics/analytics';

const GOOGLE_ANALYTICS_CONSENT_COOKIE_KEY = 'google_analytics_user_consented';
const USER_ACCEPTED = 'yes';
const USER_REJECTED = 'no';
const ACCEPTANCE_TIMEOUT_DAYS = 28;
const REJECTION_TIMEOUT_DAYS = 28;

declare var window: {
    config: { consentCookieDomain: string };
};

function setDomainIfConfigured(cookieAttributes: CookieAttributes) {
    const domain = window?.config?.consentCookieDomain;
    if (domain) {
        cookieAttributes.domain = domain;
    }
    return cookieAttributes;
}

/**
 * The system can basically be in three states with regards to whether the user has accepted the use of Google Analytics:
 * A. User accepted the use of Google Analytics (cookie: "google_analytics_user_consented=yes")
 * B. User rejected the use of Google Analytics (cookie: "google_analytics_user_consented=no")
 * C. User neither accepted nor rejected the use of Google Analytics ("google_analytics_user_consented" cookie not present)
 * There are three methods for setting the system to each of these states
 * and four for querying whether the system is in a given state.
 */
class GoogleAnalyticsConsentManager {
    setUserAcceptedGoogleAnalytics(): void {
        Cookies.set(
            GOOGLE_ANALYTICS_CONSENT_COOKIE_KEY,
            USER_ACCEPTED,
            setDomainIfConfigured({ expires: ACCEPTANCE_TIMEOUT_DAYS }),
        );
        analytics.init();
    }

    setUserRejectedGoogleAnalytics(): void {
        Cookies.set(
            GOOGLE_ANALYTICS_CONSENT_COOKIE_KEY,
            USER_REJECTED,
            setDomainIfConfigured({ expires: REJECTION_TIMEOUT_DAYS }),
        );
    }

    clearGoogleAnalyticsCookie(): void {
        Cookies.remove(GOOGLE_ANALYTICS_CONSENT_COOKIE_KEY);
    }

    queryUserAcceptedGoogleAnalytics(): boolean {
        return Cookies.get(GOOGLE_ANALYTICS_CONSENT_COOKIE_KEY) === USER_ACCEPTED;
    }

    queryUserRejectedGoogleAnalytics(): boolean {
        return Cookies.get(GOOGLE_ANALYTICS_CONSENT_COOKIE_KEY) === USER_REJECTED;
    }

    googleAnalyticsCookieAbsent(): boolean {
        const cookie = Cookies.get(GOOGLE_ANALYTICS_CONSENT_COOKIE_KEY);
        return cookie === undefined;
    }

    /**
     * When a cookie expires, the web browser (or at least Chrome + Mac) deletes it.
     * So (at least for Chrome + Mac) if a cookie is present, by definition it has also not expired.
     * Also, it seems difficult to get the expiry date of a cookie through Javascript.
     */
    googleAnalyticsCookiePresent(): boolean {
        return !this.googleAnalyticsCookieAbsent();
    }
}

const googleAnalyticsConsentManager = new GoogleAnalyticsConsentManager();
export default googleAnalyticsConsentManager;
