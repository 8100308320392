import { Box, Image } from 'grommet';
import PropTypes from 'prop-types';
import React from 'react';
import { useId } from 'react-id-generator';
import { thinArrowLeftWhite, thinArrowRightWhite } from '../../../assets/icons/MDW-icons';
import arrowDown from '../../../assets/images/arrowDownPrimary.png';
import downArrow from '../../../assets/images/downArrow.svg';
import upArrow from '../../../assets/images/upArrow.svg';
import arrowUp from '../../../assets/images/arrowUpPrimary.png';
import { Button } from '../../MDW/MDW';
import { buttonScroll, checkOverflow, StyledContentScrollBox } from './utils/utils';

const ScrollButtons = ({ children, ariaHiddenButtons = false, height, ...props }) => {
    const ref = React.useRef();
    const [StyledContentScrollBoxId] = useId();
    const [isScrollButtonsVisible, setIsScrollButtonsVisible] = React.useState(false);
    Array.isArray(children?.props?.menuItems) && children?.props?.menuItems.shift();
    React.useEffect(() => {
        setIsScrollButtonsVisible(checkOverflow(ref.current));
    }, [ref]);

    const onResize = React.useCallback(() => {
        setIsScrollButtonsVisible(checkOverflow(ref.current));
    }, []);

    React.useEffect(() => {
        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [onResize]);

    const overflow =
        props.orientation === 'horizontal'
            ? { vertical: 'hidden', horizontal: 'auto' }
            : { vertical: 'auto', horizontal: 'hidden' };

    return (
        <Box
            width="100%"
            height="30px"
            direction="row"
            align="center"
            className="scroll-button-content"
            // margin={{ top: '50px' }}
        >
            <Box width={'100%'} overflow="hidden" {...(props.orientation === 'horizontal' && { direction: 'row' })}>
                {props.orientation === 'horizontal' && isScrollButtonsVisible && (
                    <Button
                        data-testid={`keyword-scroll-left`}
                        a11yTitle="Scroll Left"
                        plain
                        icon={
                            <Box
                                {...(props.boxPropsForButtonSizing || { width: 'small' })}
                                direction="row"
                                justify="center"
                            >
                                <Image alt={'Scroll Left'} height="12px" width="12px" src={thinArrowLeftWhite} />
                            </Box>
                        }
                        onClick={buttonScroll('left', StyledContentScrollBoxId)}
                        repeatClicksOnHold
                        heldOnHover
                    />
                )}
                <StyledContentScrollBox
                    id={StyledContentScrollBoxId}
                    data-testid="scroll-button-content"
                    {...(props.orientation === 'horizontal'
                        ? {
                              flex: true,
                              align: 'center',
                          }
                        : { pad: { top: 'small', right: '17px' } })}
                    ref={ref}
                    width={'100%'}
                    direction="row"
                    height={height}
                    gap={'xsmall'}
                    overflow={overflow}
                    wrap
                    // background={'white'}
                >
                    {children}
                </StyledContentScrollBox>
                {props.orientation === 'horizontal' && isScrollButtonsVisible && (
                    <Button
                        data-testid={`keyword-scroll-right`}
                        plain
                        a11yTitle="Scroll Right"
                        icon={
                            <Box
                                {...(props.boxPropsForButtonSizing || { width: 'small' })}
                                direction="row"
                                justify="center"
                            >
                                <Image alt={'Scroll Right'} height="12px" width="12px" src={thinArrowRightWhite} />
                            </Box>
                        }
                        onClick={buttonScroll('right', StyledContentScrollBoxId)}
                        repeatClicksOnHold
                        heldOnHover
                    />
                )}
            </Box>
            {props.orientation !== 'horizontal' && isScrollButtonsVisible && (
                <Box gap="5px" pad={{ top: props.headerTags ? '' : '12px' }} margin={props.buttonsMargin}>
                    <Button
                        data-testid={`keyword-scroll-up`}
                        plain
                        a11yTitle="Scroll Up"
                        icon={<Image alt="Scroll up" src={upArrow} />}
                        onClick={buttonScroll('up', StyledContentScrollBoxId)}
                        {...(ariaHiddenButtons && { tabIndex: -1 })}
                        repeatClicksOnHold
                        style={{ height: '20px' }}
                    />
                    <Button
                        data-testid={`keyword-scroll-down`}
                        plain
                        a11yTitle="Scroll Down"
                        icon={<Image alt="Scroll down" src={downArrow} />}
                        onClick={buttonScroll('down', StyledContentScrollBoxId)}
                        {...(ariaHiddenButtons && { tabIndex: -1 })}
                        repeatClicksOnHold
                        style={{ height: '20px' }}
                    />
                </Box>
            )}
        </Box>
    );
};

export default ScrollButtons;

ScrollButtons.propTypes = {
    children: PropTypes.node.isRequired,
    height: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    buttonsMargin: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
