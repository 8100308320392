import { Table, TableHeader, TableRow, TableCell, TableBody, Text, Box } from 'grommet';
import { Observation } from '../../IndividualDatasetPageContents/IndividualDatasetPageContents';
import Linkify from '../../../../atoms/Linkify/Linkify';
import styled from 'styled-components';
import theme from '../../../../../style/theme';

const StyledHeaderTableRow = styled(TableRow)`
    border-top: solid 1px ${theme?.global?.colors[window.localStorage.getItem('branding') || 'default']?.dividerLine};
    border-bottom: solid 3px ${theme?.global?.colors[window.localStorage.getItem('branding') || 'default']?.dividerLine};
`;

const StyledBodyTableRow = styled(TableRow)`
    border-bottom: solid 1px ${theme?.global?.colors[window.localStorage.getItem('branding') || 'default']?.dividerLine};
`;
/**
 *
 - In the Exchange, Metadata Entries for an Observation are named like this, and they occur in this order:
 - "Observed Node"
 - "Measured Value"
 - "Disambiguating Description"

 - "Observation Date"
 - "Measured Property"

 - This is also the naming and order that the Browser API sends things in.

 - However, on the Health Data Gateway, there is a different order and a different naming...

 - "Observed Node" -> "Statistical Population"
 - "Disambiguating Description" -> "Population Description"
 - "Measured Value" -> "Population Size"

 - "Measured Property" (Unchanged Name)
 - "Observation Date" (Unchanged Name)

 - We will try to match the Health Data Gateway in this component.

 * @param props
 * @constructor
 */
const DescriptiveObservation = (props: { observations: Observation[]; large?: boolean; lastSection: boolean }) => {
    const textSize = props.large ? 'small' : 'xsmall';
    const headerCellTextProps = {
        size: textSize,
        weight: 'bold' as 'bold',
    };
    const bodyCellTextProps = {
        size: textSize,
    };

    return (
        <Box pad={{ bottom: 'none' }}>
            <Table data-testid="observations-table">
                <TableHeader>
                    <StyledHeaderTableRow>
                        <TableCell scope="col">
                            <Text {...headerCellTextProps}>Statistical Population</Text>
                        </TableCell>
                        <TableCell scope="col">
                            <Text {...headerCellTextProps}>Population Description</Text>
                        </TableCell>
                        <TableCell scope="col">
                            <Text {...headerCellTextProps}>Population Size</Text>
                        </TableCell>
                        <TableCell scope="col">
                            <Text {...headerCellTextProps}>Measured Property</Text>
                        </TableCell>
                        <TableCell scope="col">
                            <Text {...headerCellTextProps}>Observation Date</Text>
                        </TableCell>
                    </StyledHeaderTableRow>
                </TableHeader>
                <TableBody>
                    {props.observations.map((observation: Observation, index: number) => {
                        return (
                            <StyledBodyTableRow key={index}>
                                <TableCell scope="row">
                                    <Text {...bodyCellTextProps}>{observation.observedNode}</Text>
                                </TableCell>
                                <TableCell>
                                    <Text {...bodyCellTextProps}>
                                        <Linkify>{observation.disambiguatingDescription}</Linkify>
                                    </Text>
                                </TableCell>
                                <TableCell>
                                    <Text {...bodyCellTextProps}>{observation.measuredValue}</Text>
                                </TableCell>
                                <TableCell>
                                    <Text {...bodyCellTextProps}>{observation.measuredProperty}</Text>
                                </TableCell>
                                <TableCell>
                                    <Text {...bodyCellTextProps}>{observation.observationDate}</Text>
                                </TableCell>
                            </StyledBodyTableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </Box>
    );
};

export default DescriptiveObservation;
