import React from 'react';
import { useId } from 'react-id-generator';
import styled from 'styled-components';
import theme from '../../../style/theme';
import PropTypes from 'prop-types';
import { Select as GrommetSelect } from 'grommet';

const StyledSelect = styled(GrommetSelect)`
    font-weight: ${theme.fontWeights.body};
    font-size: ${theme.fontSizes[2]};
    line-height: 16px;
`;

const Select = React.memo(({ name, options, defaultValue, setFieldValue, onChange, ...props }) => {
    const [value, setValue] = React.useState(setDefaultValue([defaultValue], options));
    const [currentOptionsList, setCurrentOptionsList] = React.useState(options);
    const [selectId] = useId();

    React.useEffect(() => {
        setCurrentOptionsList(options);
    }, [options]);

    const processOptionClick = React.useCallback(
        (option) => {
            if (value && !value.includes(option)) {
                return [...value, option];
            } else {
                return value && value.filter((item) => item !== option);
            }
        },
        [value],
    );

    const onChangeMethod = React.useCallback(
        ({ option }) => {
            setValue(processOptionClick(option));
            setFieldValue && setFieldValue(name, option);
            onChange && onChange(option);
        },
        [setFieldValue, processOptionClick, onChange, name],
    );

    const processSearchEntry = React.useCallback(
        (string) => {
            return options.filter((option) => option.toLowerCase().includes(string.toLowerCase()));
        },
        [options],
    );

    const onSearch = React.useCallback(
        (string) => {
            setCurrentOptionsList(processSearchEntry(string));
        },
        [processSearchEntry],
    );

    return (
        <StyledSelect
            {...props}
            options={currentOptionsList}
            id={props.id || selectId}
            value={value}
            searchPlaceholder="search"
            onChange={onChangeMethod}
            closeOnChange={props.closeOnChange || false}
            size={props.size || 'medium'}
            onSearch={onSearch}
        />
    );
});

export default Select;

Select.whyDidYouRender = true;

Select.propTypes = {
    name: PropTypes.string.isRequired,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    options: PropTypes.array.isRequired,
    id: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    multiple: PropTypes.bool,
    closeOnChange: PropTypes.bool,
    size: PropTypes.string,
    onSearch: PropTypes.func,
    onOpen: PropTypes.func,
};

export const wrongDefaultValueErrorMessage = 'defaultValue not found within options array';
export const setDefaultValue = (defaultValue, options) => {
    if (!defaultValue[0]) {
        return;
    } else if (!options.includes(defaultValue[0])) {
        throw Error(wrongDefaultValueErrorMessage);
    } else {
        return defaultValue;
    }
};
