import { Box, Text } from 'grommet';
import React from 'react';
import theme from '../../../../../../style/theme';
import { StyledListRowLine } from '../../../../../organisms/List/useListRow';
import {
    StyledElementModalSubHeading,
    StyledSubTitleText,
} from '../../DescriptiveMetadata/DescriptiveMetadataListUtils';
import {
    ElementPopUpContentBlock,
    ElementPopUpEnumList,
    ElementPopUpTitleValueBlock,
    StyledGridBox,
    StyledGridText,
} from '../DataElementPopup/DataElementPopupUtils';
import * as DOMPurify from 'dompurify';
import { ToolTip } from '../../../../../MDW/MDW';
import { Link, useLocation } from 'react-router-dom';

const DataElementpopUpDescriptionNewTab = ({ ...props }) => {
    const { description, dataType } = props;
    DOMPurify.addHook('afterSanitizeAttributes', function (node) {
        // set all elements owning target to target=_blank
        if ('target' in node) {
            node.setAttribute('target', '_blank');
            node.setAttribute('rel', 'noopener');
        }
    });
    const location = useLocation();
    let originId = new URLSearchParams(location.search).get('origin');
    const originParam = originId ? `&origin=${originId}` : '';
    const id = new URLSearchParams(location.search).get('id');
    let desc = DOMPurify.sanitize(description, { USE_PROFILES: { html: true } });
    return description || dataType ? (
        <>
            {description && (
                <Box
                    pad={{
                        bottom: 'none',
                    }}
                >
                    <Box background={'white'} pad={{ top: 'small', horizontal: 'small' }} borderTop="none">
                        <div>
                            <Box
                                pad={{
                                    top: 'none',
                                    horizontal: 'small',
                                }}
                                margin={{
                                    top: 'small',
                                }}
                            >
                                <Text size="16px" weight={theme.fontWeights.semibold}>
                                    Description
                                </Text>
                            </Box>
                            <Box pad={'small'}>
                                <StyledElementModalSubHeading size="small" weight="normal">
                                    <div dangerouslySetInnerHTML={{ __html: desc }}></div>
                                </StyledElementModalSubHeading>
                            </Box>
                        </div>
                    </Box>
                </Box>
            )}

            <Box
                pad={{
                    bottom: 'none',
                }}
            >
                <Box background={'white'} pad={{ top: 'small', horizontal: 'small' }} borderTop="none">
                    <div>
                        <Box
                            pad={{
                                top: 'none',
                                horizontal: 'small',
                            }}
                            margin={{
                                top: 'small',
                            }}
                        >
                            {dataType && (
                                <Text size="16px" weight={theme.fontWeights.semibold}>
                                    Data Type
                                </Text>
                            )}
                        </Box>
                        <Box pad={'small'}>
                            <Box>
                                {dataType?.name && (
                                    <StyledElementModalSubHeading size="small" weight="normal">
                                        <StyledGridText size="small" weight="bold">
                                            Name:
                                        </StyledGridText>{' '}
                                        &nbsp;
                                        {dataType?.name}
                                    </StyledElementModalSubHeading>
                                )}
                                {dataType?.description && (
                                    <StyledElementModalSubHeading size="small" weight="normal">
                                        <StyledGridText size="small" weight="bold">
                                            Description:
                                        </StyledGridText>{' '}
                                        &nbsp;
                                        {dataType?.description}
                                    </StyledElementModalSubHeading>
                                )}
                                {dataType?.reference?.name && (
                                    <StyledElementModalSubHeading size="small" weight="normal">
                                        <StyledGridText size="small" weight="bold">
                                            Reference Name:
                                        </StyledGridText>{' '}
                                        &nbsp;
                                        <Link
                                            to={`/browser/dataset?id=${id}${originParam}&dataclass=${dataType?.reference?.id}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {dataType?.reference?.name}
                                        </Link>
                                    </StyledElementModalSubHeading>
                                )}
                            </Box>
                            {dataType?.rule && (
                                <Box style={{ marginTop: '20px' }}>
                                    <Box
                                        margin={{
                                            top: 'small',
                                            bottom: 'small',
                                        }}
                                    >
                                        <Text size="16px" weight={theme.fontWeights.semibold}>
                                            Rule
                                        </Text>
                                    </Box>
                                    <StyledElementModalSubHeading size="small" weight="normal">
                                        {dataType?.rule}
                                    </StyledElementModalSubHeading>
                                </Box>
                            )}
                            {dataType?.measurementUnit && (
                                <Box style={{ marginTop: '20px' }}>
                                    <Box
                                        margin={{
                                            top: 'small',
                                            bottom: 'small',
                                        }}
                                    >
                                        <Text size="16px" weight={theme.fontWeights.semibold}>
                                            Measurement Unit
                                        </Text>
                                    </Box>

                                    <StyledElementModalSubHeading size="small" weight="normal">
                                        <StyledGridText size="small" weight="bold">
                                            Measurement Unit Name:
                                        </StyledGridText>{' '}
                                        &nbsp;
                                        {dataType?.measurementUnit?.name}
                                    </StyledElementModalSubHeading>
                                    <StyledElementModalSubHeading size="small" weight="normal">
                                        <StyledGridText size="small" weight="bold">
                                            Measurement Unit Description:
                                        </StyledGridText>{' '}
                                        &nbsp;
                                        {dataType?.measurementUnit?.description}
                                    </StyledElementModalSubHeading>
                                </Box>
                            )}

                            <ElementPopUpContentBlock title={''} isAtBottom={dataType?.enumerations}>
                                {dataType?.enumerations && (
                                    <Box>
                                        <Box
                                            margin={{
                                                top: 'small',
                                                bottom: 'small',
                                            }}
                                        >
                                            <Text size="16px" weight={theme.fontWeights.semibold}>
                                                Enumerations
                                            </Text>
                                        </Box>
                                        <Box direction="row" pad={{ bottom: 'xsmall' }}>
                                            <StyledGridBox width="15%" pad={{ left: '3%' }}>
                                                <Text size="small">Name</Text>
                                            </StyledGridBox>
                                            <StyledGridBox width="67%" pad={{ left: '10%' }}>
                                                <Text size="small">Description</Text>
                                            </StyledGridBox>
                                        </Box>
                                    </Box>
                                )}
                                {dataType?.enumerations &&
                                    dataType?.enumerations?.map(({ name, description }, index) => (
                                        <Box
                                            key={name}
                                            pad={{ top: 'small', left: 'medium' }}
                                            data-testid={`metadata-row-id-${index}`}
                                        >
                                            <Box direction="row" pad={{ bottom: 'xsmall' }}>
                                                <StyledGridBox width="20%" data-tip={name}>
                                                    <StyledGridText
                                                        size="small"
                                                        weight={theme.fontWeights.semibold}
                                                        color={`${theme.global.colors.textLightGrey}`}
                                                    >
                                                        {`${name}: `}
                                                    </StyledGridText>
                                                </StyledGridBox>
                                                <StyledGridBox width="77%" pad={{ left: '3%' }}>
                                                    <Text
                                                        wordBreak={'break-word'}
                                                        size="small"
                                                        weight={theme.fontWeights.semibold}
                                                    >
                                                        {description}
                                                    </Text>
                                                </StyledGridBox>
                                            </Box>
                                        </Box>
                                    ))}
                                <ToolTip />
                            </ElementPopUpContentBlock>
                        </Box>
                    </div>
                </Box>
            </Box>
        </>
    ) : (
        <></>
    );
};

export default DataElementpopUpDescriptionNewTab;
