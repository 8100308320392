import { Box, Button, Image, ResponsiveContext, Text } from 'grommet';
import PropTypes from 'prop-types';
import React from 'react';
import { useLocation, useHistory } from 'react-router';
import styled from 'styled-components';
import {
    thinArrowDownWhite,
    thinArrowUpWhite,
    thinArrowUpBlack,
    thinArrowDownBlack,
} from '../../../../assets/icons/MDW-icons';
import { updateBrowserControls, updateCurrentClass } from '../../../../redux/actions/actions';
import store from '../../../../redux/store';
import theme from '../../../../style/theme';
import history from '../../../../utils/history';
import { Dropdown, SearchBar } from '../../../MDW/MDW';
import { BrokerSearchPageContainerFromPrefix } from '../BrokerSearchPageContainer';
import { IndividualDatasetPageContainerFromPrefix } from '../../IndividualDatasetPage/IndividualDatasetPageContainer';
import { generateUrlQuery, setDataSets } from './BrokerSearchPageContentsUtils';
import { fetchEndPoint } from '../../../../api/fetchEndpoint';
import { useDispatch } from 'react-redux';
import { setSortbyIntegratedItem } from '../../../../redux/actions/actions';
const StyledResultText = styled(Text)`
    white-space: pre;
`;

const StyledBackButton = styled(Button)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    &:focus-within div {
        border-radius: ${theme.radii.large};
        box-shadow: 0 0 0 ${theme.a11yBoxShadow.thickness}
            ${theme.global.colors[window.localStorage.getItem('branding') || 'default'].focusBorderColor};
    }
`;

export const goBackFromIndividualDatasetPage = (location) => {
    // console.log(location);
    if (location.state && location.state.from && location.state.from.includes(BrokerSearchPageContainerFromPrefix)) {
        const from = location.state.from; // e.g. browser-search-page||?searchterm=test
        history.push(`/browser/search${from.includes('||') ? from.split('||')[1] : ''}`, {
            from: IndividualDatasetPageContainerFromPrefix,
        });
        const endPoint = generateUrlQuery().query;
        fetchEndPoint(endPoint, setDataSets);
    } else {
        history.push('/browser/search');
    }

    store.dispatch(
        updateBrowserControls({
            ...store.getState().browserControls,
            pageNumber: 1,
        }),
    );
    store.dispatch(updateCurrentClass({}));
};

const ResultValue = ({ children, ...props }) => {
    return (
        <Text size="small" weight="bold">
            {children}
        </Text>
    );
};

const BrokerSearchComponent = React.memo(
    ({
        listStateArray,
        onSubmit,
        onClickPaginationSizeChange,
        paginationSize,
        paginationTotal,
        pageNumber,
        resultSize,
        searchResults,
        ...props
    }) => {
        const [fromNumber, setFromNumber] = React.useState();
        const [toNumber, setToNumber] = React.useState();
        const [activeIndex, setActiveIndex] = React.useState(0);
        const [emptyInput, setEmptyInput] = React.useState(false);

        const location = useLocation();
        const pageSizeArray = [25, 50, 100].filter((item) => item <= resultSize * 2);
        const size = React.useContext(ResponsiveContext);
        const dispatch = useDispatch();
        let searchTermFromURL = new URLSearchParams(location.search).get('searchterm') ?? '';
        React.useEffect(() => {
            setFromNumber(pageNumber * paginationSize - paginationSize + 1);
        }, [pageNumber, paginationSize]);

        React.useEffect(() => {
            setToNumber(pageNumber * paginationSize - (paginationSize - paginationTotal));
        }, [pageNumber, paginationSize, paginationTotal]);
        let brandingCofig = window.config?.branding === 'adr' || window.config?.branding === 'dsc2';

        React.useEffect(() => {
            if (fromNumber && (isNaN(fromNumber) || isNaN(toNumber))) {
                throw new Error('Either fromNumber or toNumber is not a number!');
            }
            !location.search.includes('?searchterm') && setActiveIndex(0);
        }, [fromNumber, toNumber]);
        const showSearchResult = (key, index) => {
            if (location.search.includes('?searchterm')) {
                let serchTerm = location.search.split('=')[1];
                setActiveIndex(index);
                if (key === 'All') {
                    dispatch(setSortbyIntegratedItem({}));
                } else {
                    if (key === 'Terminology') {
                        return dispatch(setSortbyIntegratedItem({ key: 'CONCEPTUAL' }));
                    } else if (key === 'Dataset') {
                        return dispatch(setSortbyIntegratedItem({ key: 'PHYSICAL' }));
                    } else {
                        return dispatch(setSortbyIntegratedItem({ key: 'LOGICAL' }));
                    }
                }
            }
        };
        /**
         * This callback is meant to be called from the Individual Dataset Page.
         * It checks the "previous location" (location.state.from),
         * and if the previous location was the Browser Search Page,
         * it takes the user back to the Browser Search Page with the previous search query appended
         * @type {(function(): void)|*}
         */
        const goBackFromIndividualDatasetPageCallback = React.useCallback(() => {
            goBackFromIndividualDatasetPage(location);
        }, [location]);

        return (
            <Box direction="column" flex="grow" align="center">
                <Box fill="horizontal" direction="column" align="center" gap="15px">
                    <Box fill="horizontal" direction="row" align="center" gap="15px">
                        <Box {...(props.descriptive ? { width: 'medium' } : { flex: 'grow' })}>
                            <SearchBar
                                listStateArray={listStateArray}
                                onSubmit={onSubmit}
                                placeholder="Search All Datasets"
                                inputValue={searchTermFromURL}
                                emptyInput={emptyInput}
                                setEmptyInput={setEmptyInput}
                            />
                        </Box>
                        {!props.descriptive && pageSizeArray[1] && size !== 'small' && (
                            <Dropdown
                                boxProps={{
                                    margin: { right: '4px' },
                                    direction: 'row',
                                    justify: 'center',
                                }}
                                plain
                                callback={(e) => onClickPaginationSizeChange(e.target.textContent)}
                                defaultItem={`${paginationSize}`}
                                items={pageSizeArray}
                                title="Items per page"
                                label={
                                    <Box
                                        fill="vertical"
                                        direction="row"
                                        justify="around"
                                        align="center"
                                        round={'12px'}
                                        border={{
                                            color: theme.global.colors.lightPastelBlue,
                                            size: '1px',
                                            style: 'solid',
                                            side: 'all',
                                        }}
                                        pad={{ vertical: '11px', horizontal: '20px' }}
                                        gap="30px"
                                    >
                                        <Text weight={theme.fontWeights.body} size={theme.fontSizes[3]}>
                                            Size
                                        </Text>
                                        <Box direction="column" fill={'vertical'} justify="around" gap="4px">
                                            <Image
                                                alt="Up Arrow"
                                                height={'8px'}
                                                width={'12px'}
                                                src={brandingCofig ? thinArrowUpBlack : thinArrowUpWhite}
                                            />
                                            <Image
                                                alt="Down Arrow"
                                                height={'8px'}
                                                width={'12px'}
                                                src={brandingCofig ? thinArrowDownBlack : thinArrowDownWhite}
                                            />
                                        </Box>
                                    </Box>
                                }
                            />
                        )}
                        {props.descriptive && (
                            <Box direction="row" align="center">
                                <StyledBackButton
                                    plain
                                    onClick={goBackFromIndividualDatasetPageCallback}
                                    data-testid="metadata-to-browser-button"
                                >
                                    <Box
                                        align="center"
                                        justify="center"
                                        round={'12px'}
                                        border={{
                                            color: theme.global.colors.lightPastelBlue,
                                            size: '1px',
                                            style: 'solid',
                                            side: 'all',
                                        }}
                                        pad={{ vertical: '11px', horizontal: '20px' }}
                                    >
                                        <Text size="small">Browser</Text>
                                    </Box>
                                </StyledBackButton>
                            </Box>
                        )}
                        <Box pad={{ right: '20px' }}>
                            {!props.descriptive &&
                                size !== 'small' &&
                                (toNumber === 0 || (isNaN(fromNumber) && isNaN(toNumber)) ? (
                                    <StyledResultText size="small" align="center">
                                        {`No results!`}
                                    </StyledResultText>
                                ) : (
                                    <StyledResultText size="small" align="center">
                                        {`Showing results:  `}
                                        <ResultValue>{fromNumber}</ResultValue>
                                        {` to `}
                                        <ResultValue>{toNumber}</ResultValue>
                                        {'  of  '}
                                        <ResultValue>{resultSize}</ResultValue>
                                    </StyledResultText>
                                ))}
                        </Box>
                    </Box>
                    {emptyInput && (
                        <div style={{ width: '100%', color: 'red', marginLeft: '30px' }}>
                            *Please enter a search term
                        </div>
                    )}
                </Box>
                <div style={{ width: '100%', marginTop: '10px' }}>
                    <div>
                        <Box direction="row" justify="start" data-testid="searchresults-panel-id">
                            {searchResults &&
                                Object.entries(searchResults).map(([key, value], index) => (
                                    <Box
                                        key={index}
                                        style={{
                                            cursor: location.search.includes('?searchterm') ? 'pointer' : '',
                                        }}
                                        data-testid={`searchresults-id-${key}`}
                                        margin={{ right: 'medium' }}
                                    >
                                        <Text
                                            {...(index === activeIndex
                                                ? {
                                                      color: theme?.global?.colors[
                                                          window.localStorage.getItem('branding')
                                                      ]?.lightGrey,
                                                      weight: 'bold',
                                                  }
                                                : {
                                                      color: theme?.global?.colors[
                                                          window.localStorage.getItem('branding')
                                                      ]?.lightGrey,
                                                  })}
                                            size="xsmall"
                                            onClick={() => showSearchResult(key, index)}
                                        >
                                            {key} : {value}
                                        </Text>
                                    </Box>
                                ))}
                        </Box>
                    </div>
                </div>
            </Box>
        );
    },
);

BrokerSearchComponent.propTypes = {
    listStateArray: PropTypes.array.isRequired,
    resultSize: PropTypes.number,
    paginationTotal: PropTypes.number,
    pageNumber: PropTypes.number,
    paginationSize: PropTypes.number,
    descriptive: PropTypes.bool,
    onSubmit: PropTypes.func,
};

BrokerSearchComponent.whyDidYouRender = true;

export default BrokerSearchComponent;
