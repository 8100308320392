import React from 'react';
import store from '../../redux/store';
import { isItemPopulated } from '../../utils/javascriptUtilities';
import { isEqual } from 'lodash';

const useIsReduxStateReady = (propertyNameString) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [data, setData] = React.useState(store.getState()[propertyNameString]);

    const handleChange = React.useCallback(() => {
        const stateItem = store.getState()[propertyNameString];
        if (!isEqual(stateItem, data)) {
            if (isItemPopulated(stateItem)) {
                setIsLoading(false);
            }
            setData(stateItem);
        }
    }, [propertyNameString, data]);

    React.useEffect(() => {
        if (!store.getState()[propertyNameString]) {
            throw Error('redux state item does not exist');
        }
        const unsubscribe = store.subscribe(handleChange);
        return () => {
            unsubscribe();
        };
    }, [handleChange, propertyNameString]);

    return { isLoading, data };
};

export default useIsReduxStateReady;
