import React from 'react';
import { handleSession } from './authentication';

const AuthContext = React.createContext();

export const AuthProvider = (props) => {
    const [isAuthenticated, setIsAuthenticated] = React.useState();
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        const initAuth = async () => {
            const result = await handleSession();
            setIsAuthenticated(result);
            setLoading(false);
        };
        initAuth();
    }, []);

    return <AuthContext.Provider value={{ isAuthenticated, loading }}>{props.children}</AuthContext.Provider>;
};

export const useAuth = () => {
    const context = React.useContext(AuthContext);

    if (context === undefined) {
        throw new Error('useAuth must be used within a AuthProvider');
    }

    return context;
};
