import { Box, Image, Text } from 'grommet';
import { Folder } from '../../../assets/icons/MDW-icons';

const S3FolderRow = ({ fileTreeNodeName }: { fileTreeNodeName: string }) => {
    return (
        <Box direction="row" gap="small">
            <Image alt={'Folder Icon'} height="24px" width="24px" src={Folder} />
            <Text wordBreak="break-word" color="textDarkGrey" weight={500}>
                {fileTreeNodeName}
            </Text>
        </Box>
    );
};

export default S3FolderRow;
