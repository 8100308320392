import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import styled from 'styled-components';
import './ListScrollBar.css';

export const StyledScrollbars = styled(Scrollbars)`
    & div:nth-child(3) {
        margin-right: 2px;
    }
`;

const CustomScrollbars = ({ onScroll, forwardedRef, style, children }) => {
    const refSetter = React.useCallback(
        (scrollbarsRef) => {
            if (scrollbarsRef) {
                forwardedRef(scrollbarsRef.view);
            } else {
                forwardedRef(null);
            }
        },
        [forwardedRef],
    );

    return (
        <StyledScrollbars
            ref={refSetter}
            style={{ ...style, overflow: 'hidden' }}
            {...(onScroll && { onScroll: onScroll })}
            thumbSize={120}
            hideTracksWhenNotNeeded
            renderTrackHorizontal={(props) => (
                <div {...props} style={{ display: 'none' }} className="track-horizontal" />
            )}
            renderThumbVertical={(props) => (
                <div {...props} className={`thumb-vertical-${window.localStorage.getItem('branding')}`} />
            )}
            renderTrackVertical={(props) => (
                <div
                    {...props}
                    style={{
                        width: '6px',
                    }}
                    className="track-vertical"
                />
            )}
        >
            {children}
        </StyledScrollbars>
    );
};

const ListScrollBar = React.forwardRef((props, ref) => <CustomScrollbars {...props} forwardedRef={ref} />);

export default ListScrollBar;
