import './wdyr';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App/App';
import * as serviceWorker from './serviceWorker';
import theme from './style/theme';
import { Grommet as ThemeProvider } from 'grommet';
import { Provider } from 'react-redux';
import { AuthProvider } from './utils/Authorization';
import store from './redux/store';
import history from './utils/history';
import styled from 'styled-components';

const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
if (path) {
    history.replace(path);
}

const StyledThemeProvider = styled(ThemeProvider)`
    height: unset;
    min-height: 100vh;
    width: 100%;
    & > div {
        min-height: 100vh;
    }
`;

if (process.env.REACT_APP_DATASOURCE === 'TEST') {
    const { worker } = require('./mocks/browser');
    worker.start();
}
let configEndPoint;

// (async () => {
//     const response = await fetch(' http://localhost:3000/configs', { method: 'GET' }).then((res) =>
//         res.json().catch((err) => console.log(err)),
//     );
//     let configEndPoint = response[0];
//     console.log(configEndPoint);
//     if (response) {
//         ReactDOM.render(
//             <Provider store={store}>
//                 <AuthProvider>
//                     <ThemeProvider theme={theme} full>
//                         <App />
//                     </ThemeProvider>
//                 </AuthProvider>
//             </Provider>,
//             document.getElementById('root'),
//         );
//     } else {
//         ReactDOM.render(<div>ELse</div>, document.getElementById('root'));
//     }
// })();
ReactDOM.render(
    <Provider store={store}>
        <AuthProvider>
            <StyledThemeProvider theme={theme}>
                <App />
            </StyledThemeProvider>
        </AuthProvider>
    </Provider>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
