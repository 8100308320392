import { Box, Heading, Image, ResponsiveContext, Text } from 'grommet';
import React, { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import theme from '../../../style/theme';
// import { isTesting } from '../../../utils/testingUtils';
import { Link, ScrollButtons } from '../../MDW/MDW';
import { BrokerSearchPageContainerFromPrefix } from './BrokerSearchPageContainer';
import assetsSecondary from '../../../assets/images/assetsSecondary.svg';
import dataStandartSecondarySvg from '../../../assets/images/dataStandartSecondary.svg';
import terminologySecondarySvg from '../../../assets/images/terminologySecondary.svg';
import detailsSecondary from '../../../assets/icons/detailsSecondary.svg';
import { Cubes } from 'grommet-icons';
import DOMPurify from 'dompurify';

const StyledPublisherText = styled(Text)`
    text-transform: capitalize;
`;

const StyledClassDescriptionText = styled(Text)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const StyledLink = styled(Link)`
    &:focus {
        border-radius: ${theme.radii.large};
        box-shadow: 0 0 0 ${theme.a11yBoxShadow.thickness}
            ${theme.global.colors[window.localStorage.getItem('branding') || 'default'].focusBorderColor};
        outline: none;
    }
`;

const StyledHeading = styled(Heading)`
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: 600;
    text-decoration: underline;
`;
// const StyledSubHeading = styled(Heading)`
//     font-weight: 400;
//     text-decoration: none;
//     color: red;
//     margin: 0px;
// `;
const ParserTag = styled.div`
    p {
        margin: 0px;
    }
`;
export interface SearchAPIResponse {
    searchResultType: string;
    dataModelType: string;
    id: number;
    title: string;
    publisher: string;
    keywords: string[];
    abstract: string;
    origin: DatasetOrigin;
    detail?: any;
    summaryTittle?: string;
    originId: string;
}

export interface DatasetOrigin {
    id: string;
    url: string;
    name: string;
    dataset?: any;
    datasetIndex?: any;
}

const BrokerSearchPageListItem = ({ children, dataset }: { children: ReactNode; dataset?: SearchAPIResponse }) => {
    const size = React.useContext(ResponsiveContext);
    let rds = dataset?.origin?.name == 'RDS Scotland';
    const location = useLocation();
    let typeOfDataModel =
        dataset?.dataModelType && dataset?.dataModelType === 'PHYSICAL'
            ? 'Dataset'
            : dataset?.dataModelType === 'Terminology'
            ? 'Terminology'
            : dataset?.dataModelType === 'LOGICAL'
            ? 'Data Standard'
            : dataset?.dataModelType;
    var originId = dataset?.origin?.id;
    const originParam = originId !== undefined ? `&origin=${originId}` : '';
    // &dataclass=8434&dataelement=8436
    const dataClassParam =
        dataset?.detail?.dataClassId !== undefined ? `&dataclass=${dataset?.detail.dataClassId}` : '';
    const dataElementParam =
        dataset?.detail?.dataElementId !== undefined ? `&dataelement=${dataset?.detail.dataElementId}` : '';
    const linkObject = dataset?.detail
        ? {
              pathname: `/browser/dataset`,
              // The reason for using 'origin 0' here is because advanced search only search in hub
              search: `?id=${dataset?.id ?? ''}&origin=${dataset?.originId || 0}${dataClassParam}${dataElementParam}`,
              state: {
                  from: `${BrokerSearchPageContainerFromPrefix}${location.search && `||${location.search}`}`,
              },
          }
        : {
              pathname: `/browser/dataset`,
              search: `?id=${dataset?.id ?? ''}${originParam}`,
              state: {
                  from: `${BrokerSearchPageContainerFromPrefix}${location.search && `||${location.search}`}`,
              },
          };
    const blankDataset = {
        publisher: '[No Publisher]',
        abstract: '[No Abstract]',
        title: '[No Title]',
        summaryTittle: '',
    };
    // @ts-ignore

    const { publisher, abstract, title, summaryTittle } = dataset || blankDataset;
    DOMPurify.addHook('afterSanitizeAttributes', function (node) {
        // set all elements owning target to target=_blank
        if ('target' in node) {
            node.setAttribute('target', '_blank');
            node.setAttribute('rel', 'noopener');
        }
    });
    let purifiedAbstract = DOMPurify.sanitize(abstract, { USE_PROFILES: { html: true } });
    let dataType;
    if (dataset?.dataModelType === 'PHYSICAL') {
        // dataType = "Dataset"

        dataType = (
            <Box direction="row" gap="xsmall">
                <Image src={assetsSecondary} alt={`Link to dataset`}></Image>
                <Text
                    color={theme.global.colors[window.localStorage.getItem('branding') || 'default'].secondary}
                    weight="bold"
                    size="xsmall"
                >
                    Dataset
                </Text>
            </Box>
        );
    } else if (dataset?.dataModelType === 'LOGICAL') {
        // dataType = "Data standard"
        dataType = (
            <Box direction="row" gap="xsmall">
                <Image src={dataStandartSecondarySvg} alt={`Link to data standard`}></Image>
                <Text
                    color={theme.global.colors[window.localStorage.getItem('branding') || 'default'].secondary}
                    weight="bold"
                    size="xsmall"
                >
                    Data standard
                </Text>
            </Box>
        );
    } else if (location?.search.includes('include')) {
        dataType = (
            <Box direction="row" gap="xsmall">
                {typeOfDataModel === 'DataElement' ? (
                    <Image
                        width={'11px'}
                        height={'13px'}
                        alignSelf="center"
                        src={detailsSecondary}
                        alt={`Link to data type ${typeOfDataModel}`}
                    ></Image>
                ) : (
                    <Box alignSelf="center">
                        <Cubes size="13px" color={theme?.global?.colors?.default?.secondary} />
                    </Box>
                )}

                <Text
                    color={theme.global.colors[window.localStorage.getItem('branding') || 'default'].secondary}
                    weight="bold"
                    size="xsmall"
                >
                    {typeOfDataModel}
                </Text>
            </Box>
        );
    } else {
        // dataType = "Terminology"
        dataType = (
            <Box direction="row" gap="xsmall">
                <Image src={terminologySecondarySvg} alt={`Link to Terminology`}></Image>
                <Text
                    color={theme.global.colors[window.localStorage.getItem('branding') || 'default'].secondary}
                    weight="bold"
                    size="xsmall"
                >
                    Terminology
                </Text>
            </Box>
        );
    }

    return (
        <React.Fragment>
            <Box
                width="100%"
                id={`browser-data-item-id-${dataset?.id}`}
                {...{
                    height: size !== 'small' ? '103px' : '52px',
                    role: 'link',
                }}
                data-testid="result-item"
            >
                <StyledLink to={{ ...linkObject }} textDecoration="none" truncate="true">
                    <Box direction="row" justify="between">
                        <Box>
                            <StyledHeading
                                size="16px"
                                truncate
                                level={1}
                                data-testid="data-summary-title"
                                data-tip={title}
                            >
                                {title}
                            </StyledHeading>

                            {/* <ToolTip /> */}
                        </Box>
                        <Box direction="row">
                            {(publisher || dataType || rds) && (
                                <Box pad={{ right: 'small' }} direction="row" align="center" gap="small">
                                    <StyledPublisherText size="xsmall" truncate color="secondaryText" weight={'bold'}>
                                        {(rds || publisher) && dataType
                                            ? `${rds ? 'RDS Scotland' : publisher}:`
                                            : rds
                                            ? 'RDS Scotland'
                                            : publisher}
                                    </StyledPublisherText>
                                    {dataType && (
                                        <Box justify="center" data-testid="dataType-list">
                                            {dataType}
                                        </Box>
                                    )}
                                </Box>
                            )}
                        </Box>
                    </Box>
                </StyledLink>
                <Box height={'20px'} margin={{ bottom: 'small' }} pad={{ right: 'medium' }}>
                    <ScrollButtons ariaHiddenButtons height={{ max: '21px' }} headerTags>
                        <StyledClassDescriptionText size="xsmall">
                            {(
                                <ParserTag
                                    dangerouslySetInnerHTML={{ __html: purifiedAbstract }}
                                    style={{ margin: '0px' }}
                                ></ParserTag>
                            ) || '[No Abstract]'}
                        </StyledClassDescriptionText>
                    </ScrollButtons>
                </Box>
                {children}
            </Box>
        </React.Fragment>
    );
};

export default BrokerSearchPageListItem;
