import {
    RECEIVE_AUTHENTICATION,
    DELETE_AUTHENTICATION,
    RECEIVE_PROFILE,
    RECEIVE_DATASETS,
    RECEIVE_FILTER_MENU_ITEMS,
    ADD_SELECTED_FILTER,
    REMOVE_SELECTED_FILTER,
    ADD_TO_ALL_FILTERS,
    REMOVE_FROM_ALL_FILTERS,
    CLEAR_ALL_SELECTED_FILTERS,
    RECEIVE_DATASET,
    UPDATE_BROWSER_CONTROLS,
    UPDATE_CURRENT_CLASS,
    SET_FILTERED_DATASETS,
    SET_S3_DATA,
    SET_BROWSER_ASSETS_DATA,
    SHOW_TREEVIEW_DATA_IN_DATASET,
    SENT_DATASET_KEYWORDS,
    SORT_BY_SEARCH_ITEM,
    TRIGGER_FOR_SEARCH_ALL_DATASET_INPUT,
} from '../constants/constants';

export const dataSets = (state = {}, action) => {
    switch (action.type) {
        case RECEIVE_DATASETS:
            return { ...(action.dataSets || {}) };
        default:
            return state;
    }
};

export const filteredDataSets = (state = {}, action) => {
    switch (action.type) {
        case SET_FILTERED_DATASETS:
            return { ...(action.filteredDataSets || {}) };
        default:
            return state;
    }
};

export const filterMenuItems = (state = [], action) => {
    switch (action.type) {
        case RECEIVE_FILTER_MENU_ITEMS:
            return [...(action.filterMenuItems || [])];
        default:
            return state;
    }
};

export const selectedFilters = (state = [], action) => {
    switch (action.type) {
        case ADD_SELECTED_FILTER:
            return [...(state || []), action.selectedFilter];
        case REMOVE_SELECTED_FILTER:
            return [
                ...(state || []).filter(
                    (item) =>
                        !(
                            item.label.toLowerCase() === action.selectedFilter.label.toLowerCase() &&
                            item.type === action.selectedFilter.type
                        ),
                ),
            ];
        case CLEAR_ALL_SELECTED_FILTERS:
            return [];
        default:
            return state;
    }
};

export const allFilters = (state = [], action) => {
    switch (action.type) {
        case ADD_TO_ALL_FILTERS:
            if (Array.isArray(action.filter)) {
                return [...(state || []), ...action.filter];
            }
            return [...(state || []), action.filter];
        case REMOVE_FROM_ALL_FILTERS:
            return (state || []).filter(
                (item) =>
                    !(
                        item.label.toLowerCase() === action.filter.label.toLowerCase() &&
                        item.type === action.filter.type
                    ),
            );
        default:
            return state;
    }
};

export const profile = (state = {}, action) => {
    switch (action.type) {
        case RECEIVE_PROFILE:
            return { ...(action.profile || {}) };
        default:
            return state;
    }
};

export const browserControls = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_BROWSER_CONTROLS:
            return { ...(action.browserControls || {}) };
        default:
            return state;
    }
};

export const currentClass = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_CURRENT_CLASS:
            return { ...(action.currentClass || {}) };
        default:
            return state;
    }
};

export const dataSet = (state = {}, action) => {
    switch (action.type) {
        case RECEIVE_DATASET:
            return { ...(action.dataSet || {}) };
        default:
            return state;
    }
};

export const isAuthenticated = (state = false, action) => {
    switch (action.type) {
        case RECEIVE_AUTHENTICATION:
            return action.isAuthenticated;
        case DELETE_AUTHENTICATION:
            return action.isAuthenticated;
        default:
            return state;
    }
};

export const s3Data = (state = {}, action) => {
    switch (action.type) {
        case SET_S3_DATA:
            return { ...(action.S3Data || {}) };
        default:
            return state;
    }
};
export const browserAssetsData = (state = {}, action) => {
    switch (action.type) {
        case SET_BROWSER_ASSETS_DATA:
            return { ...(action.browserAssetsData || {}) };
        default:
            return state;
    }
};
export const treeviewDataInDataSet = (state = {}, action) => {
    switch (action.type) {
        case SHOW_TREEVIEW_DATA_IN_DATASET:
            return { ...(action.treeviewDataInDataSet || {}) };
        default:
            return state;
    }
};
export const sortbyIntegratedItem = (state = {}, action) => {
    switch (action.type) {
        case SORT_BY_SEARCH_ITEM:
            return { ...(action.sortbyIntegratedItem || {}) };
        default:
            return state;
    }
};
export const triggerForSearchAllDatasetInput = (state = {}, action) => {
    switch (action.type) {
        case TRIGGER_FOR_SEARCH_ALL_DATASET_INPUT:
            return action.triggerForSearchAllDatasetInput || {};
        default:
            return state;
    }
};
