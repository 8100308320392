import React from 'react';
import PropTypes from 'prop-types';
import { Box, Heading, Layer, Button, Text } from 'grommet';
import { FormClose } from 'grommet-icons';

const MessageModal = React.memo(
    ({ messageArray, subStringToRemove = '', filterFunction = (message) => message.includes(message), ...props }) => {
        const [showModal, setShowModal] = React.useState(false);

        React.useEffect(() => {
            messageArray.filter(filterFunction).length > 0 && setShowModal(true);
        }, [messageArray, filterFunction]);

        return (
            <React.Fragment>
                {showModal && (
                    <Layer onEsc={() => setShowModal(false)} margin="large">
                        <Box pad={{ bottom: 'small' }}>
                            <Box direction="row" justify="between">
                                <Box
                                    pad={{
                                        top: 'medium',
                                        horizontal: 'medium',
                                        bottom: 'small',
                                    }}
                                >
                                    <Heading margin="none" level="4">
                                        {props.title || 'Error message'}
                                    </Heading>
                                </Box>
                                <Box pad="xsmall">
                                    <Button
                                        alignSelf="start"
                                        plain
                                        icon={<FormClose />}
                                        onClick={() => {
                                            setShowModal(false);
                                        }}
                                    />
                                </Box>
                            </Box>
                            <Box
                                fill
                                pad={{
                                    top: 'none',
                                    horizontal: 'medium',
                                    bottom: 'medium',
                                }}
                            >
                                {messageArray.filter(filterFunction).length > 0 && props.mainTextComponent ? (
                                    props.mainTextComponent
                                ) : (
                                    <Text margin={{ bottom: 'xsmall' }} size="small" color="secondaryBody">
                                        {'Please send the below error message(s) to support:'}
                                    </Text>
                                )}
                                {messageArray.filter(filterFunction).map((message, index) => (
                                    <React.Fragment key={`error-message-${index}`}>
                                        <Text size="small" margin={{ bottom: 'xsmall' }}>
                                            {message && `${message}`.replace(subStringToRemove, '')}
                                        </Text>
                                    </React.Fragment>
                                ))}
                            </Box>
                        </Box>
                    </Layer>
                )}
            </React.Fragment>
        );
    },
);

MessageModal.whyDidYouRender = true;

MessageModal.propTypes = {
    messageArray: PropTypes.array.isRequired,
    subStringToRemove: PropTypes.string,
    filterFunction: PropTypes.func,
    mainTextComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.object]),
    mainTextColor: PropTypes.string,
    title: PropTypes.string,
};

export default MessageModal;
