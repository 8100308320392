import { xApiVersion } from './getDataSets';

export const getSingleElement = (id, origin = '0') => {
    var url_string = window.location.href;
    var url = new URL(url_string);
    var api = url.origin + '/api';
    return fetch(`${window?.config?.reactAppDatasetApi || api}/dataElement/${id}?origin=${origin}`, {
        method: 'get',
        headers: {
            'X-API-Version': xApiVersion,
        },
    });
};
