import { Box } from 'grommet';
import PropTypes from 'prop-types';
import React from 'react';
import { useId } from 'react-id-generator';
import ReactPaginate from 'react-paginate';
import './Paginate.css';

const Paginate = ({ pageCount, pageRangeDisplayed, onPageChange, ...props }) => {
    const [reactPaginateID] = useId();
    const currentPage = props.getReduxState && props.getReduxState();
    const [currentPageValue, setCurrentPageValue] = React.useState(0);
    React.useEffect(() => {
        handlePreviousButtonRestrictions(currentPageValue);
        handleNextButtonRestrictions(currentPageValue, pageCount);
    }, [currentPageValue, pageCount, reactPaginateID]);

    const onPageChangeWrapper = React.useCallback(
        (object) => {
            setCurrentPageValue(object.selected);
            onPageChange && onPageChange(object);
        },
        [onPageChange],
    );
    let brand = window.localStorage.getItem('branding');

    return pageCount > 1 ? (
        <Box>
            <ReactPaginate
                forcePage={currentPage ?? currentPageValue}
                id={reactPaginateID}
                previousLabel={'Prev'}
                nextLabel={'Next'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={pageCount}
                marginPagesDisplayed={1}
                pageRangeDisplayed={pageRangeDisplayed}
                onPageChange={onPageChangeWrapper}
                // containerClassName={adrConfig ? 'paginationAdr' : 'pagination'}
                containerClassName={brand == 'default' ? 'pagination' : 'pagination' + '-' + brand}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
            />
        </Box>
    ) : (
        <div aria-label="Pagination" className="react-paginate-hidden" role="navigation"></div>
    );
};

export default Paginate;

Paginate.propTypes = {
    onPageChange: PropTypes.func.isRequired,
    pageRangeDisplayed: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
    getReduxState: PropTypes.func,
};

const handlePreviousButtonRestrictions = (currentPageValue) => {
    const pagination = document.getElementsByClassName('pagination')[0];

    if (pagination) {
        for (let i = 0; i < pagination.childNodes.length; i++) {
            if (pagination.childNodes[i].className === 'previous') {
                pagination.childNodes[i].style.cursor = currentPageValue > 0 && 'pointer';
            } else if (pagination.childNodes[i].className === 'previous disabled') {
                pagination.childNodes[i].style.cursor = currentPageValue === 0 && 'default';
            }
        }
    }
};

const handleNextButtonRestrictions = (currentPageValue, limitPageNumber) => {
    const pagination = document.getElementsByClassName('pagination')[0];

    if (pagination) {
        for (let i = 0; i < pagination.childNodes.length; i++) {
            if (pagination.childNodes[i].className === 'next') {
                pagination.childNodes[i].style.cursor = currentPageValue < limitPageNumber && 'pointer';
            } else if (pagination.childNodes[i].className === 'next disabled') {
                pagination.childNodes[i].style.cursor = currentPageValue === limitPageNumber - 1 && 'default';
            }
        }
    }
};
