import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../MDW/MDW';
import { Box, Collapsible, Layer, ResponsiveContext } from 'grommet';
import { FormClose } from 'grommet-icons';
import theme from '../../../style/theme';

const SideBarContent = React.memo(({ children, ...props }) => {
    const size = React.useContext(ResponsiveContext);
    return (
        <React.Fragment>
            {!props.showSidebar || size !== 'small' ? (
                <Collapsible direction="horizontal" open={props.showSidebar}>
                    <Box
                        className="side-bar"
                        data-testid="side-bar"
                        flex
                        width={props.width}
                        direction="column"
                        background="primary"
                        align="start"
                        justify="start"
                        border={{
                            color: theme.global.colors.primaryDividerLine,
                            size: '1px',
                            style: 'solid',
                            side: 'left',
                        }}
                    >
                        {children}
                    </Box>
                </Collapsible>
            ) : (
                <Layer animation="fadeIn">
                    <Box
                        className="side-bar"
                        data-testid="side-bar"
                        background="primary"
                        tag="header"
                        justify="start"
                        align="start"
                        direction="column"
                    >
                        <Box pad={'medium'} alignSelf="end">
                            <Button plain icon={<FormClose />} onClick={() => props.setShowSidebar(false)} />
                        </Box>
                        <Box width="100%" background="primary" align="start" justify="start">
                            {children}
                        </Box>
                    </Box>
                </Layer>
            )}
        </React.Fragment>
    );
});

SideBarContent.propTypes = {
    setShowSidebar: PropTypes.func.isRequired,
    showSidebar: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
};

SideBarContent.whyDidYouRender = true;

export default SideBarContent;
