import { Button as GrommetButton, DropButton as GrommetDropdown } from 'grommet';
import styled from 'styled-components';
import theme from '../../../style/theme';

export const StyledDropdown = styled(GrommetDropdown)`
    ${({ plain, label }) =>
        (plain
            ? 'border: none;'
            : `border: solid 2px ${
                  theme?.global?.colors[window.localStorage.getItem('branding') || 'default']?.lightGrey
              }; 
        border-radius: ${theme.radii.large};`) +
        (!label
            ? `
		& > div > span {
			line-height: 12px;
		}`
            : '')}
    box-shadow: none;
    &: hover {
        box-shadow: none;
    }

    &:focus-within > div {
        border-radius: ${theme.radii.large};
        box-shadow: 0 0 0 ${theme.a11yBoxShadow.thickness}
            ${theme.global.colors[window.localStorage.getItem('branding') || 'default'].focusBorderColor};
    }
`;
export const StyledGrommetDropdownButton = styled(GrommetButton)`
    box-shadow: none;
    border-radius: 0px;
    border: solid 1px ${theme?.global?.colors[window.localStorage.getItem('branding') || 'default']?.lightGrey};
    border-bottom: none;
    padding-left: 3px;
    padding-right: 3px;
    &: hover {
        box-shadow: none;
    }

    &:first-of-type {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    &:last-of-type {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        border-bottom: solid 2px
            ${theme?.global?.colors[window.localStorage.getItem('branding') || 'default']?.lightGrey};
    }
    &:focus > div {
        border-radius: ${theme.radii.large};
        box-shadow: 0 0 0 ${theme.a11yBoxShadow.thickness}
            ${theme.global.colors[window.localStorage.getItem('branding') || 'default'].focusBorderColor};
    }
`;
