import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import InputBase from '@material-ui/core/InputBase';
import { ButtonBase } from '@material-ui/core';
import clsx from 'clsx';
import { removeArrayDuplicates } from '../../../utils/javascriptUtilities';
import { useHistory, useLocation } from 'react-router-dom';
import grommetTheme from '../../../style/theme';
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 500,
            width: 190,
            top: 400,
            left: 545,

            transitionDuration: 0,
            elevation: 0,
            getContentAnchorEl: null,
            transformOrigin: {
                vertical: 0,
                horizontal: 0,
            },
        },
    },
    // Jumping Issue fix
    variant: 'menu',
    getContentAnchorEl: null,
};

const useStyles = makeStyles((theme) => ({
    button: {
        display: 'block',
        marginTop: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        backgroundColor: '#303F6C',
    },
    container: {
        display: 'fixed',
    },
    wrapper: {
        minHeight: '2.45rem',
        maxHeight: '3rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttonBase: {
        borderRadius: '12px',
        backgroundColor: '#303F6C',
        color: 'white',
        width: '90%',
        height: '40px',
        margin: '10px',
    },
    icon: {
        borderRadius: 3,
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: `2px auto ${grommetTheme.global.colors[window.localStorage.getItem('branding')].secondary}`,
            outlineOffset: 2,
        },
        // 'input:hover ~ &': {
        //     backgroundColor: '#1b2844',
        // },
        // 'input:disabled ~ &': {
        //     boxShadow: 'none',
        //     background: 'rgba(206,217,224,.5)',
        // },
    },
    checkedIcon: {
        backgroundColor: '#dbdbdb',
        border: '2px solid red',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%231b2844'/%3E%3C/svg%3E\")",
            content: '""',
        },
        // 'input:hover ~ &': {
        //     backgroundColor: '#106ba3',
        // },
    },
}));
const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
        '& .MuiSvgIcon-root': {
            display: 'none',
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: '#303f6c',
        fontSize: 16,
        padding: '3px 26px 0px 12px',
        color: 'white',
        height: '100%',
        boxSizing: 'border-box',
        // transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        //        '&:focus': {
        //            borderRadius: 4,
        //            borderColor: 'red',
        //            boxShadow: '0 0 0 0.2rem red',
        //        },
    },
}))(InputBase);
const names = ['Dataset', 'Data Standard', 'Terminology', 'Data Class', 'Data Element'];
const paramNames = {
    dataset: 'Dataset',
    datastandard: 'Data Standard',
    terminology: 'Terminology',
    dataclass: 'Data Class',
    dataelement: 'Data Element',
};
function StyledCheckbox(props) {
    const classes = useStyles();

    return (
        <Checkbox
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            inputProps={{ 'aria-label': 'decorative checkbox' }}
            {...props}
        />
    );
}
export default function ControlledOpenSelect({ onSubmit, inputValue, setEmptyInput }) {
    const history = useHistory();
    const location = useLocation();
    let searchTermFromURL = new URLSearchParams(location.search).get('searchterm');
    let includeFromURL = new URLSearchParams(location.search).get('include');

    var initialDataTypes =
        window.localStorage.getItem('initalAdvancedSearchChecks') == 'true'
            ? ['Dataset', 'Data Standard', 'Terminology']
            : [];
    (includeFromURL || '').split('::').forEach((value) => {
        if (value) {
            initialDataTypes.push(paramNames[value]);
        }
    });

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [dataTypes, setDataTypes] = React.useState(initialDataTypes);
    const [selectAll, setSelectAll] = React.useState(false);
    console.log(dataTypes);
    const handleChange = (event) => {
        let {
            target: { value },
        } = event;
        if (value.includes('*')) {
            value = [...names];
        } else if (value.includes('#')) {
            value = [];
        }
        if (value.includes(undefined)) {
            value.pop();
        }
        setDataTypes(typeof value === 'string' ? value.split(',') : value);
    };
    React.useEffect(() => {
        if (includeFromURL && searchTermFromURL) {
            onSubmit({
                inputValue: searchTermFromURL,
                payload: includeFromURL,
            });
        }
    }, [includeFromURL, searchTermFromURL]);
    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClick = () => {
        if (inputValue.length === 0) {
            console.log('yes it is 0');
            setEmptyInput(true);
            return;
        }
        if (dataTypes.includes(undefined)) {
            dataTypes.pop();
        }
        setEmptyInput(false);
        let payload = [...dataTypes];
        let str = '';
        if (payload[0]) str += payload[0].replaceAll(/\s/g, '').toLowerCase();
        payload.length > 1 &&
            payload.forEach((value, index) => {
                str += payload[index + 1] ? '::' + payload[index + 1].replaceAll(/\s/g, '').toLowerCase() : '';
                return str;
            });
        console.log(inputValue);
        console.log(str);
        history.push(`?searchterm=${inputValue ?? ''}&include=${str}`);
        onSubmit({
            inputValue,
            payload: str,
        });
        setOpen(false);
        window.localStorage.removeItem('initalAdvancedSearchChecks');
    };

    return (
        <div className={classes.container}>
            <div className={classes.wrapper}>
                <FormControl className={classes.formControl} title="Advanced Search options">
                    <Select
                        aria-label="Advanced search multiple choice"
                        open={open}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        value={dataTypes}
                        onChange={handleChange}
                        renderValue={() => 'Advanced Search'}
                        multiple
                        displayEmpty
                        input={<BootstrapInput />}
                        MenuProps={MenuProps}
                        inputProps={{ text: 'Advanced search options' }}
                    >
                        {
                            <MenuItem
                                onClick={() => setSelectAll(!selectAll)}
                                value={!selectAll == true ? '*' : '#'}
                                key={'selectAll'}
                            >
                                <StyledCheckbox checked={selectAll} />
                                <ListItemText
                                    style={{ color: 'black' }}
                                    primary={!selectAll == true ? 'Select All ' : 'Unselect All'}
                                />
                            </MenuItem>
                        }
                        {names.map((name, index) => (
                            <MenuItem value={name} key={index}>
                                <StyledCheckbox checked={dataTypes.indexOf(name) > -1} />
                                <ListItemText style={{ color: 'black' }} primary={name} />
                            </MenuItem>
                        ))}
                        <ButtonBase className={classes.buttonBase} onClick={handleClick}>
                            Search
                        </ButtonBase>
                    </Select>
                </FormControl>
            </div>
        </div>
    );
}
