import { Box, Tab, Tabs, Text } from 'grommet';
import PropTypes from 'prop-types';
import { useState } from 'react';
import styled from 'styled-components';
import theme from '../../../../../../style/theme';

const StyledTabs = styled(Tabs)`
    & {
        max-width: none;
    }
    div {
        flex-wrap: nowrap;
    }
    div > button > div > span {
        white-space: nowrap;
    }
`;

const StyledTab = styled(Tab)`
    &:focus {
        border-radius: ${theme.radii.large};
        box-shadow: 0 0 0 ${theme.a11yBoxShadow.thickness}
            ${theme.global.colors[window.localStorage.getItem('branding')].secondary};
    }
`;

const PopUpTabs = ({ ...props }) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const onActive = (tabIndex) => {
        setActiveIndex(tabIndex);
        props.setCurrentTab(props.tabnames[tabIndex]);
        // window.localStorage.setItem('tabIndex',tabIndex)
    };
    return (
        <StyledTabs flex margin={{ top: '14px', bottom: '14px' }} justify="center" onActive={onActive}>
            {props.tabnames.map((tabName, index) => (
                <StyledTab
                    a11yTitle={tabName}
                    plain
                    key={index}
                    margin={{ right: props.tabnames.length <= 1 ? '' : '20px' }}
                    title={
                        <Box
                            pad={{ vertical: 'xsmall', horizontal: 'medium' }}
                            round={theme.radii.large}
                            {...(index === activeIndex
                                ? { background: theme.global.colors[window.localStorage.getItem('branding')].secondary }
                                : {
                                      background:
                                          theme.global.colors[window.localStorage.getItem('branding')].faintWhite,
                                  })}
                        >
                            <Text
                                {...(index === activeIndex
                                    ? { weight: 'bold', color: 'white' }
                                    : { weight: 'bold', color: 'black' })}
                                size={theme.fontSizes[3]}
                            >
                                {tabName}
                            </Text>
                        </Box>
                    }
                ></StyledTab>
            ))}
        </StyledTabs>
    );
};

export default PopUpTabs;

PopUpTabs.propTypes = {
    tabnames: PropTypes.arrayOf(PropTypes.string).isRequired,
};
