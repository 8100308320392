import {
    Box,
    Image,
    // Button,
    // Image,
    Text,
    Header,
} from 'grommet';
import styled from 'styled-components';

import { makeStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import DOMPurify from 'dompurify';
import React from 'react';
import dataStandartSvg from '../../../../assets/images/dataStandart.svg';
import dataStandartSvgBlack from '../../../../assets/images/dataStandartBlack.svg';
import letterBlack from '../../../../assets/images/letterBlack.svg';
import letterWhite from '../../../../assets/images/letterWhite.svg';
import terminologySvg from '../../../../assets/images/terminology.svg';
import terminologySvgBlack from '../../../../assets/images/terminologyBlack.svg';
import theme from '../../../../style/theme';
import assets from '../../../../assets/images/assets.svg';
import assetsBlack from '../../../../assets/images/assetsBlack.png';
import { ScrollButtons, Tag } from '../../../MDW/MDW';
import DataElementPopup from '../IndividualDatasetPageComponents/StructuralMetadata/DataElementPopup/DataElementPopup';
import DataAccessRequestButton from './DataAccessRequestButton';
import IndividualDatasetPageHeaderAltmetric from './IndividualDatasetPageHeaderAltmetric';
import IndividualDatasetPageHeaderDescription from './IndividualDatasetPageHeaderDescription';
import { building } from '../../../../assets/icons/MDW-icons';
// import terminologyBlackSvg from '../../../../assets'
const StyledSummaryTitleText = styled(Text)`
    white-space: break-spaces;
    padding: 0 10px;
`;
const StyledSummaryTitleHeader = styled(Header)`
    margin: 0px;
    line-height: 0px;
`;

const StyledPublisherText = styled(Text)`
    text-transform: capitalize;
    padding: 0 10px;
`;

// const StyledButton = styled(Button)`
//     & > img {
//         padding: 5px;
//     }
//     &:focus > img {
//         border-radius: ${theme.radii.large};
//         box-shadow: 0 0 0 ${theme.a11yBoxShadow.thickness} ${theme.global.colors[window.localStorage.getItem('branding') || 'default'].focusBorderColor};;
//     }
// `;
const StyledWrapperForHeader = styled.div`
    box-sizing: border-box;
    max-width: 100%;
    min-width: 0;
    min-height: 0;
    margin: 10px;
    margin-bottom: 15px;
    border-radius: 6px;
    z-index: 1000;
`;
const StyledRetiredButton = styled.div`
    display: flex;
    border: 2px solid yellow;
    border-radius: 6px;
    width: 150px;
    justify-content: center;
    align-items: center;
    height: 40px;
    margin-top: 12px;
`;
const StyledTypo = styled.p`
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    margin: 0;
`;
const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
        fontSize: '14px',
    },
    customWidth: {
        maxWidth: 500,
    },
    noMaxWidth: {
        maxWidth: 'none',
    },
}));
const IndividualDatasetPageHeader = ({ summary, status, dataModelType, dataStatus, versionHistory }) => {
    const classes = useStyles();
    const [isExpanded, setExpanded] = React.useState();
    const [isModalShown, setIsModalShown] = React.useState(false);
    let {
        title,
        // publisher: { name: publisherName },
        publisher,
        abstract,
        doiName,
        version,
        keywords,
        ...remainingSummaryObjects
    } = summary;
    console.log(summary);
    let subTitile = versionHistory
        ? versionHistory[0].latestVersion
        : // : combineMemberOfAndPublisherName(memberOf, publisherName);
          summary?.publisher?.name;

    let adrConfig = window.config?.branding === 'adr';

    let altmetricConfig = window.config.altmetric;
    // let keywordItems = window.localStorage.getItem('keywords');
    // let keyword = !keywordItems ? JSON.parse(keywordItems) : null;

    const handleOpenEmailClient = () => {
        const mailtoUrl = `mailto:${summary.contactPoint}`;
        window.open(mailtoUrl);
    };
    const hanldePopUp = () => {
        summary?.publisher && setIsModalShown(true);
    };
    const setModal = (value) => {
        //  setCurrentElement({});
        setIsModalShown(value);
    };
    // @ts-ignore
    remainingSummaryObjects = {
        ...remainingSummaryObjects,
    };
    DOMPurify.addHook('afterSanitizeAttributes', function (node) {
        // set all elements owning target to target=_blank
        if ('target' in node) {
            node.setAttribute('target', '_blank');
            node.setAttribute('rel', 'noopener');
        }
    });

    let desc = DOMPurify.sanitize(abstract, { USE_PROFILES: { html: true } }) || '';

    let dataType;
    if (dataModelType === 'PHYSICAL') {
        // dataType = "Dataset"

        dataType = (
            <Box direction="row" gap="xsmall" alignSelf="start" pad={'small'}>
                <Image
                    src={
                        window.localStorage.getItem('branding') === 'adr' ||
                        window.localStorage.getItem('branding') == 'dsc2'
                            ? assetsBlack
                            : assets
                    }
                    alt={`Physical data model icon`}
                ></Image>
                <Text
                    color={theme.global.colors?.[window.localStorage.getItem('branding')].primaryText}
                    weight="bold"
                    size="xsmall"
                >
                    Dataset
                </Text>
            </Box>
        );
    } else if (dataModelType === 'LOGICAL') {
        // dataType = "Data standard"
        dataType = (
            <Box direction="row" gap="xsmall" alignSelf="start" pad={'small'}>
                <Image
                    width={'11px'}
                    src={
                        window.localStorage.getItem('branding') === 'adr' ||
                        window.localStorage.getItem('branding') == 'dsc2'
                            ? dataStandartSvgBlack
                            : dataStandartSvg
                    }
                    alt={`Logical data model icon`}
                ></Image>
                <Text
                    color={theme.global.colors?.[window.localStorage.getItem('branding')].primaryText}
                    weight="bold"
                    size="xsmall"
                >
                    Data standard
                </Text>
            </Box>
        );
    } else {
        // dataType = "Terminology"
        dataType = (
            <Box direction="row" gap="xsmall" alignSelf="start" pad={'small'}>
                <Image
                    src={
                        window.localStorage.getItem('branding') === 'adr' ||
                        window.localStorage.getItem('branding') == 'dsc2'
                            ? terminologySvgBlack
                            : terminologySvg
                    }
                    alt={`Terminology data model icon`}
                ></Image>
                <Text
                    color={theme.global.colors?.[window.localStorage.getItem('branding')].primaryText}
                    weight="bold"
                    size="xsmall"
                >
                    Terminology
                </Text>
            </Box>
        );
    }
    console.log(dataType);

    return (
        <StyledWrapperForHeader
            style={{
                backgroundColor: theme.global.colors[window.localStorage.getItem('branding')].dataSetHeaderBackground,
                color: theme.global.colors[window.localStorage.getItem('branding')].primaryText,
                filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
            }}
        >
            {isModalShown && (
                <DataElementPopup
                    currentElement={{}}
                    setIsModalShown={setModal}
                    currentClass={'currentClass'}
                    // rebuildToolTip={rebuildToolTip}
                    // round={props.round}
                    isHeader={true}
                    heightNumber={200}
                    publisher={summary?.publisher}
                ></DataElementPopup>
            )}
            <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div onClick={hanldePopUp} style={{ display: 'flex', flexDirection: 'row' }}>
                    <Box
                        background={publisher?.logo ? 'white' : 'white'}
                        round="full"
                        width="72px"
                        height="72px"
                        pad="12px"
                        margin="10px 10px 0px 10px"
                        boxSizing="border-box"
                        style={{
                            flexShrink: '0',
                            border: publisher?.logo ? '0px' : '0px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {publisher?.logo ? (
                            <Image
                                src={publisher.logo}
                                aria-hidden
                                width="100%"
                                height="100%"
                                style={{ objectFit: 'contain' }}
                                alt={publisher?.name ? `Published by ${publisher?.name}` : 'Publisher logo'}
                            ></Image>
                        ) : (
                            <Image
                                src={building}
                                aria-hidden
                                height="26px"
                                style={{ objectFit: 'contain' }}
                                alt={publisher?.name ? `Published by ${publisher?.name}` : 'Publisher logo'}
                            ></Image>
                        )}
                    </Box>
                    <Box>
                        <Box width="100%" margin={{ bottom: 'small', top: 'small' }}>
                            <StyledSummaryTitleHeader as="h1">
                                <StyledSummaryTitleText
                                    size={title.length > 50 ? 'medium' : 'large'}
                                    weight={500}
                                    truncate={false}
                                    data-testid={`data-summary-title`}
                                    wordBreak="break-all"
                                    color={theme.global.colors[window.localStorage.getItem('branding')].primaryText}
                                >
                                    {title}
                                </StyledSummaryTitleText>
                            </StyledSummaryTitleHeader>
                        </Box>
                        <Box width="100%" margin={{ bottom: 'xsmall' }}>
                            <StyledPublisherText
                                size="xsmall"
                                truncate
                                color={theme.global.colors[window.localStorage.getItem('branding')].primaryText}
                            >
                                {subTitile}
                            </StyledPublisherText>
                        </Box>
                    </Box>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {status == 'RETIRED' && (
                        <StyledRetiredButton>
                            <StyledTypo>Retired</StyledTypo>
                        </StyledRetiredButton>
                    )}
                    {dataType}
                    {altmetricConfig && <IndividualDatasetPageHeaderAltmetric doiName={doiName} />}
                </div>
            </Box>
            <Box>
                {desc.length > 255 ? (
                    <IndividualDatasetPageHeaderDescription
                        abstract={abstract}
                        desc={desc}
                        bg={theme.global.colors[window.localStorage.getItem('branding')]}
                        textColor={theme.global.colors[window.localStorage.getItem('branding')].primaryText}
                        setExpanded={setExpanded}
                        isExpanded={isExpanded}
                    />
                ) : (
                    <Tooltip classes={{ tooltip: classes.button }}>
                        <div
                            style={{
                                width: '100%',
                                padding: '13px 10px 10px 10px',
                                fontSize: '12px',
                                letterSpacing: 'none',
                                boxSizing: 'border-box',
                            }}
                            dangerouslySetInnerHTML={{ __html: desc }}
                        ></div>
                    </Tooltip>
                )}
            </Box>
            <div
                style={{
                    display: isExpanded ? 'none' : 'flex',
                    justifyContent: 'space-between',
                    marginLeft: '10px',
                    alignItems: 'baseline',
                }}
            >
                <div style={{ display: 'flex' }}>
                    {/* {dataType} */}
                    <ScrollButtons ariaHiddenButtons height={{ max: '30px' }} headerTags={true}>
                        {keywords &&
                            keywords.map((tagName, tagIndex, index) => {
                                const trimmedTagName = tagName.toLowerCase().trim();
                                const ref = React.createRef();
                                const tagObject = {
                                    label: trimmedTagName,
                                    ref,
                                    type: 'keywords',
                                };
                                return (
                                    <div key={tagName} style={{ display: 'flex' }}>
                                        <Tag
                                            // theRef={ref}
                                            tagObject={tagObject}
                                            hasBoxShadow={false}
                                            id={`dataset-tag${tagIndex}-${tagName}`}
                                            dataTip={tagName || ''}
                                            // onClick={onTagClick(tagObject)}
                                            color={theme.global.colors.default.primary}
                                            backgroundColor={theme.global.colors.default.midGrey}
                                            // {...(tagName.label && !tagName.isEnabled
                                            //     ? { disabled: true }
                                            //     : tagName.label &&
                                            //       tagName.isEnabled && {
                                            //           disabled: false,
                                            //       })}
                                        >
                                            {tagName}
                                        </Tag>
                                    </div>
                                );
                            })}
                    </ScrollButtons>
                </div>

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        marginRight: '10px',
                        marginBottom: '10px',
                    }}
                >
                    <div
                        style={{
                            background:
                                theme.global.colors[window.localStorage.getItem('branding') || 'default']
                                    .contactButtonBgColor,
                            display: 'flex',
                            justifyContent: 'inherit',
                            alignItems: 'center',
                            width: '138px',
                            height: '44px',
                            borderRadius: '12px',
                            marginRight: '6px',
                            cursor: 'pointer',
                        }}
                        onClick={handleOpenEmailClient}
                    >
                        <img src={adrConfig ? letterBlack : letterWhite} alt="Contact us" />
                        <p
                            style={{
                                margin: '0',
                                fontSize: '14px',
                                color: theme.global.colors[window.localStorage.getItem('branding') || 'default']
                                    .primaryButtonText,
                            }}
                        >
                            Contact
                        </p>
                    </div>
                    {/* <div
                        style={{
                            background: adrConfig ? '#86CBBD' : '#6F9C64',
                            display: 'flex',
                            justifyContent: 'inherit',
                            alignItems: 'center',
                            width: '138px',
                            height: '44px',
                            borderRadius: '12px',
                            cursor: 'pointer',
                        }}
                    > */}
                    {/* <img src={adrConfig ? lockBlack : lockWhite} alt={adrConfig ? 'letterBlack' : 'lockWhite'} />
                        <p style={{ margin: '0', fontSize: '14px', color: adrConfig ? 'black' : 'white' }}>
                            Access Data
                        </p> */}
                    {dataStatus &&
                    (dataStatus.dataStatus === 'Secured' || dataStatus.dataStatus === 'External') &&
                    (dataStatus.dataAccess || window?.config?.requestDataAccessUrl) ? (
                        <DataAccessRequestButton dataStatus={dataStatus} />
                    ) : null}
                    {/* </div> */}
                </div>
            </div>
        </StyledWrapperForHeader>
    );
};

export default IndividualDatasetPageHeader;
