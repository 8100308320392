import React from 'react';
import store from '../../redux/store';
import { isItemPopulated } from '../../utils/javascriptUtilities';
import useDeepEffect from '../useDeepEffect/useDeepEffect';
import { isEqual } from 'lodash';
/**
 * The basic flow is:
 *
 * item = store.getState()[reduxItemName] -> fetchCall ->
 *  [success] -> middleWare -> reduxDispatchMethod
 *  [error] -> setErrorMessage
 *
 * The fetch call will only be made if the "item" is not populated (i.e. is empty) or `overRideState` is true.
 *
 * @returns {{isSuccessful: boolean}}
 */
const useFetchItemsToRedux = (
    fetchCall,
    reduxDispatchMethod,
    setErrorMessage,
    reduxItemName,
    middleWare,
    overRideState = false,
) => {
    const item = store.getState()[reduxItemName];

    if (typeof item !== 'object') {
        throw Error('redux state property must be of type array or object');
    }

    const [data, setData] = React.useState(item);
    const [isSuccessful, setIsSuccessful] = React.useState(false);

    useDeepEffect(() => {
        if (overRideState || !isItemPopulated(item)) {
            fetchCall()
                .then((result) => {
                    return result.json();
                })
                .then((response) => {
                    const associatedMedia = response?.documentation?.associatedMedia;
                    const structuralMetadata = response?.structuralMetadata;
                    // const summary = response?.summary;
                    response.structuralMetadata && delete response.structuralMetadata;
                    response.documentation && delete response.documentation.associatedMedia;
                    // response.summary && delete response.summary;
                    !window?.config?.displayOrigin && delete response.origin;
                    const citation = response.summary?.citation;
                    const doiName = response.summary?.doiName;
                    const publisher = response.summary?.publisher;
                    response.summary?.citation && delete response.summary?.citation;
                    response.summary?.doiName && delete response.summary?.doiName;
                    response.summary?.publisher && delete response.summary?.publisher;
                    response.summary = { ...response.summary, citation, doiName, publisher, associatedMedia };
                    // response = { summary, accociatedMedia, ...response };
                    structuralMetadata && (response = { ...response, structuralMetadata });
                    console.log(response);
                    if (response) {
                        const processedData = middleWare ? middleWare(response) : response;
                        if (processedData) {
                            reduxDispatchMethod(processedData);
                            setData((currentData) => !isEqual(processedData, currentData) && processedData);
                        }
                        setIsSuccessful(true);
                    } else {
                        handleErrorResponse(response);
                    }
                })
                .catch((error) => {
                    handleErrorCatch(error, setErrorMessage);
                });
        } else {
            setData((currentData) => {
                return !isEqual(item, currentData) && item;
            });
            setIsSuccessful(true);
        }
    }, [reduxDispatchMethod, setErrorMessage, reduxItemName, fetchCall, item, middleWare, overRideState]);
    return { isSuccessful, data };
};

export default useFetchItemsToRedux;

export const handleErrorResponse = (response) => {
    console.error('Fetch data failed: ', response);
    if (response.globalErrors && response.globalErrors.length) {
        throw Error('Items global error: ' + response.globalErrors.toString());
    } else if (response.fieldErrors && response.fieldErrors.length) {
        throw Error('Items field error: ' + JSON.stringify(response.fieldErrors, null, 2));
    } else {
        throw Error('Items fetch data failed: ' + JSON.stringify(response, null, 2));
    }
};

export const handleErrorCatch = (err, setErrorMessage) => {
    console.error('Fetch data error: ', err);
    const error = err.toString();
    setErrorMessage(`Error: ${error !== '[object Object]' ? error : JSON.stringify(err, null, 2)}`);
};
