import React from 'react';
import { addToAllFilters, receiveFilterMenuItems } from '../redux/actions/actions';
import store from '../redux/store';
import { removeArrayDuplicates } from '../utils/javascriptUtilities';
import { xApiVersion } from './getDataSets';

export const fetchEndPoint = (endPoint, setDataSets) => {
    var url = new URL(window.location.href);
    var api = url.origin + '/api';
    return fetch(
        `${window?.config?.reactAppDatasetApi || api}/dataset${endPoint}${endPoint === '' ? '?' : ''}`.replace(
            /searchterm/,
            'searchTerm',
        ),
        {
            method: 'get',
            headers: {
                'X-API-Version': xApiVersion,
            },
        },
    )
        .then((response) => response.json())
        .then((result) => {
            // let secondPart = [];
            // let firstPart = [];
            // result.filters.filter((item) => {
            //     if (['themes', 'partner'].includes(item.name)) {
            //         firstPart.push(item);
            //     } else {
            //         secondPart.push(item);
            //     }
            // });
            // result.filters = [...firstPart, ...secondPart];
            // console.log(result.filters)
            getMenuItemsFromResponse(result);
            setDataSets(result);
        })
        .catch((err) => {
            throw new Error(err);
        });
};

const getMenuItemsFromResponse = (data) => {
    const processedFilters = data.filters.map((item) => {
        return { ...item, options: processMenuItems(removeArrayDuplicates(item.options), item.name) };
    });
    store.dispatch(receiveFilterMenuItems(processedFilters));
};

export const processMenuItems = (options, catagoryName) => {
    const checkboxObjectList = options
        .map((menuItem) => {
            const ref = React.createRef();
            const checkBoxObject = {
                label: menuItem,
                ref,
                type: catagoryName.replace(/ /g, ''),
            };
            return checkBoxObject;
        })
        .filter(({ label }) => label);

    console.time('Dispatch');
    store.dispatch(
        addToAllFilters(
            checkboxObjectList.map((checkBoxObject) => {
                return { ...checkBoxObject, label: checkBoxObject.label.toLowerCase().trim() };
            }),
        ),
    );
    console.timeEnd('Dispatch');

    return checkboxObjectList;
};
