import { Box, Image, Text } from 'grommet';
import analytics from '../../../analytics/analytics';
import { DownloadIcon, FileIcon } from '../../../assets/icons/MDW-icons';
import { formatBytes } from '../../../utils/javascriptUtilities';
import { StyledLink } from './S3Browser';

const sendDownloadEvent = (linkStem: string | undefined, fullPathName: string) => {
    analytics.sendEvent({
        category: 'Individual Dataset',
        action: `File Download`,
        label: `${linkStem}/${fullPathName}`,
    });
};

function getDownloadLink(linkStem: string, fullPathName: string, filename: string) {
    const filePathExcludingFileName = fullPathName.split('/').slice(0, -1).map(encodeURIComponent).join('/');
    // At the top level, fullPathName can be just a filename, e.g. "data.txt". In which case filePathExcludingFileName is the empty string. In which case we don't want to include a trailing slash.
    return `${linkStem}/${filePathExcludingFileName !== '' ? filePathExcludingFileName + '/' : ''}${encodeURIComponent(
        filename,
    )}?${responseContentDispositionAttachment}`;
}

const responseContentDispositionAttachment = 'response-content-disposition=attachment';
const S3FileRow = (props: { fileTreeNodeName: string; fullPathName: string; size: number; linkStem?: string }) => {
    const href = getDownloadLink(props.linkStem || '', props.fullPathName, props.fileTreeNodeName);
    const downloadLinkProps = {
        href: href,
        target: '_blank',
        download: props.fileTreeNodeName,
        rel: 'noopener noreferrer',
        style: { outline: 'none' },
        onClick: () => {
            sendDownloadEvent(props.linkStem, props.fullPathName);
        },
    };
    return (
        <Box direction="row" justify="between" pad={{ right: 'medium' }}>
            <Box direction="row" gap="small">
                <Image alt={'File Icon'} height="24px" width="24px" src={FileIcon} />
                <StyledLink {...downloadLinkProps} aria-hidden={'true'} tabIndex={-1}>
                    <Text wordBreak="break-word" data-testid="File Download Link Text" weight={400}>
                        {props.fileTreeNodeName}
                    </Text>
                </StyledLink>
            </Box>
            <Box direction="row" gap="small" pad={{ left: 'large' }}>
                <Text>{props.size === 0 ? '0 Bytes' : formatBytes(props.size)}</Text>
                <a {...downloadLinkProps}>
                    <Image
                        data-testid="File Download Link Icon"
                        alt={'Download File'}
                        height="24px"
                        width="24px"
                        src={DownloadIcon}
                    />
                </a>
            </Box>
        </Box>
    );
};

export default S3FileRow;
