import { Box } from 'grommet';

import React from 'react';
import theme from '../../../../../style/theme';
// import { seperateCamelCase } from '../../../../../utils/javascriptUtilities';
import { seperateCamelCase } from '../../../../../utils/javascriptUtilities';
import {
    DescriptiveMetadata,
    IndividualDatasetPageContentsProperProps,
    MetadataSection,
    Observation,
} from '../../IndividualDatasetPageContents/IndividualDatasetPageContents';
import DescriptiveGenericSection from './DescriptiveGenericSection';
import { DescriptiveSummary } from './DescriptiveMetadataListComponents';
import { StyledMetadataBox, StyledSubTitleText } from './DescriptiveMetadataListUtils';
import DescriptiveObservation from './DescriptiveObservations';

type DescriptiveMetadataListProps = Omit<
    IndividualDatasetPageContentsProperProps,
    'dataSet' | 'showDataDownloadBrowser' | 'round'
> & {
    descriptiveMetadata: DescriptiveMetadata;
};

const DescriptiveMetadataList = React.memo(({ descriptiveMetadata, listRef }: DescriptiveMetadataListProps) => {
    const isFinalized = descriptiveMetadata.status;
    const descriptiveMetadataStatusless = { ...descriptiveMetadata };
    delete descriptiveMetadataStatusless.status;
    delete descriptiveMetadataStatusless.versionHistory;
    delete descriptiveMetadataStatusless.dataModel;
    delete descriptiveMetadataStatusless.dataModelType;

    // doi publication date selected from citation and merged with doiName as a html for showing it proper;
    // const { summary } = descriptiveMetadataStatusless;
    const summary = JSON.parse(JSON.stringify(descriptiveMetadataStatusless.summary));
    let publicationDate = summary?.citation?.split(',')[1];
    const { doiName } = summary;
    React.useEffect(() => {
        if (doiName !== undefined) {
            summary.doiName = `<p style="margin:0px;">${doiName}&nbsp;<span style="font-size:12px;font-weight: bold;line-height:8px;padding-left:40%">Publication Date:</span>${publicationDate}</p>`;
        }
        return () => {
            summary.doiName = doiName;
        };
    }, []);

    console.log(summary);

    const metadataSectionNames: string[] = Object.keys(descriptiveMetadataStatusless);

    const assetless = metadataSectionNames.indexOf('assets');
    if (assetless > -1) {
        // only splice array when item is found
        metadataSectionNames.splice(assetless, 1); // 2nd parameter means remove one item only
    }
    const {
        observations,
        // @ts-ignore
        documentation: { associatedMedia: associatedMediaArray } = {},
    } = descriptiveMetadataStatusless;
    const speciallyHandledMetadataSections = ['summary', 'observations'];

    return (
        <Box ref={listRef}>
            {metadataSectionNames?.length &&
                metadataSectionNames.map((metadataSectionName, index) => (
                    <StyledMetadataBox
                        key={`metadata-box-${index}`}
                        data-testid={`metadata-${metadataSectionName}-row-id`}
                    >
                        <Box
                            pad={{ vertical: 'small', left: 'small', right: '15px' }}
                            data-testid={`metadata-row-id-${index}`}
                        >
                            {metadataSectionName !== 'summary' && (
                                <Box border={{ side: 'top', color: theme?.global?.colors?.default?.dividerLine }}>
                                    <StyledSubTitleText
                                        data-testid={`${metadataSectionName}-list-title2`}
                                        size="small"
                                        weight="bold"
                                        margin={{ bottom: 'small', top: 'large' }}
                                    >
                                        {metadataSectionName === 'gap' ? '' : seperateCamelCase(metadataSectionName)}
                                    </StyledSubTitleText>
                                </Box>
                            )}
                            {summary && metadataSectionName === 'summary' && (
                                <Box margin={{ bottom: 'small', top: 'small' }}>
                                    <DescriptiveSummary
                                        isFinalized={isFinalized}
                                        // @ts-ignore
                                        summary={summary}
                                        associatedMediaArray={associatedMediaArray}
                                        lastSection={index === metadataSectionNames?.length - 1}
                                    />
                                </Box>
                            )}
                            {observations && metadataSectionName === 'observations' && (
                                <Box margin={{ bottom: 'small', top: 'small' }}>
                                    <DescriptiveObservation
                                        observations={observations as Observation[]}
                                        lastSection={index === metadataSectionNames?.length - 1}
                                    />
                                </Box>
                            )}
                            {!speciallyHandledMetadataSections.includes(metadataSectionName) && (
                                <Box margin={{ bottom: 'small', top: 'small' }}>
                                    <DescriptiveGenericSection
                                        metadataSectionName={metadataSectionName}
                                        metadataSection={
                                            descriptiveMetadataStatusless[metadataSectionName] as MetadataSection
                                        }
                                        lastSection={index === metadataSectionNames?.length - 1}
                                    />
                                </Box>
                            )}
                        </Box>
                    </StyledMetadataBox>
                ))}
        </Box>
    );
});

DescriptiveMetadataList.whyDidYouRender = true;

export default DescriptiveMetadataList;
