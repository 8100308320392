import CustomLinkify from 'react-linkify';
import styled from 'styled-components';
import theme from '../../../style/theme';

const StyledLinkifyDiv = styled.div`
    & a {
        box-sizing: border-box;
        font-size: inherit;
        line-height: inherit;
        color: ${theme.global.colors.primary};
        font-weight: 400;
        -webkit-text-decoration: none;
        text-decoration: none;
        cursor: pointer;
    }
`;
const StyledDivForTextHoldingAsMedia = styled.span`
    background-color: rgb(215, 219, 220);
    border-radius: 6px;
    padding: 3px;
    height: 10px;
`;
const Linkify = ({ children, ...props }) => {
    const child = children && children.includes('http') ? children.replaceAll(',', ' ') : children;
    return (
        <StyledLinkifyDiv>
            <CustomLinkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                    <a target="blank" href={decoratedHref} key={key} style={{ textDecoration: 'no' }}>
                        <StyledDivForTextHoldingAsMedia>
                            &nbsp;&nbsp;
                            {decoratedText.length > 100 ? decoratedText.slice(0, 100) + '...' : decoratedText}
                            &nbsp;&nbsp;
                        </StyledDivForTextHoldingAsMedia>
                    </a>
                )}
            >
                {child}
            </CustomLinkify>
        </StyledLinkifyDiv>
    );
};

export default Linkify;
