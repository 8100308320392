import { Box, Button, Image, Stack, Text } from 'grommet';
import React from 'react';
import styled from 'styled-components';
import {
    popUpElementIcon,
    popUpFormCloseIcon,
    popUpFormCloseIconBlack,
    building,
} from '../../../../../../assets/icons/MDW-icons';
import theme from '../../../../../../style/theme';
import { isTesting } from '../../../../../../utils/testingUtils';
import { List, ToolTip } from '../../../../../MDW/MDW';
import { StyledListRowLine } from '../../../../../organisms/List/useListRow';
import {
    StyledElementModalHeading,
    StyledElementModalSubHeading,
    StyledMetadataBox,
} from '../../DescriptiveMetadata/DescriptiveMetadataListUtils';
import DataElementPopUpTabs from './DataElementPopUpTabs';
import { useLocation, useHistory } from 'react-router-dom';
import roundPic from '../../../../../../assets/images/roundPic-adr.png';

export const truncateLength = 100;

const ListWrapper = ({ className, ...props }) => {
    return (
        <div className={className}>
            <List {...props} />
        </div>
    );
};

export const StyledEnumList = styled(ListWrapper)`
    div.track-vertical {
        margin-right: 15px;
        background-color: ${theme.global.colors.lightGrey};
    }
    div.thumb-vertical {
        background-color: ${theme.global.colors.alternativeBlue};
    }
`;

export const StyledGridBox = styled(Box)`
    display: grid;
`;
export const StyledGridText = styled(Text)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const StyledButton = styled(Button)`
    padding-left: 5px;
    padding-right: 5px;
    &:focus {
        border-radius: ${theme.radii.large};
        box-shadow: 0 0 0 ${theme.a11yBoxShadow.thickness}
            ${theme.global.colors[window.localStorage.getItem('branding') || 'default'].focusBorderColor};
    }
`;

export const ElementPopUpTitleValueBlock = (props) => {
    const processedDescription =
        props.description?.length > truncateLength
            ? props.description.slice(0, truncateLength) + '...'
            : props.description;

    return (
        <Box
            margin={{ top: 'xsmall' }}
            pad={
                props.pad || {
                    top: 'none',
                    horizontal: 'small',
                }
            }
        >
            {props.title && (
                <Box pad={{ left: 'small' }}>
                    <Text size="xsmall">{`${props.title} `}</Text>
                </Box>
            )}
            <Box
                {...(props.title && { background: props.dark ? 'white' : 'lighterGrey', pad: { left: 'small' } })}
                data-tip={props.description}
            >
                <StyledElementModalSubHeading
                    color={props.color}
                    size={props.subHeadingSize || 'small'}
                    weight="normal"
                >
                    {processedDescription}
                </StyledElementModalSubHeading>
            </Box>
        </Box>
    );
};

export const ElementPopUpContentBlock = (props) => {
    return (
        <Box
            ref={props.containerRef}
            pad={{
                bottom: 'none',
            }}
        >
            <Box
                background={'white'}
                pad={'small'}
                margin={{ top: '-1px' }}
                {...(props.isAtBottom && {
                    round: { size: 'small', corner: 'bottom' },
                })}
            >
                <Box
                    pad={{
                        top: 'none',
                        horizontal: 'small',
                    }}
                >
                    <Text size="16px" weight={theme.fontWeights.semibold}>
                        {`${props.title} `}
                    </Text>
                </Box>
                {props.children}
                {!props.isAtBottom ? (
                    <Box direction="row" justify="center" width="100%">
                        <StyledListRowLine width="calc(100% - 13px - 13px)" margin={{ vertical: 'small' }} />
                    </Box>
                ) : (
                    <Box direction="row" justify="center" width="100%" pad={{ vertical: 'xsmall' }} />
                )}
            </Box>
        </Box>
    );
};

export const ElementPopUpTitleBlock = ({
    setCurrentTab,
    currentTab,
    PopUpElementTabNames,
    containerRef,
    pingState,
    ping,
    ...props
}) => {
    let adr = window.config?.branding == 'adr';
    const location = useLocation();
    const history = useHistory();
    const hasContent =
        currentTab === 'Details'
            ? props.dataType || props.enums
            : currentTab === 'Metadata'
            ? props.metadata
            : currentTab === 'Relationships'
            ? props.relationships
            : false;
    // const hasContent =
    //     currentTab === 'Details'
    //         ? props.dataType || props.enums
    //         : currentTab === 'Metadata'
    //         ? props.metadata
    //         : currentTab === 'Relationships'
    //         ? props.relationships
    //         // : currentTab === ''
    //         // ? props.contact
    //         : false;
    console.log(props);
    React.useEffect(() => {
        const height = containerRef && containerRef.current && containerRef.current.clientHeight;
        if (height) {
            pingState(height);
        }
    }, [containerRef, pingState, ping]);
    const handleClose = () => {
        props.setIsModalShown && props.setIsModalShown(false);
        history.push('dataset' + location.search.split('&')[0] + '&' + location.search.split('&')[1]);
    };
    return (
        <Box ref={containerRef} style={{ zIndex: '0' }}>
            <Box
                round={{
                    size: theme.radii.small,
                    ...(hasContent && {
                        corner: 'top',
                    }),
                }}
            >
                <Stack anchor="top">
                    <Box
                        {...(PopUpElementTabNames?.length && {
                            background: theme.global.colors[window.localStorage.getItem('branding')].primary,
                            pad: {
                                horizontal: 'large',
                                bottom: 'medium',
                            },
                            round: {
                                size: theme.radii.xlarge,
                                corner: 'top',
                            },
                        })}
                    >
                        <Box
                            round={{
                                size: theme.radii.xlarge,
                                corner: 'top',
                            }}
                            background={'default.richAlternativeBlue'}
                            pad={{
                                horizontal: 'small',
                                top: 'small',
                                bottom: PopUpElementTabNames?.length ? 'small' : 'large',
                            }}
                        >
                            <Box height="33px">
                                <StyledButton
                                    alignSelf="end"
                                    plain
                                    label={
                                        <Image
                                            alt="Close"
                                            src={adr ? popUpFormCloseIconBlack : popUpFormCloseIcon}
                                            height="11px"
                                            width="11px"
                                        ></Image>
                                    }
                                    onClick={handleClose}
                                />
                            </Box>
                            <Box direction="row" justify="center">
                                {/* {props.element?.contact ? (
                                    <StyledElementModalHeading
                                        margin={{
                                            left: 'small',
                                            top: 'small',
                                            right: 'none',
                                            bottom: '2px',
                                        }}
                                        level="3"
                                    >
                                        {props.element?.contact.name}
                                    </StyledElementModalHeading>
                                ) : ( */}
                                <StyledElementModalHeading
                                    margin={{
                                        left: props.element?.contact ? '' : 'small',
                                        top: 'small',
                                        right: 'none',
                                        bottom: '2px',
                                    }}
                                    level="3"
                                >
                                    {props.element?.contact ? props.element?.contact?.name : props.element?.name}
                                </StyledElementModalHeading>
                                {/* )} */}
                            </Box>
                            {/* <Box direction="row" justify="center">
                                <ElementPopUpTitleValueBlock
                                    subHeadingSize="small"
                                    color="dark"
                                    description={props.element?.description}
                                />
                            </Box> */}
                        </Box>
                        {!PopUpElementTabNames?.length || (
                            <Box width="100%" direction="row" justify="center">
                                <DataElementPopUpTabs setCurrentTab={setCurrentTab} tabnames={PopUpElementTabNames} />
                            </Box>
                        )}
                    </Box>
                    <PopUpElementIcon isHeader={Boolean(props.element?.contact)} logo={props?.element?.contact?.logo} />
                </Stack>
            </Box>
        </Box>
    );
};

export const ElementPopUpEnumList = (props) => {
    return (
        <Box ref={props.containerRef}>
            <Box
                pad={{
                    bottom: 'none',
                }}
            >
                <Box
                    background={'white'}
                    pad={{
                        bottom: 'xsmall',
                    }}
                    borderBottom="none"
                >
                    <Box
                        pad={{
                            vertical: 'xsmall',
                            horizontal: 'medium',
                        }}
                    >
                        <Text size="16px" weight={theme.fontWeights.semibold}>
                            Enumerations
                        </Text>
                    </Box>
                </Box>
            </Box>
            <Box
                width="100%"
                pad={{
                    top: 'none',
                }}
                background="white"
            >
                <Box
                    round={{
                        size: theme.radii.small,
                        corner: 'bottom',
                    }}
                >
                    {!isTesting && <ToolTip />}
                    <StyledEnumList
                        round={{ size: 'small', corner: 'bottom' }}
                        listLength={props.enums && props.enums.length}
                        rowDirection="column"
                        heightNumber={props.enums && props.enums.length > 1 ? 300 : 150}
                        listRef={props.listRef}
                        dataTestId="element-popup-enum-list"
                        rowPadding={{ left: 'small', right: '30px' }}
                        plain
                        listBackground="white"
                        rowBackground="white"
                    >
                        {({ index }) => {
                            return (
                                <React.Fragment>
                                    <StyledMetadataBox>
                                        <Box
                                            pad={{ top: 'small', left: 'medium' }}
                                            data-testid={`enum-row-id-${index}`}
                                        >
                                            <Box direction="row" pad={{ bottom: 'xsmall' }}>
                                                <StyledGridBox width="20%" data-tip={props.enums[index]?.name}>
                                                    <StyledGridText
                                                        size="small"
                                                        weight={theme.fontWeights.semibold}
                                                        color={`${theme.global.colors.textLightGrey}`}
                                                    >
                                                        {`${props.enums[index]?.name}: `}
                                                    </StyledGridText>
                                                </StyledGridBox>
                                                <StyledGridBox width="77%" pad={{ left: '3%' }}>
                                                    <Text size="small" weight={theme.fontWeights.semibold}>
                                                        {props.enums[index]?.description}
                                                    </Text>
                                                </StyledGridBox>
                                            </Box>
                                        </Box>
                                    </StyledMetadataBox>
                                </React.Fragment>
                            );
                        }}
                    </StyledEnumList>
                </Box>
            </Box>
        </Box>
    );
};

const TransformedBox = styled(Box)`
    div {
        transform: translateY(-50%);
    }
`;
const PopUpElementIcon = ({ isHeader, logo }) => {
    console.log(logo, isHeader, popUpElementIcon);
    return (
        <TransformedBox direction="row" justify="center">
            <Box
                background="white"
                round="full"
                pad={isHeader ? '12px' : '24px'}
                width="72px"
                height="72px"
                boxSizing="border-box"
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                {isHeader ? (
                    <Image
                        src={isHeader && logo ? logo : building}
                        aria-hidden
                        width={isHeader && logo ? '48px' : '26px'}
                        height={isHeader && logo ? '48px' : '26px'}
                        style={{ objectFit: 'contain' }}
                    ></Image>
                ) : (
                    <Image
                        src={popUpElementIcon}
                        aria-hidden
                        width={'24px'}
                        height={'24px'}
                        style={{ objectFit: 'contain' }}
                    ></Image>
                )}
            </Box>
        </TransformedBox>
    );
};
