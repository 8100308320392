import { Box, Tab, Tabs, Text } from 'grommet';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { showTreeviewDataInDataSet, updateCurrentClass } from '../../../../redux/actions/actions';
import store from '../../../../redux/store';
import theme from '../../../../style/theme';
import { useSideBarAndDataDownloadContext } from '../../../templates/GenericPageTemplate/GenericPageTemplate';
import { handleScrollTo } from '../../BrokerSearchPage/BrokerSearchPageContents/BrokerSearchPageContentsUtils';
import { ListRef } from '../../../organisms/List/List';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { useLocation } from 'react-router-dom';

import {
    terminology,
    dataDownloadTabName,
    dataDictionaryTabName,
    glossary,
} from './IndividualDatasetPageContentsUtils';

const StyledTabs = styled(Tabs)`
    & {
        max-width: none;
        background: white;
        color: #444444;
        padding: 0px 10px;
        z-index: 1;
    }
    div {
        flex-wrap: nowrap;
    }
    div > button > div > span {
        white-space: nowrap;
    }
`;

const StyledTab = styled(Tab)`
    &:focus {
        border-radius: ${theme.radii.large};
        box-shadow: 0 0 0 ${theme.a11yBoxShadow.thickness}
            ${theme.global.colors[window.localStorage.getItem('branding') || 'default'].focusBorderColor};
    }
`;

const StyledCloudDownloadIcon = styled(CloudDownloadIcon)`
    padding-left: 5px;
    padding-bottom: 5px;
    opacity: 0.8;
`;

type MetadataTabsProps = {
    menuItems: MenuItems;
    listRef: ListRef;
};

export type MenuItems = string[];

const MetadataTabs = ({ menuItems, listRef }: MetadataTabsProps) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const location = useLocation();

    const {
        setShowSidebar,
        setShowSidebarGlossary,
        setShowDataDownloadBrowser,
        setShowVersionHistory,
        setShowAssets,
        setShowTreeData,
        setOpenFirstClass,
    } = useSideBarAndDataDownloadContext();

    useEffect(() => {
        if (location.search.includes('dataclass')) {
            setActiveIndex(menuItems.indexOf(dataDictionaryTabName));
            setShowSidebar(true);
            setShowTreeData(true);
            store.dispatch(updateCurrentClass({}));
        }
    }, [location]);

    const onActive = (e: number) => {
        setActiveIndex(e);
        if (menuItems && menuItems[e] === 'Assets') {
            setShowAssets(true);
            store.dispatch(updateCurrentClass({}));
        } else {
            setShowAssets(false);
            store.dispatch(updateCurrentClass({}));
        }
        if (menuItems && menuItems[e] === 'Version History') {
            setShowVersionHistory(true);
            store.dispatch(updateCurrentClass({}));
        } else {
            setShowVersionHistory(false);
            store.dispatch(updateCurrentClass({}));
        }
        if (menuItems && menuItems[e] === dataDictionaryTabName) {
            setShowSidebar(true);
            setShowTreeData(true);
            setOpenFirstClass(true);
            store.dispatch(updateCurrentClass({}));
        } else {
            setShowSidebar(false);
            setShowTreeData(false);
            store.dispatch(showTreeviewDataInDataSet({}));
            store.dispatch(updateCurrentClass({}));
        }
        if (menuItems && menuItems[e] === terminology) {
            setShowSidebar(true);
            setShowTreeData(true);
            store.dispatch(updateCurrentClass({}));
        } else {
            setShowSidebar(false);
            setShowTreeData(false);
            store.dispatch(updateCurrentClass({}));
        }
        if (menuItems && menuItems[e] === glossary) {
            setShowSidebarGlossary(true);
            store.dispatch(updateCurrentClass({}));
        } else {
            setShowSidebarGlossary(false);
            store.dispatch(updateCurrentClass({}));
        }
        if (menuItems && menuItems[e] === dataDownloadTabName) {
            setShowDataDownloadBrowser(true);
        } else {
            // Descriptive Metadata Tab
            setShowDataDownloadBrowser(false);
            store.dispatch(updateCurrentClass({}));

            let position = 0;
            for (var i = 0; i < menuItems.length; i++) {
                if (menuItems[i] == menuItems[e]) break;
                if (
                    !menuItems[i].match(
                        /(Assets|Version History|Terminology|Data Dictionary|Structural Metadata|Data|Data Download)/gi,
                    )
                )
                    position++;
            }

            handleScrollTo(listRef, position);
        }
    };

    return (
        <StyledTabs flex justify="start" onActive={onActive}>
            {menuItems.map((menuItem, index) => (
                <StyledTab
                    plain
                    key={index}
                    // @ts-ignore: This is not in the Grommet type but it seems to work anyways...
                    margin={{ right: '30px' }}
                    title={
                        <Box direction="row" align="center">
                            <Text
                                style={{
                                    border:
                                        index === activeIndex
                                            ? `2px ${
                                                  theme.global.colors[
                                                      window.localStorage.getItem('branding') || 'default'
                                                  ].secondary
                                              } solid`
                                            : '',
                                    borderRadius: index === activeIndex ? '6px' : '',
                                    padding: index === activeIndex ? '2px' : '',
                                }}
                                {...(index === activeIndex && { weight: 'bold' })}
                                size={theme.fontSizes[3]}
                            >
                                {menuItem}
                            </Text>
                            {menuItem === dataDownloadTabName ? <StyledCloudDownloadIcon /> : null}
                        </Box>
                    }
                />
            ))}
        </StyledTabs>
    );
};

export default MetadataTabs;
