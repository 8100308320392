import { Box, Button, Image, Text } from 'grommet';
import { RoundType } from 'grommet/utils';
import React from 'react';
import { MDX_BROWSER_PREFIX, ADR_BROWSER_PREFIX, useTitle } from '../../../../hooks/useTitle/useTitle';
import store from '../../../../redux/store';
import theme from '../../../../style/theme';
// import { isTesting } from '../../../../utils/testingUtils';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { thinArrowLeftDark, thinArrowLeftWhite } from '../../../../assets/icons/MDW-icons';
import { showTreeviewDataInDataSet } from '../../../../redux/actions/actions';
import { ScrollButtons, ToolTip } from '../../../MDW/MDW';
import { useSideBarAndDataDownloadContext } from '../../../templates/GenericPageTemplate/GenericPageTemplate';
import { goBackFromIndividualDatasetPage } from '../../BrokerSearchPage/BrokerSearchPageContents/BrokerSearchComponent';
import BrowserAssetsWrapper from '../../BrowserAssets/BrowserAssetsWrapper';
import S3BrowserWrapper from '../../S3Browser/S3BrowserWrapper';
import DescriptiveMetadataList from '../IndividualDatasetPageComponents/DescriptiveMetadata/DescriptiveMetadataList';
import {
    ElementPopUpContentBlock,
    StyledGridBox,
    StyledGridText,
} from '../IndividualDatasetPageComponents/StructuralMetadata/DataElementPopup/DataElementPopupUtils';
import StructuralDataContent from '../IndividualDatasetPageComponents/StructuralMetadata/StructuralDataContent';
import {
    extractSearchMenuLabels,
    IndividualDatasetPageContentsBox,
    individualDatasetPageTabRenaming,
} from './IndividualDatasetPageContentsUtils';
import MetadataTabs, { MenuItems } from './IndividualDatasetPageTabs';
// import SummeryHeader from './SummeryHeader';

// import IndividualDatasetPageHeader from './IndividualDatasetPageHeader'
// import IndividualDatasetPageHeader from './IndividualDatasetPageHeader';
// import StructuralGlossaryContent from '../IndividualDatasetPageComponents/StructuralMetadata/StructuralGlossaryContent';
import history from '../../../../utils/history';
import IndividualDatasetPageHeader from './IndividualDatasetPageHeader';
import { Tooltip, makeStyles } from '@material-ui/core';
declare global {
    interface Window {
        config: any;
    }
}
export const classItemIdPrefix = `data-class-name-`;
type Assets = {
    location: string;
    files: {
        name: string;
        size: string;
        id: string;
    }[];
};
// type DocumentControl = {
//     stage: string;
//     scope: string;
//     sponsor:string;
//     sro: string;
//     businessLead:string;
//     technicalCommittee: string;
//     approvalDate: string;
//     legalAuthority: string;
//     postImplementationReviewDate: string;
//     reviews:[];
// }
export type Dataset = DescriptiveMetadata & EverythingElseInDataset & Assets;

type EverythingElseInDataset = {
    id: string;
    structuralMetadata: DataClassInfo[];
    data?: DataDownloadInformation;
    dataStatus?: DataStatus;
};

type DataStatus = {
    dataStatus: 'None' | 'Open' | 'Secured' | 'External';
    dataAccess?: string;
};

export const splitDataset: (dataset: Dataset) => [DescriptiveMetadata, EverythingElseInDataset] = (
    dataset: Dataset,
) => {
    const { id, structuralMetadata, data, dataStatus, ...descriptiveMetadata } = dataset;
    return [descriptiveMetadata, { id, structuralMetadata, data, dataStatus }];
};

export type DescriptiveMetadata = {
    summary: SummaryMetadataSection;
} & {
    [key: string]: MetadataSection | Observation[];
};

export interface SummaryMetadataSection {
    title: string;
    publisher: {
        name: string;
        memberOf: string;
    };
    abstract: string;
    citation: string;
    [key: string]: NestedMetadataSection | string | undefined;
}

export interface MetadataSection {
    [key: string]: NestedMetadataSection | string | string[] | undefined;

    documentation?: { associatedMedia: string[] };
}

export interface NestedMetadataSection {
    [key: string]: string | string[];

    [index: number]: string;
}

export interface Observation {
    observedNode: string;
    measuredValue: string;
    disambiguatingDescription: string;
    observationDate: string;
    measuredProperty: string;
    [key: string]: string;
}

export type DataClassInfo = {
    id: number;
    name: string;
};

export type DataDownloadInformation = {
    location: string;
    files: FileInfo[];
};

export type FileInfo = {
    name: string;
    size: string | number;
};

const StyledBackButton = styled(Button)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    &:focus-within div {
        border-radius: ${theme.radii.large};
        box-shadow: 0 0 0 ${theme.a11yBoxShadow.thickness} ${theme.a11yBoxShadow.colour};
    }
    padding-bottom: 15px;
`;

const StyledMenuButtonLeftArrowImage = styled(Image)`
    width: 8px;
    margin-right: 8px;
`;

const IndividualDatasetPageContents = () => {
    const listRef: any = React.useRef();
    // const treeData = useSelector((state: any) => state.treeviewDataInDataSet);
    // const assets = {
    //     "location": "https://client-config.metadatadev.co.uk/john-test/assets/",
    //     "files": [
    //         {
    //             "name": "JC Test 101-FINALIZED-6.xlsx",
    //             "size": 1231230,
    //             "id": 70306
    //         },
    //         {
    //             "name": "JC Test 101-FINALIZED-6.docx",
    //             "size": 6189,
    //             "id": 70307
    //         },
    //         {
    //             "name": "JC Test 101-FINALIZED-6.xlsx",
    //             "size": 0,
    //             "id": 70308
    //         },
    //         {
    //             "name": "JC Test 101-FINALIZED-6.xlsx",
    //             "size": 1230,
    //             "id": 70309
    //         },
    //         {
    //             "name": "JC Test 101-FINALIZED-6.xlsx",
    //             "size": 44320,
    //             "id": 70310
    //         },
    //         {
    //             "name": "JC Test 101-FINALIZED-6.xlsx",
    //             "size": 220,
    //             "id": 70311
    //         },
    //         {
    //             "name": "JC Test 101-FINALIZED-6.xlsx",
    //             "size": 440,
    //             "id": 70312
    //         }
    //     ]
    // }
    // const documentControl = {
    //     "stage": "implementation",
    //     "scope": "Huge scope",
    //     "sponsor": "wetqretqret",
    //     "sro": "hjyytet",
    //     "businessLead": "yueueu",
    //     "technicalCommittee": "iuiyiuyi",
    //     "approvalDate": "2022-06-28",
    //     "legalAuthority": "iooyoiyo",
    //     "postImplementationReviewDate": "2022-08-07",
    //     "reviews": [
    //         {
    //             "reviewer": "Reviewer 1",
    //             "dateReviewed": "2022-07-13",
    //             "reviewOutcome": "Accept",
    //             "notes": "Excellent work"
    //         },
    //         {
    //             "reviewer": "Reviewer 2",
    //             "dateReviewed": "2022-06-30",
    //             "reviewOutcome": "Reject"
    //         },
    //         {
    //             "reviewer": "Reviewer 3",
    //             "dateReviewed": "2022-07-06",
    //             "reviewOutcome": "Revise",
    //             "notes": "Improve it"
    //         }
    //     ]
    // };
    // let coverage = {
    //     spatial: {},
    //     temporal: {
    //         startDate: '2022-06-10',
    //         endDate: '2022-06-10',
    //         frequency: 'BIWEEKLY',
    //         distributionReleaseDate: '2022-07-09',
    //     },
    // };
    let dataSet: Dataset = store.getState().dataSet;
    const { summary, status, dataModelType, dataStatus, versionHistory } = dataSet;
    let adrConfig = window.config?.branding === 'adr';
    let originConfig = window.config?.displayOrigin;
    //@ts-ignore: Unreachable code error
    // dataSet = { ...dataSet, coverage };
    //@ts-ignore: Unreachable code error

    delete dataSet?.documentControl?.reviews;
    delete dataSet?.descriptiveSchema;

    !originConfig && delete dataSet?.origin;
    const menuItems: MenuItems = individualDatasetPageTabRenaming(extractSearchMenuLabels(dataSet));

    useTitle(
        (window.config?.branding == 'adr' ? ADR_BROWSER_PREFIX : MDX_BROWSER_PREFIX) +
            dataSet.summary.title +
            ' @ ' +
            dataSet.id,
    );

    const location = useLocation();

    /**
     * This callback is meant to be called from the Individual Dataset Page.
     * It checks the "previous location" (location.state.from),
     * and if the previous location was the Browser Search Page,
     * it takes the user back to the Browser Search Page with the previous search query appended
     * @type {(function(): void)|*}
     */
    const goBackFromIndividualDatasetPageCallback = () => {
        let searchParams = window.localStorage.getItem('searchParams');
        if (location.state) {
            goBackFromIndividualDatasetPage(location);
        } else {
            // @ts-ignore
            history.push(`/browser/search${searchParams !== null ? searchParams : ''}`);
            // goBackFromIndividualDatasetPage(location);
        }
        store.dispatch(showTreeviewDataInDataSet({}));
    };

    // React.useEffect(() => {
    //     let rawId = location.search.split('=')[2]?.indexOf('&dataelement');
    //     let linkId =
    //         rawId >= 5 ? location.search.split('=')[2].slice(0, rawId) : location.search.split('=').slice(-1)[0];
    //      fetchMetaAndRelations(linkId).then((data) => {
    //          console.log(data);
    //      });
    // }, []);

    return (
        <IndividualDatasetPageContentsBox fill align="center">
            <Box width="100%">
                <Box direction="row" align="center" justify="between" margin={{ bottom: '20px' }}>
                    <StyledBackButton
                        plain
                        onClick={goBackFromIndividualDatasetPageCallback}
                        data-testid="metadata-to-browser-button"
                    >
                        <Box
                            direction="row"
                            align="center"
                            justify="center"
                            background={
                                theme?.global?.colors[window.localStorage.getItem('branding') || 'default']?.secondary
                            }
                            round={'12px'}
                            border={{
                                // @ts-ignore
                                color: theme?.global?.colors[window.localStorage.getItem('branding') || 'default']
                                    ?.secondary,
                                size: '1px',
                                style: 'solid',
                                side: 'all',
                            }}
                            pad={{ vertical: '11px', horizontal: '20px' }}
                        >
                            <StyledMenuButtonLeftArrowImage
                                src={adrConfig ? thinArrowLeftDark : thinArrowLeftWhite}
                                alt="Back button"
                            ></StyledMenuButtonLeftArrowImage>
                            <Text
                                color={
                                    theme?.global?.colors[window.localStorage.getItem('branding') || 'default']
                                        ?.primaryButtonText
                                }
                                size="small"
                                weight="bold"
                            >
                                Return to Results
                            </Text>
                        </Box>
                    </StyledBackButton>
                    {/* {dataStatus &&
                    (dataStatus.dataStatus === 'Secured' || dataStatus.dataStatus === 'External') &&
                    (dataStatus.dataAccess || window?.config?.requestDataAccessUrl) ? (
                        <DataAccessRequestButton dataStatus={dataStatus} />
                    ) : null} */}
                    {/* <DataAccessRequestButton dataStatus={{ dataStatus: 'Secured', dataAccess: 'www.google.com' }} /> */}
                </Box>
                <div style={{ background: 'white', borderRadius: '6px 6px 0px 0px' }}>
                    <Box background={'white'} width={'100%'} round={'6px'} height={{ min: '200px', max: '100%' }}>
                        <IndividualDatasetPageHeader
                            dataStatus={dataStatus}
                            summary={summary}
                            status={status}
                            dataModelType={dataModelType}
                            versionHistory={versionHistory ? versionHistory : ''}
                        />
                    </Box>
                    <ScrollButtons
                        orientation="horizontal"
                        height="50px"
                        onHover
                        boxPropsForButtonSizing={{
                            width: '30px',
                            background: 'white',
                            margin: { horizontal: '10px' },
                        }}
                    >
                        <MetadataTabs menuItems={menuItems} listRef={listRef} />
                    </ScrollButtons>
                </div>
                <Box direction="row" fill>
                    <IndividualDatasetPageContentsProper
                        dataSet={dataSet || {}}
                        listRef={listRef}
                        round={theme.radii.large}
                        dataTestId="descriptive-metadata-contents-proper"
                    />
                </Box>
            </Box>
        </IndividualDatasetPageContentsBox>
    );
};

export interface IndividualDatasetPageContentsProperProps {
    dataSet: Dataset;
    listRef: React.MutableRefObject<null>;
    round?: string | RoundType;
    dataTestId?: string;
    assets?: Object;
}

export const max_vids_fetched = 'max_vids_fetched';
const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
        fontSize: '14px',
    },
}));
const IndividualDatasetPageContentsProper = (props: IndividualDatasetPageContentsProperProps) => {
    const classes = useStyles();
    const dataContainerRef: any = React.useRef(null);
    const [titleBoxHeight, setTitleBoxHeight] = React.useState(1000);
    const [listHeight, setListHeight] = React.useState(1000);
    // const [showTreedata, setShowTreedata] = React.useState(' ');
    // const dispatch = useDispatch();
    const treeData = useSelector((state: any) => state.treeviewDataInDataSet);
    // const hideTreedata = () => dispatch(showTreeviewDataInDataSet({ showTreeView: false }));
    // showVersionHistory,
    const { showDataDownloadBrowser, showAssets, showVersionHistory, showTreeData, showSidebarGlossary } =
        useSideBarAndDataDownloadContext();

    const updateListHeight = React.useCallback(() => {
        dataContainerRef.current && setTitleBoxHeight(dataContainerRef.current.getBoundingClientRect().height);
    }, []);

    window.localStorage.removeItem(max_vids_fetched);

    React.useEffect(() => {
        const listNode =
            dataContainerRef?.current?.childNodes[0]?.childNodes[0]?.childNodes[0]?.childNodes[0]?.childNodes[0];
        listNode && setListHeight(listNode.getBoundingClientRect().height);
        dataContainerRef.current &&
            dataContainerRef.current.getBoundingClientRect().height !== titleBoxHeight &&
            updateListHeight();
    }, [props.dataSet, dataContainerRef, updateListHeight, showTreeData, showSidebarGlossary, titleBoxHeight]);

    React.useEffect(() => {
        updateListHeight();
    }, [props.dataSet, dataContainerRef, updateListHeight, showTreeData, showSidebarGlossary]);

    React.useEffect(() => {
        window.addEventListener('resize', updateListHeight);
        return () => {
            window.removeEventListener('resize', updateListHeight);
        };
    }, [updateListHeight]);

    const rebuildToolTip = React.useCallback(() => {
        ToolTip.rebuild();
    }, []);
    const descriptiveMetadata: DescriptiveMetadata = React.useMemo(() => {
        return splitDataset(props.dataSet)[0];
    }, [props.dataSet]);
    const versionHistory: any = props?.dataSet?.versionHistory;
    return (
        <Box background="white" fill height={'60vh'} round={{ size: '6px', corner: 'bottom' }}>
            {showDataDownloadBrowser && props.dataSet.data ? (
                <Box fill background="white" round="small" pad="small">
                    <S3BrowserWrapper data={props.dataSet.data} />
                </Box>
            ) : showAssets && props.dataSet.assets ? (
                <Box fill background="white" round="small" pad="small">
                    {/* @ts-ignore: Unreachable code error */}
                    <BrowserAssetsWrapper data={props.dataSet.assets} />
                </Box>
            ) : showVersionHistory && versionHistory ? (
                // <StructuralDataContent
                //     currentClass={treeData}
                //     rebuildToolTip={rebuildToolTip}
                //     dataSet={treeData}
                //     heightNumber={titleBoxHeight}
                // />
                <div style={{ height: '100%', minHeight: '290px' }}>
                    <Box
                        pad={{
                            bottom: 'none',
                            top: '10px',
                        }}
                    >
                        <Box background={'white'} pad={{ top: 'small', horizontal: 'small' }}>
                            <div>
                                <Box
                                    pad={{
                                        top: 'none',
                                        horizontal: 'small',
                                    }}
                                    margin={{
                                        top: 'small',
                                    }}
                                >
                                    <Text size="14px" weight={theme.fontWeights.semibold}>
                                        Version History
                                    </Text>
                                </Box>
                                <Box pad={'small'}>
                                    {/* <StyledElementModalSubHeading size="small" weight="normal">
                                                {props.dataSet.versionHistory}
                                            </StyledElementModalSubHeading> */}
                                    <ElementPopUpContentBlock title={''} isAtBottom={versionHistory}>
                                        {versionHistory && (
                                            <Box direction="row" pad={{ bottom: 'xsmall' }}>
                                                <StyledGridBox width="19%">
                                                    <StyledGridText size="small">Major Version</StyledGridText>
                                                </StyledGridBox>
                                                <StyledGridBox width="15%">
                                                    <StyledGridText size="small">Date</StyledGridText>
                                                </StyledGridBox>
                                                <StyledGridBox width="15%">
                                                    <StyledGridText size="small">DOI</StyledGridText>
                                                </StyledGridBox>
                                                <StyledGridBox width="51%">
                                                    <StyledGridText size="small">Release Notes</StyledGridText>
                                                </StyledGridBox>
                                            </Box>
                                        )}

                                        {props.dataSet.versionHistory &&
                                            versionHistory?.map((d: any, index: number) => {
                                                return (
                                                    <Box
                                                        key={index++}
                                                        pad={{ top: 'small' }}
                                                        data-testid={`metadata-row-id-${index++}`}
                                                        style={{ background: index++ % 2 !== 0 ? '#F6F6F6' : 'none' }}
                                                    >
                                                        <Box direction="row" pad={{ bottom: 'xsmall' }}>
                                                            <StyledGridBox width="19%" data-tip={d}>
                                                                <Tooltip
                                                                    placement="left"
                                                                    title={d.roundedVersion && d.roundedVersion}
                                                                    classes={{ tooltip: classes.button }}
                                                                >
                                                                    <StyledGridText
                                                                        style={{ paddingLeft: '10px' }}
                                                                        size="small"
                                                                        weight={theme.fontWeights.body}
                                                                    >
                                                                        {d.roundedVersion && d.roundedVersion}
                                                                    </StyledGridText>
                                                                </Tooltip>
                                                            </StyledGridBox>
                                                            <StyledGridBox width="15%">
                                                                <Tooltip
                                                                    placement="left"
                                                                    title={d.date && d.date}
                                                                    classes={{ tooltip: classes.button }}
                                                                >
                                                                    <Text
                                                                        wordBreak={'break-word'}
                                                                        size="small"
                                                                        weight={theme.fontWeights.body}
                                                                    >
                                                                        {d.date && d.date}
                                                                    </Text>
                                                                </Tooltip>
                                                            </StyledGridBox>
                                                            <StyledGridBox width="15%">
                                                                {d.doi && (
                                                                    <Tooltip
                                                                        placement="left"
                                                                        title={d.doi && d.doi}
                                                                        classes={{ tooltip: classes.button }}
                                                                    >
                                                                        <Text
                                                                            wordBreak={'break-word'}
                                                                            size="small"
                                                                            weight={theme.fontWeights.body}
                                                                        >
                                                                            {d.doi && d.doi}
                                                                        </Text>
                                                                    </Tooltip>
                                                                )}
                                                            </StyledGridBox>
                                                            <StyledGridBox width="51%">
                                                                <Tooltip
                                                                    placement="left"
                                                                    title={d.revisionNotes && d.revisionNotes}
                                                                    classes={{ tooltip: classes.button }}
                                                                >
                                                                    <Text
                                                                        wordBreak={'break-word'}
                                                                        size="small"
                                                                        weight={theme.fontWeights.body}
                                                                    >
                                                                        {d.revisionNotes && d.revisionNotes}
                                                                    </Text>
                                                                </Tooltip>
                                                            </StyledGridBox>
                                                        </Box>
                                                    </Box>
                                                );
                                            })}
                                    </ElementPopUpContentBlock>
                                </Box>
                            </div>
                        </Box>
                    </Box>
                </div>
            ) : showTreeData && props.dataSet.structuralMetadata ? (
                <StructuralDataContent
                    currentClass={treeData}
                    rebuildToolTip={rebuildToolTip}
                    dataSet={treeData}
                    heightNumber={listHeight}
                />
            ) : showSidebarGlossary ? (
                <StructuralDataContent
                    currentClass={treeData}
                    rebuildToolTip={rebuildToolTip}
                    dataSet={treeData}
                    heightNumber={listHeight}
                />
            ) : (
                // <StructuralGlossaryContent
                //     currentClass={treeData}
                //     rebuildToolTip={rebuildToolTip}
                //     dataSet={treeData}
                //     heightNumber={listHeight}
                // />
                <DescriptiveMetadataList
                    dataTestId="descriptive-metadata-list"
                    listRef={props.listRef}
                    descriptiveMetadata={descriptiveMetadata || {}}
                />
            )}
            {/* {isTesting && <ToolTip />} */}
        </Box>
    );
};

export default IndividualDatasetPageContents;

IndividualDatasetPageContents.whyDidYouRender = true;
