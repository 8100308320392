import React from 'react';
import PropTypes from 'prop-types';
import FormContents from './FormContents';
import { Layer } from 'grommet';
import { MessageHubProvider } from '../../MDW/MDW';

const FormContext = React.createContext();

const Form = ({ children, showFormModal, setShowFormModal, headingText, ...props }) => {
    const ref = React.useRef();
    return (
        <React.Fragment>
            <MessageHubProvider containerRef={ref} notificationTimeout={3000}>
                <FormContext.Provider
                    value={{
                        showFormModal,
                        setShowFormModal,
                        headingText,
                        ...(props.round && { round: props.round }),
                        ...(props.headingMargin && {
                            headingMargin: props.headingMargin,
                        }),
                        ...(props.elevation && { elevation: props.elevation }),
                        ...(props.height && { height: props.height }),
                        ...(props.width && { width: props.width }),
                    }}
                >
                    {!setShowFormModal ? (
                        <FormContents>{children}</FormContents>
                    ) : (
                        setShowFormModal &&
                        showFormModal && (
                            <Layer onEsc={() => setShowFormModal(false)} margin="large">
                                <FormContents>{children}</FormContents>
                            </Layer>
                        )
                    )}
                </FormContext.Provider>
            </MessageHubProvider>
        </React.Fragment>
    );
};

Form.whyDidYouRender = true;

Form.propTypes = {
    headingText: PropTypes.string.isRequired,
    showFormModal: PropTypes.bool,
    setShowFormModal: PropTypes.func,
    round: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    headingMargin: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    elevation: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    children: PropTypes.node.isRequired,
};

export default Form;

export const useForm = () => {
    const context = React.useContext(FormContext);

    if (context === undefined) {
        throw new Error('useForm must be used within a FormProvider');
    }

    return context;
};
