export const RECEIVE_AUTHENTICATION = 'RECEIVE_AUTHENTICATION';
export const DELETE_AUTHENTICATION = 'DELETE_AUTHENTICATION';
export const RESET_APP = 'RESET_APP';
export const RECEIVE_PROFILE = 'RECEIVE_PROFILE';
export const RECEIVE_DATASETS = 'RECEIVE_DATASETS';
export const RECEIVE_FILTER_MENU_ITEMS = 'RECEIVE_FILTER_MENU_ITEMS';
export const ADD_SELECTED_FILTER = 'ADD_SELECTED_FILTER';
export const REMOVE_SELECTED_FILTER = 'REMOVE_SELECTED_FILTER';
export const ADD_TO_ALL_FILTERS = 'ADD_TO_ALL_FILTERS';
export const REMOVE_FROM_ALL_FILTERS = 'REMOVE_FROM_ALL_FILTERS';
export const CLEAR_ALL_SELECTED_FILTERS = 'CLEAR_ALL_SELECTED_FILTERS';
export const RECEIVE_DATASET = 'RECEIVE_DATASET';
export const UPDATE_BROWSER_CONTROLS = 'UPDATE_BROWSER_CONTROLS';
export const UPDATE_CURRENT_CLASS = 'UPDATE_CURRENT_CLASS';
export const SET_FILTERED_DATASETS = 'SET_FILTERED_DATASETS';
export const SET_S3_DATA = 'SET_S3_DATA';
export const SET_BROWSER_ASSETS_DATA = 'SET_BROWSER_ASSETS_DATA';
export const SHOW_TREEVIEW_DATA_IN_DATASET = 'SHOW_TREEVIEW_DATA_IN_DATASET';
export const SORT_BY_SEARCH_ITEM = 'SORT_BY_SEARCH_ITEM';
export const TRIGGER_FOR_SEARCH_ALL_DATASET_INPUT = 'TRIGGER_FOR_SEARCH_ALL_DATASET_INPUT';
