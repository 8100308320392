import { createStore, applyMiddleware, compose } from 'redux';
import appReducer from '../reducers';
import thunkmiddleware from 'redux-thunk';

window.process = { ...window.process };

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = (state, action) => {
    if (action.type === 'RESET_APP') {
        state = undefined;
    }

    return appReducer(state, action);
};

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunkmiddleware)));

export default store;
