//the reducer just controls how the new state gets added to the store in relation to the action

import { combineReducers } from 'redux';
import {
    currentClass,
    isAuthenticated,
    profile,
    dataSets,
    filterMenuItems,
    selectedFilters,
    allFilters,
    dataSet,
    browserControls,
    filteredDataSets,
    s3Data,
    browserAssetsData,
    treeviewDataInDataSet,
    sortbyIntegratedItem,
    triggerForSearchAllDatasetInput,
} from './reducers';
export default combineReducers({
    isAuthenticated,
    profile,
    dataSets,
    filterMenuItems,
    selectedFilters,
    allFilters,
    dataSet,
    browserControls,
    currentClass,
    filteredDataSets,
    s3Data,
    browserAssetsData,
    treeviewDataInDataSet,
    sortbyIntegratedItem,
    triggerForSearchAllDatasetInput,
});
