import { Box, Layer as GrommetLayer, Text, Video as GrommetVideo } from 'grommet';
import { FormClose } from 'grommet-icons';
import styled from 'styled-components';
import { Button } from '../../MDW/MDW';
import theme from '../../../style/theme';

export const Layer = styled(GrommetLayer)`
    ${(props) =>
        props.size === 'small'
            ? `background-color: #00000080; justify-content: center;`
            : `background-color: transparent;`}
    min-width: 100%;
`;

export const Video = styled(GrommetVideo)`
    //   max-width: none;
`;

export const VideoOverlayHideableBox = styled(Box)`
    display: ${(props) => (props.isVisible === false ? `none` : `flex`)};
`;

const StyledButton = styled(Button)`
    &:focus {
        border-radius: ${theme.radii.large};
        box-shadow: 0 0 0 ${theme.a11yBoxShadow.thickness}
            ${theme.global.colors[window.localStorage.getItem('branding') || 'default'].focusBorderColor};
    }
`;

export const VideoOverlayCloseButton = (props) => (
    <StyledButton
        plain
        a11yTitle="Close Overlay"
        label={
            <Box direction="row" justify="end">
                <Text size="small" color="white">
                    Close
                </Text>
                <FormClose
                    color="white"
                    margin={{
                        bottom: 'xsmall',
                    }}
                />
            </Box>
        }
        onClick={props.onCloseButtonClick}
    />
);
