import ReactTooltip from 'react-tooltip';
import { fetchEndPoint } from '../../../../api/fetchEndpoint';
import {
    addSelectedFilter,
    removeSelectedFilter,
    setFilteredDataSets,
    updateBrowserControls,
} from '../../../../redux/actions/actions';
import store from '../../../../redux/store';
import theme from '../../../../style/theme';
import history from '../../../../utils/history';
import { scrollToRefItem, sleep } from '../../../../utils/javascriptUtilities';

export const setDataSets = (results) => {
    store.dispatch(setFilteredDataSets(results));
};

export const removeHighlightTag = (tagObject) => {
    if (tagObject.ref.current && tagObject.ref.current.firstChild) {
        tagObject.ref.current.firstChild.style.backgroundColor =
            theme.global.colors[window.localStorage.getItem('branding')].midGrey;
        tagObject.ref.current.setAttribute('aria-pressed', false);
        tagObject.ref.current.firstChild.firstChild.style.color =
            theme.global.colors[window.localStorage.getItem('branding')].primary;
        tagObject.ref.current.firstChild.firstChild.style.cursor = 'pointer';
    }
};

export const setHighlightAllTags = (tagObject, highlighCallback) => {
    store.getState().allFilters.forEach((filter) => {
        if (
            filter &&
            tagObject &&
            filter.label.toLowerCase() === tagObject.label.toLowerCase() &&
            filter.type === tagObject.type
        ) {
            console.log('hh');
            highlighCallback(filter);
        }
    });
};

const setCheckSelectedFilters = (tagObject, checked) => {
    store.getState().allFilters.forEach((filter) => {
        if (
            tagObject.label.toLowerCase() === filter.label.toLowerCase() &&
            tagObject.type === filter.type &&
            filter.ref.current
        ) {
            filter.ref.current.checked = checked;
        }
    });
};

export const setCheckAllSelectedCheckboxes = (tagObject) => {
    store.getState().selectedFilters.forEach((filter) => {
        if (
            tagObject.label &&
            filter.label &&
            tagObject.label.toLowerCase() === filter.label.toLowerCase() &&
            tagObject.type === filter.type &&
            tagObject.ref.current
        ) {
            tagObject.ref.current.checked = true;
        }
    });
};

const addToSelectedFilters = (tagObject) => {
    let isValid = true;
    store.getState().selectedFilters.forEach((selectedFilter) => {
        if (
            selectedFilter.label.toLowerCase() === tagObject.label.toLowerCase() &&
            selectedFilter.type === tagObject.type
        ) {
            isValid = false;
        }
    });
    isValid && store.dispatch(addSelectedFilter(tagObject));
};

const generateQueryAttributes = () => {
    const urlAttributes = {};
    store.getState().selectedFilters.forEach((selectedFilter) => {
        const selectedFilterCategoryName = selectedFilter.type.replace(/ /g, '');
        const paramValue = encodeURIComponent(selectedFilter.label);

        if (!urlAttributes[selectedFilterCategoryName]) {
            urlAttributes[selectedFilterCategoryName] = '';
        }

        urlAttributes[selectedFilterCategoryName] +=
            urlAttributes[selectedFilterCategoryName] === '' ? paramValue : `::${paramValue}`;
    });
    return urlAttributes;
};

const convertAttributesToQuery = (urlAttributes) => {
    let query = `?`;
    Object.keys(urlAttributes).forEach((key) => {
        const paramsString = `${key}=${urlAttributes[key]}`;
        query += urlAttributes[key] !== '' ? (query === '?' ? paramsString : `&${paramsString}`) : '';
    });
    return query;
};


export const highlightTag = (tagObject, child) => {
    console.log(tagObject);

    if (child) {
        console.log(document.getElementsByClassName(child), child);
        const element = document.getElementsByClassName(child);
        for (let index = 0; index < element.length; index++) {
            element[index].style.backgroundColor =
                theme.global.colors[window.localStorage.getItem('branding')].secondary;
            element[index].setAttribute('aria-pressed', true);
            element[index].children[0].style.color = theme.global.colors[window.localStorage.getItem('branding')].white;
            element[index].style.cursor = 'default';
        }
    }
};

export const setHighlightSelectedTags = (tagObject, child, highlighCallback) => {
    store.getState().selectedFilters.forEach((filter) => {
        if (
            filter &&
            tagObject &&
            filter.label.toLowerCase() === tagObject.label.toLowerCase() &&
            filter.type === tagObject.type
        ) {
            highlighCallback(tagObject, child);
        }
    });
};

export const addFilter = (tagObject) => {
    setCheckSelectedFilters(tagObject, true);
    addToSelectedFilters(tagObject);
    setHighlightAllTags(tagObject, highlightTag);
};

export const removeFilter = (tagObject) => {
    setCheckSelectedFilters(tagObject, false);
    store.dispatch(removeSelectedFilter(tagObject));
    setHighlightAllTags(tagObject, removeHighlightTag);
};

export const generateUrlQuery = () => {
    const urlAttributes = generateQueryAttributes();
    const query = convertAttributesToQuery(urlAttributes);
    history.push(query);
    return query !== '?' ? { query, urlAttributes } : { query: '', urlAttributes: undefined };
};

export const accordionPanelidPrefix = 'search-menu-acc-panel';

export const handleScrollTo = (ref, index) => {
    const doScrolls = async () => {
        let count = 0;
        while (count !== 5) {
            await sleep(50);
            ref && scrollToRefItem(ref, index, 'start');
            count++;
        }
    };

    doScrolls();
};

export const enablePanel = (panel) => {
    panel.style.backgroundColor = theme.global.colors.lightGrey;
};

export const disablePanel = (panel) => {
    setTimeout(() => {
        panel.style.backgroundColor = theme.global.colors.lightestBlue;
    }, 400);
};

export const extractPageNumberFromReduxState = () => store.getState().browserControls['pageNumber'] - 1;

export const genericClickFunction = (setDataSets, attribute, payloadMiddleware) => {
    return (payload) => {
        const arg = payloadMiddleware ? payloadMiddleware(payload) : payload;
        ReactTooltip.hide();
        const resetPageNumber = () => {
            store.dispatch(
                updateBrowserControls({
                    ...store.getState().browserControls,
                    pageNumber: 1,
                }),
            );
            return 1;
        };
        const generatedEndpoint = generateUrlQuery().query;

        const endPoint = Object.entries(store.getState().browserControls).reduce(
            (acc, [k, v]) =>
                acc + `${acc ? '&' : '?'}${k}=${k === attribute ? arg : k === 'pageNumber' ? resetPageNumber() : v}`,
            generatedEndpoint,
        );

        return fetchEndPoint(endPoint, setDataSets);
    };
};
