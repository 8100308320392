const bootstrap_4_6 = [
    {
        src: 'https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.slim.min.js',
        integrity: 'sha384-DfXdz2htPH0lsSSs5nCTpuj/zy4C+OGpamoFVy38MVBnE+IbbVYUew+OrCXaRkfj',
        crossOrigin: 'anonymous',
    },
    {
        src: 'https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js',
        integrity: 'sha384-9/reFTGAW83EW2RDu2S0VKaIzap3H66lZH81PoYlFhbGU+6BZp6G7niu735Sk7lN',
        crossOrigin: 'anonymous',
    },
    {
        src: 'https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/js/bootstrap.min.js',
        integrity: 'sha384-+sLIOodYLS7CIrQpBjl+C7nPvqq+FbNUBDunl/OZv93DB7Ln/533i8e/mZXLi/P+',
        crossOrigin: 'anonymous',
        defer: true,
    },
];

const bootstrap_5_2 = [
    {
        src: 'https://cdn.jsdelivr.net/npm/@popperjs/core@2.11.6/dist/umd/popper.min.js',
        integrity: 'sha384-oBqDVmMz9ATKxIep9tiCxS/Z9fNfEXiDAYTujMAeBAsjFuCZSmKbSSUnQlmh/jp3',
        crossOrigin: 'anonymous',
    },
    {
        src: 'https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.min.js',
        integrity: 'sha384-cuYeSxntonz0PPNlHhBs68uyIAVpIIOZZ5JqeqvYYIcEL727kskC66kF92t6Xl2V',
        crossOrigin: 'anonymous',
        defer: true,
    },
];

const dsc = [
    {
        src: `/domain/dsc/assets/scripts/dsc.js`,
        defer: true,
    },
];

interface Iinjectable {
    src?: string;
    integrity?: string;
    crossOrigin?: string;
    defer?: boolean;
}

const injector = (collection: Iinjectable[]) => {
    collection.forEach((injectable: Iinjectable) => {
        const node = document.createElement('script');
        if (injectable.src) {
            node.src = injectable.src;
        }
        if (injectable.integrity) {
            node.integrity = injectable.integrity;
        }
        if (injectable.crossOrigin) {
            node.crossOrigin = injectable.crossOrigin;
        }
        if (injectable.defer) {
            node.defer = injectable.defer;
        }
        document.body.appendChild(node);
    });
};

const clientLibraryInjector = (HTML: string) => {
    const libString: RegExpMatchArray = HTML.match(
        /(?<=<span id="include" display="none" hidden>)(.*)(?=<\/span>)/gm,
    ) || [''];
    const libArray = libString.toString().split(',');

    libArray.forEach((lib) => {
        switch (lib.trim()) {
            case 'bootstrap-4.6':
                injector(bootstrap_4_6);
                break;
            case 'bootstrap-5.2':
                injector(bootstrap_5_2);
                break;
            case 'dsc':
                injector(dsc);
                break;
        }
    });
};

export default clientLibraryInjector;
