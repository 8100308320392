import React from 'react';
import { removeArrayDuplicates } from '../../../utils/javascriptUtilities';
import { List, ScrollButtons, Tag, ToolTip } from '../../MDW/MDW';
import NewTag from '../../atoms/NewTag/NewTag';
import theme from '../../../style/theme';
const MemoizationOfTags = React.memo(({ dataset, datasetIndex, onTagClick }) => {
    return removeArrayDuplicates(dataset.keywords).map((tagName, tagIndex) => {
        const trimmedTagName = tagName.trim();
        const ref = React.createRef();
        const tagObject = {
            label: trimmedTagName,
            ref,
            type: 'keywords',
        };
        return (
            // <Tag
            //     theRef={ref}
            //     tagObject={tagObject}
            //     id={`dataset${datasetIndex}-${dataset ? dataset.title : 'noName'}-tag${tagIndex}-${tagName}`}
            //     dataTip={tagName || ''}
            //     onClick={onTagClick(tagObject)}
            //     color={theme.global.colors.default.primary}
            //     backgroundColor={theme.global.colors.default.dynamicTagBgColor}
            //     {...(tagName.label && !tagName.isEnabled
            //         ? { disabled: true }
            //         : tagName.label &&
            //           tagName.isEnabled && {
            //               disabled: false,
            //           })}
            //     key={tagName}
            // >
            //     {tagName}
            // </Tag>
            <NewTag
                theRef={ref}
                tagObject={tagObject}
                id={`dataset${datasetIndex}-${dataset ? dataset.title : 'noName'}-tag${tagIndex}-${tagName}`}
                className="keyword"
                dataTip={tagName || ''}
                onClick={onTagClick(tagObject)}
                color={theme.global.colors.default.primary}
                {...(tagName.label && !tagName.isEnabled
                    ? { disabled: true }
                    : tagName.label &&
                      tagName.isEnabled && {
                          disabled: false,
                      })}
                key={tagName}
                lastChild={dataset.keywords.length - 1 === tagIndex ? '' : ', '}
            >
                {tagName}
            </NewTag>
        );
    });
});

export default MemoizationOfTags;
